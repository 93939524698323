import {
    SearchOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import {Badge, Space, Row, Col, Typography, Button, Card, Table, Divider, Pagination, notification} from 'antd';
import { useTranslation } from 'react-i18next';
import useScrollToElement from '../../shared/hooks/useScrollToElement';
import EditBenefit from './EditBenefit';
import BenefitSelector from '../benefit-selector';
import DeleteModal from '../delete-modal';
import StatusBadge from '../status-badge';
import {useState, useEffect, useMemo} from 'react';

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../../shared/constants/query";

import useBenefits from "../../shared/hooks/useBenefits";

const { Title } = Typography;

const Benefits = () => {
    const {searchResults, benefits, fetchBenefits, deleteBenefit, alphabeticalSort, searchBenefit} = useBenefits();
    const { scrollToElement } = useScrollToElement();
    const {t} = useTranslation();
    const [current, setCurrent] = useState(1);
    const [fetchingBenefits, setFetchingBenefits] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showCreateNew, setShowCreateNew] = useState(false);
    const [deleteModal, setDeleteModal] = useState({shouldOpen: false, data:{}});
    const [selectorResults, setSelectorResults] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);

    const renderBenefits = useMemo(() => {        
        if(benefits && selectorResults === null) {
            return {
                content: benefits.content?.sort(alphabeticalSort), 
                pagination: {... benefits.pagination}
            };
        }
        if(selectorResults !== null && searchResults === null) {
            return selectorResults;
        }
        if(selectorResults !== null && searchResults !== null) {
            return searchResults;
        }
    },[selectorResults, benefits, searchResults])

    const fetchBenefitsPage = (page) => {
        setFetchingBenefits(true);
        fetchBenefits(page, DEFAULT_PAGE_SIZE, () => {
            setFetchingBenefits(false);
        });
    }

    const fetchBenefitsSearchPage = (page) => {
        setFetchingBenefits(true);
        searchBenefit(searchTerm, page, DEFAULT_PAGE_SIZE, () => {
            setFetchingBenefits(false);
        });
    }

    const doFirstRender = () => {
        fetchBenefitsPage(DEFAULT_PAGE)
    };

    useEffect(()=> {
        if(benefits === null){
            doFirstRender();
        }
    },[benefits, doFirstRender]);

    const onBenefitSelectHandler = (benefit) => {
        setSelectedItem(benefit); 
    }

    const onBenefitClearHandler = () => {
        setSelectorResults(null);
        setSelectedItem(null);
        setSearchTerm(null);
    }

    const onBenefitChangeHandler = (text) => {
        if (text !== undefined && text.length === 0) {
            onBenefitClearHandler();
        } else {
            setSearchTerm(text);
        }
    }
    
    const onBenefitResultsHandler = (results) => {
        setSelectorResults(results);
    }

    const callAppropriateFetch = (page = DEFAULT_PAGE) => {
        if(searchTerm !== null) {
            fetchBenefitsSearchPage(page)
        } else {
            fetchBenefitsPage(page);
        }
    }

    const onPaginationChange = (page) => {
        setCurrent(page);
        callAppropriateFetch(page);
        setFetchingBenefits(true);
    }

    const onEdit = (record) => {
        setSelectedItem(record);
        setTimeout(() => {
            scrollToElement(document.getElementById('edit-benefits'));
        }, 500);
    }

    const onDeleteOkHandler = () => {
        deleteBenefit(
            deleteModal.data.record,
            () => {
                setSelectedItem(null);
                callAppropriateFetch(current);
                setDeleteModal({shouldOpen: false, data:{}})
                notification.success({
                    message: t('benefitDeletedSuccess'),
                    placement: "bottomRight",
                });                    
            },
            (error) => {
                setDeleteModal({shouldOpen: false, data:{}})
                notification.error({
                    message: t('benefitDeletedFail'),
                    description: error.message,
                    placement: "bottomRight",
                });
            }       
        )
    }

    const onDeleteCancelHandler = () => {
        setDeleteModal({shouldOpen: false, data:{}})
        setSelectorResults(null);
    }

    const onDelete = (record) => {
        setDeleteModal({shouldOpen: true, data:{
            record,
            content: t('benefitsDeleteConfirmation', {label: record.description})
        }})
    }

    const onCreateNew = () => {
        setShowCreateNew(true);
    }

    const onSuccessHandler = (refetch = false) => {
        if(refetch){
            callAppropriateFetch(current);
        }
    }

    const onCancelHandler = (refetch = false) => {
        setSelectedItem(null);
        setShowCreateNew(false);    
        if(refetch){
            callAppropriateFetch(current);
        }
    }

    const columns = [
        {
            title: t("benefitsStatus"),
            dataIndex: "status",
            key: "status",
            render: (_, record) => <StatusBadge status={record.status} />,
        },
        {
            title: t("benefitsDescriptionLabel"),
            dataIndex: "description",
            key: "description",
            render: (_, record) => record.description,
        },
        {
            title: t("actionLabel"),
            key: "action",
            width: 190,
            align: "right",
            render: (_, record) => (
              <Space size="middle">
                <EditOutlined className='IconTable' onClick={() => onEdit(record)}/>
                <DeleteOutlined className='IconTable' onClick={() => onDelete(record)}/>
              </Space>
            ),
        }
    ];

    return (
        <>
            <Row justify={'center'}>
                <Col span={24}>
                    <Title level={1} className="pageTitle">
                    { t('benefitsConfigLbl')} 
                    </Title>
                </Col>
            </Row>
            <Row justify={'center'} className="paddingBottom2Rem paddingTop1Rem">
                <Col sm={24} md={15}>
                    <Row>
                        <Col sm={24} md={24}>
                            {showCreateNew ? (
                            <Row>
                                <Col sm={24} md={24}>
                                    <Title level={3} className="marginZero">{t("benefitsCreateNewLbl")}</Title>
                                </Col>
                            </Row>
                            ) : (
                                <Row>
                                    <Col sm={24} md={10}>
                                        <Title level={3} className="marginZero">{selectedItem ? t("benefitsEditLbl") : t("benefitsConsultLbl")}</Title>
                                    </Col>
                                    <Col sm={24} md={14} className="alignRight">
                                        <Button type="primary" icon={<PlusOutlined />} onClick={onCreateNew}>{t('benefitsCreateNewLbl')}</Button>
                                    </Col>
                                </Row>
                            )}
                            <Divider className="marginBottom1Rem marginTop05Rem" />                                
                        </Col>
                    </Row>

                    {!showCreateNew && (
                        <Row className="paddingTop1Rem">
                            <Col sm={24} md={24}>
                                <Card
                                    title={
                                        <Row justify="center">
                                            <Col sm={24} md={12}>
                                                <b>{t('benefitsListLbl')}</b>
                                            </Col>
                                            <Col sm={24} md={10}>
                                                <BenefitSelector
                                                    onBenefitClearHandler={onBenefitClearHandler}
                                                    onBenefitSelectHandler={onBenefitSelectHandler}
                                                    onBenefitResultsHandler={onBenefitResultsHandler}
                                                    onBenefitChangeHandler={onBenefitChangeHandler}
                                                />
                                            </Col>       
                                            <Col sm={24} md={2} className='alignRight'>
                                                <Button type='primary'><SearchOutlined /></Button>
                                            </Col>       
                                        </Row>
                                    }
                                >
                                <Table
                                    loading={fetchingBenefits}
                                    size="middle"
                                    columns={columns}
                                    dataSource={renderBenefits?.content}
                                    pagination={false}
                                    tableLayout="fixed"
                                    rowKey="benefitId"
                                    onRow={(record, _) => ({
                                        className: selectedItem && record.benefitId === selectedItem.benefitId ? 'selected-row' : null
                                      })
                                    }                                    
                                />
                                {renderBenefits && renderBenefits?.pagination.totalPages > 1 && 
                                    (
                                    <Pagination
                                        onChange={onPaginationChange}
                                        current={current}
                                        total={renderBenefits.pagination.totalElements}
                                        showSizeChanger={false}
                                    />
                                    )
                                }
                            </Card>
                        </Col>
                    </Row>
                    )}
                    <div id='edit-benefits'>
                        {
                            (selectedItem || showCreateNew) && (
                                <>
                                    <EditBenefit 
                                        selectedBenefit={selectedItem} 
                                        createNew={showCreateNew} 
                                        onCancelHandler={onCancelHandler}
                                        onSuccessHandler={onSuccessHandler}
                                    />
                                </>
                            )
                        }
                    </div>
                    { deleteModal && deleteModal.shouldOpen && (
                        <DeleteModal
                            shouldOpen={deleteModal.shouldOpen}
                            data={deleteModal.data}
                            onOkHandler={onDeleteOkHandler}
                            onCancelHandler={onDeleteCancelHandler}
                        />
                        )
                    }
                </Col>
            </Row>
        </>

    )
} 

export default Benefits;