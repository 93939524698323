import { useEffect, useState, useMemo } from 'react';
import {
  EditOutlined,
  DownloadOutlined,
  CopyOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import Loading from '../../loading/Loading';

import { Table, Space, Modal, Button, Pagination } from 'antd';

import useAppClient from '../../../shared/hooks/useAppClient';

import { useParams, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

const modelObject = { open: false, content: <></>, footer: <></> };

import './ProposalHistory.css';

const ProposalHistory = ({
  shouldReplace = false,
  simulationId = null,
  keyPrefix = 'history-',
  shouldRefetchProposal = false,
  proposalUpdateHandler = undefined,
  candidate: { employeeId = '', candidateId = '', proposalNotes = '', email },
}) => {
  const apiInstance = useAppClient();
  const { locale, companyId } = useParams();
  const navigate = useNavigate();
  const [proposals, setProposals] = useState(null);
  const { t } = useTranslation();
  const [modal, setModal] = useState(modelObject);
  const [current, setCurrent] = useState(1);
  const [showLoading, setShowLoading] = useState(false);

  const apiId = useMemo(() => {
    return employeeId.length > 0 ? employeeId : candidateId;
  }, [employeeId, candidateId]);

  useEffect(() => {
    const getProposals = async () => {
      setProposals(null);
      setShowLoading(true);
      apiInstance
        .get(`/hr-proposals/hr-proposals/${apiId}?page=${current}&size=10`, {
          headers: {
            'Accept-Language': locale,
          },
        })
        .then((response) => {
          setShowLoading(false);
          if (response && response.status === 200 && response?.data) {
            const { content, pagination } = response?.data;
            setProposals({
              content: content.map((item) => ({
                candidateId: item.candidateId,
                employeeId: item.employeeId,
                key: item.proposalId,
                id: item.proposalId,
                status: item.status,
                notes: item.notes,
                date: dayjs(item.createdAt),
              })),
              pagination,
            });
          } else {
            setProposals(null);
          }
        });
    };

    apiId && apiId.length > 0 && getProposals();
    shouldRefetchProposal && getProposals();
  }, [apiId, shouldRefetchProposal]);

  const hideModal = () => {
    setModal({ open: false });
  };

  const goToProposal = (id) => {
    navigate(`/${locale}/company/${companyId}/proposals/${id}`);
  };

  const editProposalHandler = ({ id }) => {
    if (simulationId === null) {
      goToProposal(id);
    } else {
      setModal({
        open: true,
        title: t('proposalsEditTitle'),
        content: <Space direction='vertical'>{t('proposalsEditBody')}</Space>,
        footer: (
          <>
            {!shouldReplace && (
              <>
                <Button onClick={() => setModal(modelObject)}>
                  {t('proposalsEditBtnNoLbl')}
                </Button>
                <Button type='primary' onClick={() => goToProposal(id)}>
                  {t('proposalsEditBtnYesLbl')}
                </Button>
              </>
            )}
          </>
        ),
      });
    }
  };

  const exportProposal = (proposal) => {
    apiInstance
      .get(`/hr-proposals/hr-proposals/export/${proposal.id}`, {
        headers: {
          'Accept-Language': locale,
          accept: 'application/pdf'
        },
        responseType: 'blob',
      })
      .then((response) => {
        if (response && response?.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          const contentDisposition = response.headers.get("content-disposition");
          let fileName = `Proposal - ${proposal.id}.pdf`;
          if (contentDisposition !== undefined){
             fileName = contentDisposition.split('; ')[1].replace('filename=','');
          }
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        }
      });
  };

  const replaceProposalHandler = (proposal) => {
    setShowLoading(true);
    apiInstance
      .put(`/hr-proposals/hr-proposals/${proposal.id}`, {
        ...proposal,
        simulationId,
        notes: proposalNotes.length > 0 ? proposalNotes : '',
      })
      .then((response) => {
        setShowLoading(false);
        if (proposalUpdateHandler !== undefined) {
          proposalUpdateHandler(response);
        }
        setModal({
          open: true,
          title: t('proposalsReplaceTitle'),
          content: (
            <>
              <p>
                {response && response.status === 200 ? (
                  <>
                    <CheckOutlined className='status-green' />{' '}
                    {t('proposalsReplaceBody')}
                  </>
                ) : (
                  <>
                    <CloseOutlined className='status-red' />{' '}
                    {t('proposalsNotReplaceBody')}
                  </>
                )}
              </p>
            </>
          ),
          footer: <></>,
        });
      })
      .catch((error) => {
        setShowLoading(false);
        console.warn('error', error);
      });
  };

  const onChange = (page) => {
    setCurrent(page);
  };

  const columns = [
    //TODO: UNCOMMENT THIS COLLUMN WHEN STATUS CHANGES WITH WORKFLOW
    /*
        {
          title: t('proposalsStatusLabel'),
          dataIndex: "status",
          key: `${keyPrefix}-status`,
          render: (_, record) => {            
            switch (record.status) {
              case 'DRAFT':
                return <><span className='status status-grey'>●</span> {t('proposalsStatusDraft')}</> 
              case 'IN_APPROVAL':
                return <><span className='status status-blue'>●</span> {t('proposalsStatusInApproval')}</>
              case 'APPROVED':
                return <><span className='status status-green'>●</span> {t('proposalsStatusApproved')}</>
              case 'REJECTED':
                return <><span className='status status-red'>●</span> {t('proposalsStatusRejected')}</>
              case 'SENT_TO_CANDIDATE':
                return <><span className='status status-yellow'>●</span> {t('proposalsStatusSentToCandidate')}</>
              case 'ACCEPTED':
                return <><span className='status status-greener'>●</span> {t('proposalsStatusAccepted')}</>
              default:
                return <><span className='status status-brown'>●</span> {t('proposalsStatusNotAccepted')}</>
            }
          },
        },*/
    {
      title: t('proposalsNotesLabel'),
      dataIndex: 'notes',
      key: `${keyPrefix}-notes`,
      render: (_, record) => record.notes,
    },
    {
      title: t('proposalsDateLabel'),
      dataIndex: 'date',
      key: `${keyPrefix}-date`,
      render: (_, record) => dayjs(record.date).format('YYYY-MM-DD'),
    },
    {
      title: t('actionLabel'),
      key: `${keyPrefix}-action`,
      width: 190,
      align: 'right',
      render: (_, record) => (
        <Space size='middle'>
          {shouldReplace ? (
            <>
              <CopyOutlined
                className='IconTable'
                onClick={() => replaceProposalHandler(record)}
              />
            </>
          ) : (
            <>
              <EditOutlined
                className='IconTable'
                onClick={() => editProposalHandler(record)}
              />
              <DownloadOutlined
                className='IconTable'
                onClick={() => exportProposal(record)}
              />
            </>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      {showLoading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <Table
            size='middle'
            columns={columns}
            dataSource={proposals?.content}
            pagination={false}
            tableLayout='fixed'
          />
          {proposals &&
            proposals.pagination &&
            proposals.pagination.totalPages > 1 && (
              <Pagination
                onChange={onChange}
                current={current}
                total={proposals.pagination.totalElements}
                showSizeChanger={false}
              />
            )}
        </>
      )}
      <Modal
        title={modal.title}
        open={modal.open}
        onCancel={() => {
          hideModal();
        }}
        footer={modal.footer}
        width={800}
      >
        {modal.content}
      </Modal>
    </>
  );
};

export default ProposalHistory;
