import axios from "axios";
import store from "../../store";

import { findBoostITAccount } from "../../utils/findBoostITAccount";
import { msalInstance, tryFetchToken } from './Auth';
import { URL } from "../constants/urls";
import i18n from 'i18next';
import {LOCAL_STORAGE_KEYS} from '../constants/localStorage';

const setToken = store.getActions().userModel.setToken;

const cacheToken = (token) => {
  setToken(token);
  localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, token);
  updateInstanceToken(token);
}

const updateInstanceToken = (token) =>{
  apiInstance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${token}`;
}

const getInstanceToken = () => {
  return apiInstance.defaults.headers.common["Authorization"];
}

const renewToken = () => {
  const accounts = msalInstance.getAllAccounts();
  const account = findBoostITAccount(accounts);
  tryFetchToken(account);
};

const apiInstance = axios.create({ baseURL: URL.ENV_BASE_API});

const mockInstance = axios.create({ 
  baseURL: URL.MOCK_BASE_API,
});

const responseInterceptor = (response) => {
  return response;
};

const errorInterceptor = (error) => {
  console.log("API interceptor error", error);
  
  let reject = true;

  if (error?.response?.status === 401) {
    reject = false;
    renewToken(); 
  }

  if (reject){
    const {message} = error;
    if (message === undefined){
      error.message = i18n.t('genericErrorDescription');
    }
    return Promise.reject(error);
  };
};

apiInstance.interceptors.response.use(responseInterceptor, errorInterceptor);

mockInstance.interceptors.response.use(responseInterceptor, errorInterceptor);

export { apiInstance, mockInstance, cacheToken, renewToken, getInstanceToken, updateInstanceToken};