
import React from 'react'

import { ReloadOutlined, CheckOutlined, SaveOutlined, DownloadOutlined } from "@ant-design/icons";

import {
    Button,
    Col,
    Form,
    Row,
} from "antd";

import { useTranslation } from "react-i18next";

const SalaryFormFooter = ({
    resetForm,
    saveProposalHandler,
    exportProposalHandler,
    shouldShowProposalControls = false,
    newProposal = undefined,
}) => {
    const { t } = useTranslation();

    return (
        <Row justify="center">
        <Col sm={24} md={21}>
          <Form.Item
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={resetForm} icon={<ReloadOutlined />}>
              {t("resetForm")}
            </Button>
            &nbsp; &nbsp;
            <Button htmlType="submit" type="primary" icon={<CheckOutlined />}>
              {t("runSimulation")}
            </Button>
            { shouldShowProposalControls &&
              (
                <>
                  &nbsp; &nbsp;
                  <Button type="primary" onClick={() => saveProposalHandler()} icon={<SaveOutlined />}>
                    {t("proposalSaveBtn")}
                  </Button>            
                  &nbsp; &nbsp;      
                  <Button type="primary" onClick={() => exportProposalHandler()} icon={<DownloadOutlined />} disabled={newProposal === undefined}>
                    {t("proposalExportBtn")}
                  </Button>
                </>
              )
            }
          </Form.Item>
        </Col>
      </Row>
    )
}

export default SalaryFormFooter