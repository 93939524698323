import {
  Typography,
} from "antd";

import { useEffect } from "react";
import "./index.css";

import SalaryForm from "./components/SalaryForm";

const { Title } = Typography;

const Calculator = ({
  title,
  isProposal = false
}) => {

  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <>
      <Title level={2} className="pageTitle">
        { title }
      </Title>
      <div className="formWrapper">
        <SalaryForm 
          isProposal={isProposal}
          />
      </div>
    </>
  );
};

export default Calculator;