import React from 'react';
import Icon, {LoadingOutlined } from "@ant-design/icons";
import "./Company.css";

const Company = () => {
    return (
        <Icon className="loading-company" component={LoadingOutlined} />
    )
}

export default Company;