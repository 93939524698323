import dayjs from "dayjs";
import { BenefitFormDefaultsValue, BenefitFormItemType, BenefitsMap } from "../hooks/useBenefits.types";

export const workItemsMap: BenefitsMap = [
    {formField: 'benefitId', apiField: 'benefitId', output: { type: BenefitFormItemType.STRING }},
    {formField: 'description', apiField: 'description', output: { type: BenefitFormItemType.STRING }},
    {formField: 'proposals', apiField: 'isDefault', output: { type: BenefitFormItemType.BOOL }},
    {formField: 'state', apiField: 'status', output: { type: BenefitFormItemType.BOOL }},
    {formField: 'defaultValue', apiField: 'defaultValue', output: { type: BenefitFormItemType.NUMBER }},
    {formField: 'frequency', apiField: 'isRepeat', output: { type: BenefitFormItemType.STRING, valueCheck: {true: 'month', false: 'single'} }},
    {formField: 'maxValue', apiField: 'maximumValue', output: { type: BenefitFormItemType.NUMBER }},
    {formField: 'months', apiField: 'defaultMonths', output: { type: BenefitFormItemType.NUMBER }},
    {formField: 'irs', apiField: 'irs', output: { type: BenefitFormItemType.STRING, valueCheck: {true: 'yes', false: 'no'} }},
    {formField: 'tribAuto', apiField: 'taTax', output: { type: BenefitFormItemType.NUMBER }},
    {formField: 'vatDeductible', apiField: 'deductVat', output: { type: BenefitFormItemType.BOOL }},
    {formField: 'vatValue', apiField: 'vat', output: { type: BenefitFormItemType.NUMBER }},
    {formField: 'stateRange', apiField: ['startDate', 'endDate'], output: { type: BenefitFormItemType.DATE_RANGE }},
]

export const formDefaults:BenefitFormDefaultsValue = {
    defaults: {
        benefitId: null,
        irs: 'yes',
        stateRange:[dayjs(), null],
        frequency: 'single',
    },
    disabled: {
        state: true,
        vat: true,
    }
}