import { DeleteOutlined, FormOutlined  } from "@ant-design/icons";
import { Badge, Popconfirm, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";
import { IField } from "./FiledWithHistory.interface";
import { IHistoryComponent, ITableData } from "./History.interface";


const HistoryComponent = ({ history, onDelete }: IHistoryComponent) => {

  const tableData = history.map((item, index) =>  {return {...item, key: index}} ) as ITableData[];

  const { t } = useTranslation();

  const columns: ColumnsType<IField> = [
    {
      key: "badge",
      width: 30,
      render: (_, record) => record.isCurrent && <Badge status="success" />,
    },
    {
      key: "value",
      dataIndex: "value",
      title: t("employee.value"),
    },
    {
      key: "date",
      dataIndex: "date",
      title: t("date"),
      render: (text) => text.format("MMM YYYY"),
    },
    {
      key: "actions",
      align: "center",
      width: 70,
      render: (_, record) => (
        <>
        {
          onDelete && (
            <Popconfirm
              title={t("questionSure")}
              onConfirm={() => onDelete !== undefined && onDelete(record)}
              cancelText={t("cancel")}
              okText={t("yes")}
            >
              <a>
                <DeleteOutlined />
              </a>
            </Popconfirm>
          )
        }
        </>
      ),
    },
  ];

  return (
    <>
      <Typography.Title level={4}>{t("history")}</Typography.Title>
      <Table
        size="middle"
        columns={columns}
        dataSource={tableData}
        pagination={false}
        tableLayout="fixed"
      />
    </>
  );
};

export default HistoryComponent;
