import React, {useState, useEffect} from "react"

import {
    CheckCircleOutlined,
    CloseCircleOutlined,
} from "@ant-design/icons";

import { useTranslation } from 'react-i18next';

import {
    Modal,
    Space,
    Button
} from 'antd';

const MODAL_DEFAULT = {title: '', open: false, content: ''};

const ConfirmModal = ({
    shouldOpen = false, 
    data={}, 
    onNoHandler = null, 
    onYesHandler = null 
}) => {
    const [modal, setModal] = useState(MODAL_DEFAULT);
    const { t } = useTranslation();
    CheckCircleOutlined
    useEffect(()=> {
        if(shouldOpen) {
            setModal({content: data.content ?? '', title: data.title ?? '', open: true});
        } else {
            setModal(MODAL_DEFAULT);
        }
    },[shouldOpen, data, setModal])

    const onYes = () => {
        if (onYesHandler !== null) {
            onYesHandler();
        }
    }

    const onNo = () => {
        setModal(MODAL_DEFAULT);
        if (onNoHandler !== null) {
            onNoHandler();
        }
    }

    const hideModal = () => {
        setModal(MODAL_DEFAULT);
    }

    return (
        <Modal
            title={modal.title}
            open={modal.open}
            onCancel={() => {
                hideModal();
            }}
            footer={
                <Space direction='horizontal' size={'middle'}>
                    <Button onClick={() => onNo()} icon={<CloseCircleOutlined />}>{t('confirmModalNoBtnLbl')}</Button>
                    <Button type='primary' onClick={() => onYes()} icon={<CheckCircleOutlined />}>{t('confirmModalYesBtnLbl')}</Button>
                </Space>
            }
        >
            {modal.content}
        </Modal>
    )
}

export default ConfirmModal