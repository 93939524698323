import { euroFormater } from "./euroFormater";
import { percentageFormater } from "./percentageFormater";
import { TYPE_IRS_DETAIL } from "../shared/constants/irs-detail";

export const renderFormat = (itemType, itemValue) => {
    switch (itemType) {
      case TYPE_IRS_DETAIL.PERCENTAGE:
        return percentageFormater.format(itemValue / 100);
      case TYPE_IRS_DETAIL.CURRENCY:
        return euroFormater.format(itemValue);
      default:
        return itemValue;
    }
  };