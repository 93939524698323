import { TYPE_BREADCRUMB } from "../shared/constants/breadcrumb";

import { useTranslation } from "react-i18next";

export const getBreadCrumb = (type, locale, companyId, employeeId, travelReportId) => {
  const { t } = useTranslation();
  switch(type){
      case TYPE_BREADCRUMB.EMPLOYEE:
        return [
          {
            path: `/${locale}/${companyId ? `company/${companyId}/` : ""}`,
            breadcrumbName: t("home"),
          },
          {
            path: `/${locale}/company/${companyId}/employees`,
            breadcrumbName: t("employees"),
          },
          {
            path: `/${locale}/company/${companyId}/employee/${employeeId}`,
            breadcrumbName: t("employee"),
          },
        ];

      case TYPE_BREADCRUMB.EMPLOYEES:
        return  [
          {
            path: `/${locale}/${companyId ? `company/${companyId}/` : ""}`,
            breadcrumbName: t("home"),
          },
          {
            path: `/${locale}/company/${companyId}/employees`,
            breadcrumbName: t("employees"),
          },
        ];

      case TYPE_BREADCRUMB.CALCULATOR:
        return [
          {
            path: `/${locale}/${companyId ? `company/${companyId}/` : ""}`,
            breadcrumbName: t("home"),
          },
          {
            path: `/${locale}/company/${companyId}/calculator`,
            breadcrumbName: t("calculator"),
          }
      ];
      
      case TYPE_BREADCRUMB.TRAVEL_REPORT:
          return [
            {
              path: `/${locale}/${companyId ? `company/${companyId}/` : ""}`,
              breadcrumbName: t("home"),
            },
            {
              path: `/${locale}/company/${companyId}/employees`,
              breadcrumbName: t("employees"),
            },
            {
              path: `/${locale}/company/${companyId}/employee/${employeeId}/travel-reports`,
              breadcrumbName: t("travelReports"),
            },
            {
              path: `/${locale}/company/${companyId}/employee/${employeeId}/travel-report/${travelReportId}`,
              breadcrumbName: t("travelReport"),
            },
        ];
  
      case TYPE_BREADCRUMB.TRAVEL_REPORTS:
          return [
            {
              path: `/${locale}/${companyId ? `company/${companyId}/` : ""}`,
              breadcrumbName: t("home"),
            },
            {
              path: `/${locale}/company/${companyId}/employees`,
              breadcrumbName: t("employees"),
            },
            {
              path: `/${locale}/company/${companyId}/employee/${employeeId}/travel-reports`,
              breadcrumbName: t("travelReports"),
            },
        ];

      case TYPE_BREADCRUMB.ITINERARIES:
        return [
          {
            path: `/${locale}/${companyId ? `company/${companyId}/` : ""}`,
            breadcrumbName: t("home"),
          },
          {
            path: `/${locale}/company/${companyId}/itineraries`,
            breadcrumbName: t("itineraries"),
          },
        ];
      case TYPE_BREADCRUMB.PROPOSALS:
        return [
          {
            path: `/${locale}/${companyId ? `company/${companyId}/` : ""}`,
            breadcrumbName: t("home"),
          },
          {
            path: `/${locale}/company/${companyId}/proposals`,
            breadcrumbName: t("proposals"),
          },          
        ];

        case TYPE_BREADCRUMB.CONFIGURATIONS:
          return [
            {
              path: `/${locale}/${companyId ? `company/${companyId}/` : ""}`,
              breadcrumbName: t("home"),
            },
            {
              path: `/${locale}/company/${companyId}/configurations`,
              breadcrumbName: t("configurations"),
            },
          ];
  
        case TYPE_BREADCRUMB.BENEFITS:
          return [
            {
              path: `/${locale}/${companyId ? `company/${companyId}/` : ""}`,
              breadcrumbName: t("home"),
            },
            {
              path: `/${locale}/company/${companyId}/configurations`,
              breadcrumbName: t("configurations"),
            },
            {
              path: `/${locale}/company/${companyId}/configurations/benefits`,
              breadcrumbName: t("benefits"),     
            },          
          ];
  
        case TYPE_BREADCRUMB.BENEFITS_GROUPS:
          return [
            {
              path: `/${locale}/${companyId ? `company/${companyId}/` : ""}`,
              breadcrumbName: t("home"),
            },
            {
              path: `/${locale}/company/${companyId}/configurations`,
              breadcrumbName: t("configurations"),
            },
            {
              path: `/${locale}/company/${companyId}/configurations/benefits-group`,
              breadcrumbName: t("benefitGroups"),   
            },          
          ];
        case TYPE_BREADCRUMB.WORK_ITEMS:
          return [
            {
              path: `/${locale}/${companyId ? `company/${companyId}/` : ""}`,
              breadcrumbName: t("home"),
            },
            {
              path: `/${locale}/company/${companyId}/configurations`,
              breadcrumbName: t("configurations"),
            },
            {
              path: `/${locale}/company/${companyId}/configurations/work-items`,
              breadcrumbName: t("workItems"),     
            },     
          ];
          case TYPE_BREADCRUMB.COMMISSIONS:
            return [
              {
                path: `/${locale}/${companyId ? `company/${companyId}/` : ""}`,
                breadcrumbName: t("home"),
              },
              {
                path: `/${locale}/company/${companyId}/configurations`,
                breadcrumbName: t("configurations"),
              },
              {
                path: `/${locale}/company/${companyId}/configurations/commissions`,
                breadcrumbName: t("commissions"),     
              },     
            ];
            
    default:
      return [
        {
          path: `/${locale}/company/${companyId}/`,
          breadcrumbName: t("home"),
        }
      ];
  }
}