import { useState, useEffect } from "react";

const useWindowState = () => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
    },[setLoaded])

    return {loaded};
};

export default useWindowState;