import React from 'react';
import Footer from '../footer';
import "./MainContent.css";

const MainContent = ({content}) => {
    return (
      <>
        <div className="inner-body">
          {content}
          <Footer />
        </div>
      </>
    );
}

export default MainContent;