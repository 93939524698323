import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
} from "@ant-design/icons";

import {Space, Row, Col, Typography, Button, Card, Table, Divider, Pagination, notification} from 'antd';

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../../shared/constants/query";

import useScrollToElement from '../../shared/hooks/useScrollToElement';

//TODO: UNCOMENT TO USE BELLOW
//import EditCommissions from './EditCommissions';
import EditBenefit from '../benefits/EditBenefit';
import DeleteModal from '../delete-modal';
import useBenefits from "../../shared/hooks/useBenefits";
import StatusBadge from '../status-badge';

const { Title } = Typography;

const Commissions = () => {
    const {benefits, deleteBenefit, fetchCommissions, alphabeticalSort} = useBenefits();

    const [deleteModal, setDeleteModal] = useState({shouldOpen: false, data:{}});
    const [showCreateNew, setShowCreateNew] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const { scrollToElement } = useScrollToElement();
    const {t} = useTranslation();
    const [current, setCurrent] = useState(1);
    const [fetchingBenefits, setFetchingBenefits] = useState(false);

    const renderCommissions = useMemo(() => {        
        if(benefits) {
            return {
                content: benefits.content?.sort(alphabeticalSort), 
                pagination: {... benefits.pagination}
            };
        }
    },[benefits, alphabeticalSort])

    const onCreateNew = () => {
        setShowCreateNew(true);
    }

    const fetchCommissionPage = (page) => {
        setFetchingBenefits(true);
        fetchCommissions(page, DEFAULT_PAGE_SIZE, () => {
            setFetchingBenefits(false);
        });
    }

    const onPaginationChange = (page) => {        
        setCurrent(page);
        fetchCommissionPage(page);
    }

    const onSuccessHandler = (refetch = false) => {
        if(refetch){
            fetchCommissionPage(current);
        }
    }

    const onCancelHandler = (refetch = false) => {
        setSelectedItem(null);
        setShowCreateNew(false);    
        if(refetch){
            fetchCommissionPage(current);
        }
    }

    const onDeleteOkHandler = () => {
        deleteBenefit(
            deleteModal.data.record,
            () => {
                setSelectedItem(null);
                fetchCommissionPage(current);
                setDeleteModal({shouldOpen: false, data:{}})
                notification.success({
                    message: t('commissionsDeletedSuccess'),
                    placement: "bottomRight",
                });                    
            },
            (error) => {
                setDeleteModal({shouldOpen: false, data:{}})
                notification.error({
                    message: t('commissionsDeletedFail'),
                    description: error.message,
                    placement: "bottomRight",
                });
            }       
        )    
    }

    const onDeleteCancelHandler = () => {
        setDeleteModal({shouldOpen: false, data:{}});
        setSelectorResults(null);
    }

    const onEdit = (record) => {
        setSelectedItem(record);
        setTimeout(() => {
            scrollToElement(document.getElementById('edit-commissions'));        
        }, 500);
    }

    const onDelete = (record) => {
        setDeleteModal({shouldOpen: true, data:{
            record,
            content: t('commissionsDeleteConfirmation', {label: record.description})
        }})
    }

    const doFirstRender = () => {
        fetchCommissionPage(DEFAULT_PAGE);
    }

    useEffect(()=> {
        if(benefits === null){
            doFirstRender();
        }
    },[benefits, doFirstRender]);

    const columns = [
        {
            title: t("commissionsStatus"),
            dataIndex: "status",
            width: '20%',
            key: "status",
            render: (_, record) => <StatusBadge status={record.status}/>,
        },
        {
            title: t("commissionsDescriptionLabel"),
            dataIndex: "description",
            key: "description",
            render: (_, record) => record.description,
        },
        {
            title: t("commissionsValueLabel"),
            dataIndex: "value",
            key: "value",
            render: (_, record) => (<>{`${record.defaultValue ?? 0} ${t('euroSymbol')}`}</>),
        },
        {
            title: t("actionLabel"),
            key: "action",
            width: 190,
            align: "right",
            render: (_, record) => (
              <Space size="middle">
                <EditOutlined className='IconTable' onClick={() => onEdit(record)}/>
                <DeleteOutlined className='IconTable' onClick={() => onDelete(record)}/>
              </Space>
            ),
        }
    ];

    return (
        <>
            <Row justify={'center'}>
                <Col span={24}>
                    <Title level={1} className="pageTitle">
                    { t('commissionsLbl')} 
                    </Title>
                </Col>
            </Row> 
            <Row justify={'center'} className="paddingBottom2Rem paddingTop1Rem">
            <Col sm={24} md={15}>
                    <Row>
                        <Col sm={24} md={24}>
                            {showCreateNew ? (
                            <Row>
                                <Col sm={24} md={24}>
                                    <Title level={3} className="marginZero">{t("commissionsCreateNewLbl")}</Title>
                                </Col>
                            </Row>
                            ) : (
                                <Row>
                                    <Col sm={24} md={10}>
                                        <Title level={3} className="marginZero">{selectedItem ? t("commissionsEditLbl") : t("commissionsConsultLbl")}</Title>
                                    </Col>
                                    <Col sm={24} md={14} className="alignRight">
                                        <Button type="primary" icon={<PlusOutlined />} onClick={onCreateNew}>{t('commissionsCreateNewLbl')}</Button>
                                    </Col>
                                </Row>
                            )}
                            <Divider className="marginBottom1Rem marginTop05Rem" />                                
                        </Col>
                    </Row>
                    {!showCreateNew && (
                        <Row className="paddingTop1Rem">
                            <Col sm={24} md={24}>
                                <Card
                                    title={
                                        <Row justify="center">
                                            <Col sm={24} md={24}>
                                                <b>{t('commissionsListLbl')}</b>
                                            </Col>
                                        </Row>
                                    }
                                >
                                <Table
                                    loading={fetchingBenefits}
                                    size="middle"
                                    columns={columns}
                                    dataSource={renderCommissions?.content}
                                    pagination={false}
                                    tableLayout="fixed"
                                    rowKey="benefitId"
                                    onRow={(record, _) => ({
                                        className: selectedItem && record.benefitId === selectedItem.benefitId ? 'selected-row' : null
                                      })
                                    }
                                />
                                {renderCommissions && renderCommissions?.pagination.totalPages > 1 && 
                                    (
                                        <Pagination
                                            onChange={onPaginationChange}
                                            current={current}
                                            total={renderCommissions.pagination.totalElements}
                                            showSizeChanger={false}
                                        />
                                    )
                                }
                                </Card>
                            </Col>
                    </Row>
                    )}
                    <div id='edit-commissions'>
                        {
                            (selectedItem || showCreateNew) && (   
                                <EditBenefit 
                                    selectedBenefit={selectedItem} 
                                    createNew={showCreateNew} 
                                    onCancelHandler={onCancelHandler}
                                    onSuccessHandler={onSuccessHandler}
                                    isEditingCommissions={true}
                                />
                            )
                        }
                    </div>
                    { deleteModal && deleteModal.shouldOpen && (
                        <DeleteModal
                            shouldOpen={deleteModal.shouldOpen}
                            data={deleteModal.data}
                            onOkHandler={onDeleteOkHandler}
                            onCancelHandler={onDeleteCancelHandler}
                        />
                        )
                    }
                </Col>            
            </Row>
        </>
    );
}

export default Commissions;