import React, {useEffect} from 'react';
import { useNavigate, useParams } from "react-router-dom";

const Configurations = () => {
    const {locale, companyId} = useParams();
    const navigate = useNavigate();

    useEffect(()=> {
        navigate(`/${locale}/company/${companyId}/`);
    }, [locale, companyId])

    return (
        <></>
    )
}

export default Configurations;