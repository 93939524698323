import react from 'react';

import { CloseCircleOutlined } from "@ant-design/icons";

import {
    Button,
    Card,
    Col,
    Form,
    InputNumber,
    Row,
    Select,
    Typography,
    Space, 
    Radio,
} from "antd";

const { Title } = Typography;
const { Option } = Select;

import { useTranslation } from "react-i18next";

const SalaryFormCommissions = ({
    shouldLoadDefaults = false,
    commissions = [],
    includedCommission = [],
    commissionsHandleAdd,
    onCommissionChange,
    commissionHandleRemove,
    onCommissionPaymentChange
}) => {
    const { t } = useTranslation();

    return (
        <Card
            title={
            <Row align="middle">
                <Col xs={24} sm={12}>
                <Title level={5} className="margin0">
                    {t("commissionsTitle")}
                </Title>
                </Col>
                <Col xs={24} sm={12}>
                <Form.Item
                    name="commissions"
                    rules={[
                    {
                        required: false,
                        message: t("commissionsQuestion"),
                    },
                    ]}
                    className="marginBottom0"
                >
                    <Select
                    placeholder={t(
                        "selectCommissionsToInclude"
                    )}
                    onChange={commissionsHandleAdd}
                    allowClear
                    >
                    {commissions &&
                    commissions.map((commission, index) => {
                        return (
                        <Option
                            key={`commission-${index}`}
                            value={commission.commissionCode}
                            disabled={commission.isDefault || commission.disabledByExclusivity}
                        >
                            {commission.commissionDescription}
                        </Option>
                        );
                    })}
                    </Select>
                </Form.Item>
                </Col>
            </Row>
            }
        >
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            {
            !shouldLoadDefaults && includedCommission &&
                includedCommission.map(commission => {
                    return (
                        <Row
                            justify="space-between"
                            align="middle"
                            key={`includedCommission-${commission.commissionCode}`}
                            className={!commission.isActive ? 'row-disabled' : ''}
                        >
                            <Col xs={10}>{commission.commissionDescription}</Col>
                            <Col xs={6}>
                                <Form.Item
                                    name={`includedCommission-value-${commission.commissionCode}`}
                                    initialValue={commission.defaultValue ?? 0}
                                    style={{marginBottom: '0'}}
                                >
                                <InputNumber
                                    status={commission.status}
                                    className="fullWidth"
                                    min={0}
                                    step={1}
                                    addonAfter={t('euroSymbol')}
                                    max={commission.maximumValue ? commission.maximumValue : null}
                                    onChange={(value) => {
                                        onCommissionChange(value, commission.commissionCode);
                                    }}
                                />
                                </Form.Item>
                            </Col>
                            <Col xs={5}>
                                <Form.Item
                                    name={`includedCommission-paymentRepeat-${commission.commissionCode}`}
                                    valuePropName="checked"
                                    style={{margin: '0', padding: '0'}}
                                >
                                    <Radio.Group
                                        onChange={(value) => {
                                            onCommissionPaymentChange(value, commission.commissionCode)
                                        }}
                                        value={commission.paymentRepeat? 'month' : 'single'}
                                    >
                                        <Radio value="month">{t("commissionMonthLabel")}</Radio>
                                        <Radio value="single">{t("commissionSingleLabel")}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col xs={1}>
                                <Button
                                    type="text"
                                    shape="circle"
                                    size="small"
                                    icon={<CloseCircleOutlined />}
                                    disabled={commission.isDisabled ?? false}
                                    className={commission.isDisabled? 'btn-disabled' : null}                                        
                                    onClick={() => {
                                        commissionHandleRemove(commission.commissionCode);
                                    }}
                                />
                            </Col>
                        </Row>
                    )                        
                })                     
            }
            </Space>
        </Card>
    );
}

export default SalaryFormCommissions;