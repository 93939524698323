import {
  Col,
  Input,
  Pagination,
  Row,
  Table,
  Typography,
} from "antd";
import { useStoreState } from "easy-peasy";
import {useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import useAppClient from "../../shared/hooks/useAppClient";

import { getEmployeeColumns } from "../../utils/getEmployeeColumns";

const { Title } = Typography;
const { Search } = Input;

function Employees() {
  const apiInstance = useAppClient();
  const { t } = useTranslation();
  const { locale, companyId } = useParams();
  
  const navigate = useNavigate();

  const onCustomCell = (record, _) => {
    return {
        onClick: (_) => {
          navigate(`/${locale}/company/${companyId}/employee/${record.id}`)
        },
    };
  }

  const columns = getEmployeeColumns(t, onCustomCell, locale, companyId);

  const companyName = useStoreState((state) => state.selectedCompanyModel.name);
  const [loadingEmployees, setLoadingEmployees] = useState(true);
  const [employees, setEmployees] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [current, setCurrent] = useState(1);

  const [searchText, setSearchText] = useState("");

  const handleSearch = (selectedKeys) => {
    typeof selectedKeys === "object"
      ? (selectedKeys = selectedKeys.target.value)
      : (selectedKeys = selectedKeys);
    if (selectedKeys.length < 3 && selectedKeys !== "") return;
    setEmployees(null);
    setCurrent(1);
    setSearchText(selectedKeys);
  };

  const getEmployees = async (current) => {
    setLoadingEmployees(true);
    apiInstance
      .get(
        `/employees/employees?companyId=${companyId}&page=${
          current - 1
        }&size=10${searchText.length > 2 ? `&search=${searchText}` : ""}`
      )
      .then((response) => {
        setEmployees(response.data.content);
        setPagination(response.data.pagination);
        setLoadingEmployees(false);
      });
  };

  const onChange = (page) => {
    setCurrent(page);
  };

  useEffect(() => {
    if (searchText && searchText.length > 2) {
      let timer = setTimeout(() => {
        getEmployees(current);
      }, 400);

      return () => clearTimeout(timer);
    } else {
      getEmployees(current);
    }
  }, [current, searchText]);

  useEffect(() => {
    document.title = `Employees${
      typeof companyName === "string" ? ` — ${companyName}` : ""
    }`;
  }, []);

  return (
    <>
      <Row>
        <Col span={20}>
          <Title level={2} className="pageTitle">
            {t("employees")}
          </Title>
        </Col>
        <Col span={4} align="right">
          <Search
            placeholder={t("employeeSearchPlaceholder")}
            onSearch={handleSearch}
            onChange={handleSearch}
            enterButton
            allowClear
          />
        </Col>
      </Row>
      <Table
        loading={loadingEmployees}
        dataSource={employees}
        columns={columns}
        rowKey="id"
        pagination={false}
      />
      {employees && pagination.totalPages > 1 && (
        <Pagination
          onChange={onChange}
          current={current}
          total={pagination.totalElements}
          showSizeChanger={false}
        />
      )}
    </>
  );
}

export default Employees;
