import { Button, Col, Row, Space, Statistic } from "antd";
import { useTranslation } from "react-i18next";
import useIsOpen from "../../../../shared/hooks/useIsOpen";
import CreateNewFieldModalForm from "./CreateNewFieldModalForm";
import { IActiveValue } from "./FiledWithHistory.interface";
import { useMemo } from 'react';
const ActiveValue = ({
  field,
  onCreate,
  valueFormComponent,
  modalDescription,
  showActiveValue = false,
  onCustomAddItem,
  headerComponent,
}: IActiveValue) => {
  const { isOpen, open, close } = useIsOpen();
  const { value, date } = field ? field : { value: undefined, date: undefined };
  const { t } = useTranslation();

  return (
    <Space direction="vertical" size="large" style={{ whiteSpace: "nowrap" }}>
      <Row gutter={56}>
        { 
          headerComponent && (
            <Col sm={24} md={24}>          
                {headerComponent}
            </Col>        
          )
        }
        {
          showActiveValue && (
            <>
              <Col sm={24} md={24}>
                <Statistic title={t("employee.active")} value={value || "N/D"} />
              </Col>
              <Col sm={24} md={24}>
                <Statistic
                  title={t("employee.validSince")}
                  value={date ? date.format("MMM YYYY") : "N/D"}
                />
              </Col>            
            </>
          )
        }

      </Row>

      <Row>
        <Col sm={24} md={24}>
          {
            onCustomAddItem !== undefined ? (
              <>
                <Button onClick={onCustomAddItem}>{t("add")}</Button>
              </>
            ) : (
              <>
                { onCreate !== undefined && <Button onClick={open}>{t("add")}</Button> }
                <CreateNewFieldModalForm
                  open={isOpen}
                  onCreate={(values) => {
                    close();
                    if (onCreate !== undefined) {
                      onCreate(values);
                    }
                  }}
                  onCancel={close}
                  valueFormComponent={valueFormComponent}
                  description={modalDescription}
                />
              </>
            )
          }
        </Col>
      </Row>
    </Space>
  );
};

export default ActiveValue;
