
import { useState, useCallback } from "react";
import useAppClient from "./useAppClient";
import { Group, Benefit, BenefitsGroupsState, BenefitsApiGroup, BenefitsGroupState, UseBenefitGroups } from "./useBenefitGroups.types";

const useBenefitGroups = (): UseBenefitGroups => {
    const [group, setGroup] = useState<BenefitsGroupState>(null);
    const [groups, setGroups] = useState<BenefitsGroupsState>(null);
    const [searchResults, setSearchResults] = useState<BenefitsGroupsState>(null);
    const apiInstance = useAppClient(true);
    
    const defaultOrder = (current:Benefit, next:Benefit) => {
        const currentSort = Number(current.order);
        const nextSort = Number(next.order)
         
        if (currentSort < nextSort) {
            return -1;
        } else if (currentSort === nextSort) {
            return 0;
        } else {
            return 1;
        }
    };

    const alphabeticalSort = (current:Benefit, next:Benefit) => {
        const currentSort = current.description;
        const nextSort = next.description;

        if (currentSort < nextSort) {
            return -1;
          }
          if (currentSort > nextSort) {
            return 1;
          }
          return 0;
    };

    const fetch = useCallback((page = 1, size = 10, successHandler?: ()=> void, errorHandler?: (error:Error) => void) => {
        apiInstance.get<BenefitsApiGroup>(`/hr-simulation/benefits/groups?page=${
            page
          }&size=${size}`)
        .then((response) => {
            const { content, pagination }:BenefitsApiGroup = response?.data;
            setGroups({content, pagination});
            if(successHandler !== undefined){
                successHandler();
            }
        })
        .catch((error) => {
            if(errorHandler !== undefined){
                errorHandler(error);
            }   
        });
    },[setGroups]);

    const fetchAll = () => fetch(1, 999999);

    const createGroup = (name: string, successHandler?: ()=> void, errorHandler?: (error:Error) => void) => {
        apiInstance.post('/hr-simulation/benefits/groups', {
            description: name
        })
        .then((_) => {
            if (successHandler !== undefined){
                successHandler();
            }
        })
        .catch((error) => {
            if(errorHandler !== undefined){
                errorHandler(error);
            }
        });
    }

    const fetchGroup = (groupId: string, successHandler?: ()=> void, errorHandler?: (error:Error) => void) => {
        apiInstance.get<Group>(`/hr-simulation/benefits/groups/${groupId}`)
        .then((response) => {
            const data = response?.data;
            if (data){
                const ordered = data.benefits.sort(defaultOrder);
                setGroup({
                    ... data,
                    benefits: ordered,
                });
                if(successHandler !== undefined){
                    successHandler();
                }
            }

            if(successHandler !== undefined){
                successHandler();
            }
        })
        .catch((error) => {
            if(errorHandler !== undefined){
                errorHandler(error);
            }
        });
    }

    const deleteGroup = (groupId: string, successHandler?: ()=> void, errorHandler?: (error:Error) => void) => {
        apiInstance.delete(`/hr-simulation/benefits/groups/${groupId}`)
        .then((_) => {
            if(successHandler !== undefined){
                successHandler();
            }
        })
        .catch((error) => {
            if(errorHandler !== undefined){
                errorHandler(error);
            }
        });
    }

    const renameGroup = (group: Group, newName: string, successHandler?: ()=> void, errorHandler?: (error:Error) => void) => updateGroup({...group, description: newName}, group.benefits, successHandler, errorHandler);

    const updateGroup = (group: Group, benefits: Benefit[], successHandler?: ()=> void, errorHandler?: (error:Error) => void) => {
        apiInstance.put(`/hr-simulation/benefits/groups/${group.benefitGroupId}`,{
            description: group.description,
            benefits: benefits
        })
        .then((_) => {
            if(successHandler !== undefined){
                successHandler();
            }
        })
        .catch((error) => {
            if(errorHandler !== undefined){
                errorHandler(error);
            }
        });
    }


    const searchGroup = useCallback((name:string, page = 1, size = 9999, successHandler?: ()=> void, errorHandler?: (error:Error) => void) => {
        if(name === undefined || name.length < 1) {
            setSearchResults(null);
        } else {
            apiInstance
            .get<BenefitsApiGroup>(`/hr-simulation/benefits/groups?page=${
                page
              }&size=${size}&search=${name}`)    
            .then((response) => {
                const data = response?.data;
                if (data){
                    setSearchResults({
                        ... data,
                    });
                    if(successHandler !== undefined){
                        successHandler();
                    }
                }
            })
            .catch((error) => {
                if(errorHandler !== undefined){
                    errorHandler(error);
                }
            });    
        }
    },[setSearchResults])

    return {
        groups,
        group,
        searchResults,
        fetch,
        fetchAll,
        createGroup,
        fetchGroup,
        deleteGroup,
        searchGroup,
        updateGroup,
        defaultOrder,
        renameGroup,
        alphabeticalSort
    }
}

export default useBenefitGroups;