import react from 'react';

import { CloseCircleOutlined } from "@ant-design/icons";

import {
    Button,
    Card,
    Col,
    Form,
    InputNumber,
    Row,
    Select,
    Typography,
    Space, 
} from "antd";

const { Title } = Typography;
const { Option } = Select;

import { useTranslation } from "react-i18next";

const SalaryFormAdditionalIncome = ({
    shouldLoadDefaults = false,
    extraSalary=[],
    includedExtraSalary=[],
    extraSalaryHandleAdd,
    onExtraSalaryChange,
    extraSalaryHandleRemove,
}) => {
    const { t } = useTranslation();

    return (
        <Card
        title={
          <Row align="middle">
            <Col xs={24} sm={12}>
              <Title level={5} className="margin0">
                {t("additionalIncome")}
              </Title>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="extra-salary"
                rules={[
                  {
                    required: false,
                    message: t("additionalIncomeQuestion"),
                  },
                ]}
                className="marginBottom0"
              >
                <Select
                  placeholder={t("selectAdditionalIncomeToInclude")}
                  onChange={extraSalaryHandleAdd}
                  allowClear
                >
                  {extraSalary &&
                    extraSalary.map((income, index) => {
                      return (
                        <Option
                          key={`income-${index}`}
                          value={income.extraSalaryCode}
                          disabled={
                            income.isDefault ||
                            income.disabledByExclusivity
                          }
                        >
                          {income.extraSalaryDescription}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        }
      >
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          {
          !shouldLoadDefaults && includedExtraSalary &&
            includedExtraSalary.map((income, _) => {
              return (
                <Row
                  justify="space-between"
                  align="middle"
                  key={`includedExtraSalary-${income.extraSalaryCode}`}
                  className={!income.isActive ? 'row-disabled' : ''}
                >
                  <Col xs={12}>{income.extraSalaryDescription}</Col>
                  <Col xs={5}>
                    <Form.Item
                      name={`includedExtraSalary-value-${income.extraSalaryCode}`}
                      initialValue={income.valueValue ?? 0}
                      style={{marginBottom: '0'}}
                    >
                      <InputNumber
                        readOnly={!income.isEditable}
                        className="fullWidth"
                        status={income.status}
                        step={1}
                        min={0}
                        max={income.maximumValue ? income.maximumValue : null}
                        placeholder={t("valueOfAdditionalIncome")}
                        addonAfter={t('euroSymbol')}
                        onChange={(value) => {
                          onExtraSalaryChange(
                            value,
                            "valueValue",
                            income.extraSalaryCode,
                            true
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={5}>
                    <Form.Item
                      name={`includedExtraSalary-month-${income.extraSalaryCode}`}
                      initialValue={income.monthValue ?? 0}
                      style={{marginBottom: '0'}}
                    >
                      <InputNumber
                        readOnly={!income.isEditable}
                        className="fullWidth"
                        min={1}
                        max={14}
                        placeholder={t("lengthOfAdditionalIncome")}
                        addonAfter={t("months")}
                        onChange={(value) => {
                          onExtraSalaryChange(
                            value,
                            "monthValue",
                            income.extraSalaryCode,
                            false
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={1} align="right">
                    <Button
                      type="text"
                      shape="circle"
                      size="small"
                      icon={<CloseCircleOutlined />}
                      disabled={income.isDisabled ?? false}
                      className={income.isDisabled? 'btn-disabled' : null}                                    
                      onClick={() => {
                        extraSalaryHandleRemove(income.extraSalaryCode);
                      }}
                    ></Button>
                  </Col>
                </Row>
              );
          })}
        </Space>
      </Card>
    );
}

export default SalaryFormAdditionalIncome;