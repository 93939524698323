import { Breadcrumb as AntdBreadcumb } from "antd";
import React, {useEffect, useState} from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { TYPE_BREADCRUMB } from "../../shared/constants/breadcrumb";
import { getBreadCrumb } from '../../utils/getBreadCrumb';

import "./Breadcrumbs.css";

function Breadcrumbs() {
  const location = useLocation();
  const { locale, companyId, employeeId, travelReportId } = useParams();
  const [breadcrumbs, setBreadcrumbs] = useState();

  const employeeBreadcrumbs = getBreadCrumb(TYPE_BREADCRUMB.EMPLOYEE, locale, companyId, employeeId, travelReportId);
  const employeesBreadcrumbs = getBreadCrumb(TYPE_BREADCRUMB.EMPLOYEES, locale, companyId, employeeId, travelReportId);
  const travelReportsBreadcrumbs = getBreadCrumb(TYPE_BREADCRUMB.TRAVEL_REPORTS, locale, companyId, employeeId, travelReportId); 
  const travelReportBreadcrumbs = getBreadCrumb(TYPE_BREADCRUMB.TRAVEL_REPORT, locale, companyId, employeeId, travelReportId);
  const calculatorBreadcrumbs = getBreadCrumb(TYPE_BREADCRUMB.CALCULATOR, locale, companyId, employeeId, travelReportId);
  const itinerariesBreadcrumbs =  getBreadCrumb(TYPE_BREADCRUMB.ITINERARIES, locale, companyId, employeeId, travelReportId);
  const defaultBreadcrumbs =  getBreadCrumb(null, locale, companyId, employeeId, travelReportId);
  const proposalsBreadcrumbs = getBreadCrumb(TYPE_BREADCRUMB.PROPOSALS, locale, companyId, employeeId, travelReportId);
  const configurationsBreadcrumbs = getBreadCrumb(TYPE_BREADCRUMB.CONFIGURATIONS, locale, companyId, null, null);
  const benefitsBreadcrumbs = getBreadCrumb(TYPE_BREADCRUMB.BENEFITS, locale, companyId, null, null);
  const benefitsGroupsBreadcrumbs = getBreadCrumb(TYPE_BREADCRUMB.BENEFITS_GROUPS, locale, companyId, null, null);
  const workItemsBreadcrumbs = getBreadCrumb(TYPE_BREADCRUMB.WORK_ITEMS, locale, companyId, null, null);
  const commissionsBreadcrumbs = getBreadCrumb(TYPE_BREADCRUMB.COMMISSIONS, locale, companyId, null, null);

  useEffect(() => {
    if (location.pathname.includes("travel-report/")) {
      setBreadcrumbs(travelReportBreadcrumbs);
    } else if (location.pathname.includes("calculator")) {
      setBreadcrumbs(calculatorBreadcrumbs);
    } else if (location.pathname.includes("travel-reports")) {
      setBreadcrumbs(travelReportsBreadcrumbs);
    } else if (
      location.pathname.endsWith("employees")
    ) {
      setBreadcrumbs(employeesBreadcrumbs);
    } else if (
      location.pathname.endsWith("configurations")
    ) {
      setBreadcrumbs(configurationsBreadcrumbs);
    } else if (
      location.pathname.endsWith("benefits")
    ) {
      setBreadcrumbs(benefitsBreadcrumbs);
    } else if (
      location.pathname.endsWith("benefits-group")
    ) {
      setBreadcrumbs(benefitsGroupsBreadcrumbs);
    } else if (
      location.pathname.endsWith("work-items")
    ) {
      setBreadcrumbs(workItemsBreadcrumbs);
    } else if (
      location.pathname.endsWith("commissions")
    ) {
      setBreadcrumbs(commissionsBreadcrumbs);
    } else if (
      location.pathname.includes("employee") &&
      !location.pathname.includes("employees")
    ) {
      setBreadcrumbs(employeeBreadcrumbs);
    } else if (location.pathname.includes("itineraries")) {
      setBreadcrumbs(itinerariesBreadcrumbs);
    } else if (location.pathname.includes("proposals")){
      setBreadcrumbs(proposalsBreadcrumbs);
    } else {
      setBreadcrumbs(defaultBreadcrumbs);
    }
  }, [location]);

  return (
    <AntdBreadcumb 
      className="breadcrumbs"
      items={breadcrumbs?.map((breadcrumb) => 
        ( {title: <Link to={breadcrumb.path}>{breadcrumb.breadcrumbName}</Link>})
      )}
    >
    </AntdBreadcumb>
  );
}

export default Breadcrumbs;
