import { Col, Dropdown, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useEmployeeContext } from "../Employee.context";
import { MenuOutlined } from "@ant-design/icons";

const { Title } = Typography;

const EmployeeHeader = () => {
  const employee = useEmployeeContext();
  const { locale } = useParams();

  const { t } = useTranslation();

  return (
    <Row>
      <Col span={18}>
        <Title level={2} className="pageTitle">
          {employee.name}
        </Title>
      </Col>
      <Col span={6} style={{ textAlign: "right" }}>

      <Dropdown
            menu={{
              items: [
                {
                  key: 0,
                  label: (
                    <Link
                      to={`/${locale}/company/${employee.company}/employee/${employee.id}/travel-reports`}
                    >
                      {t("travelReportsButton")}
                    </Link>
                  ),
                },
                {
                  key: 1,
                  label: (
                    <Link
                      to={`/${locale}/company/${employee.company}/employee/${employee.id}/per-diems/NATIONAL`}
                    >
                      {t("acReportsButton")}
                    </Link>
                  ),
                },
              ],
            }}
          >
            <a href="/" onClick={(e) => e.preventDefault()}>
              <MenuOutlined />
            </a>
          </Dropdown>
      </Col>
    </Row>
  );
};

export default EmployeeHeader;
