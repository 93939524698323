import CalculatorApp from "../../components/calculator";
import { useTranslation } from "react-i18next";

const Proposal = () => {
    const { t } = useTranslation();
    return (
        <CalculatorApp 
            title={t("proposalsEditor")}
            isProposal={true} 
        />
    );
}

export default Proposal;