import { Badge } from "antd";
import { useTranslation } from 'react-i18next';

const StatusBadge = ({status}) => {
    const {t} = useTranslation();
    
    return (
        status ? 
        <>
            <Badge status="success" /> {t('statusBadgeActive')}
        </> : 
        <>
            <Badge status="error" /> {t('statusBadgeInactive')}
        </>
    )
}

export default StatusBadge;