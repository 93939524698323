import type { Dayjs } from 'dayjs';
export interface BenefitsApiRoot {
    content: Benefit[]
    pagination: Pagination
}
export interface Benefit {
    benefitId: string
    code: string
    grouped: string
    description: string
    startDate: string
    endDate: any
    status: string
    sortOrder: string
    isRequired: boolean
    isDefault: boolean
    isEditable: boolean
    isDaily: boolean
    defaultValue: number
    defaultMonths: number
    maximumValue?: number
    extraDefinitions: any
    exclusiveEntryCodes: string[]
    irs: boolean
    socialSecurity: boolean
    socialSecurityCompany: number
    socialSecurityEmployee: number
    taTax: number
    vat: number
    deductVat: boolean
    commissionValue: number
    commissionPercentage: number
    alerts: boolean
    alertPercentage1: any
    alertPercentage2: any
    requiresApproval: boolean
    approvalValue: any
    approvalPercentage: any
  }
  
  export interface Pagination {
    page: number
    size: number
    totalPages: number
    totalElements: number
  }

  export enum BenefitFormItemType {
    STRING,
    NUMBER,
    BOOL,
    DATE_RANGE
  }

  export type BenefitMapsItem = {
      formField: string,
      apiField: string | string[],
      output: {
        type: BenefitFormItemType,
        value?: BenefitFormStateValue,
        valueCheck?: ValueCheck | undefined,
        refField?: string,
      }
  }

  export type BenefitsMap = BenefitMapsItem[];

  export type BenefitFormStateValue = number | string | string[] | boolean | null | Dayjs | [Dayjs, Dayjs] | [Dayjs, null];

  export type BenefitFormDefaultsValue = {
    defaults: BenefitFormState,
    disabled?: BenefitFormState,
    labels?: BenefitFormState
  };

  export type BenefitForm = {
    [key: string] : BenefitFormStateValue;
  };

  export type BenefitAPI = { [key: string] : BenefitFormStateValue };

  export type BenefitFormState = BenefitForm | null;

  export type BenefitsState = BenefitsApiRoot | null;

  export type ValueCheck = {
    true: string,
    false: string
  }

  export type BenefitState = Benefit | null;

  export type UseBenefitsType = {
    searchResults: BenefitsState,
    benefits: BenefitsState,
    benefit: BenefitState,
    formBenefit:BenefitFormState | null,
    fetchBenefits: (page?: number, size?: number, successHandler?: ()=> void, errorHandler?: (error: Error) => void) => void,
    fetchBenefit: (benefitId: string, successHandler?: ()=> void, errorHandler?: (error: Error) => void) => void,
    fetchWorkItems: (page?: number, size?: number, successHandler?: ()=> void, errorHandler?: (error: Error) => void) => void,
    fetchCommissions: (page?: number, size?: number, successHandler?: ()=> void, errorHandler?: (error: Error) => void) => void,
    computeFormBenefit: (benefit:Benefit, defaults:any) => void,
    convertFormToBenefitAPI: (formData:BenefitFormState) => void,
    computeFormWorkItem: (benefit:Benefit, defaults:any) => void,
    computeFormCommissions: (benefit:Benefit, defaults:any) => void,
    convertFormToWorkItemAPI: (formData:BenefitFormState) => void,
    convertFormToCommissionsAPI: (formData:BenefitFormState) => void,
    createBenefit: (data: BenefitAPI, successHandler?: ()=> void, errorHandler?: (error: Error) => void) => void,
    updateBenefit: (data: BenefitAPI, successHandler?: ()=> void, errorHandler?: (error: Error) => void) => void,
    deleteBenefit: (data: BenefitAPI, successHandler?: ()=> void, errorHandler?: (error: Error) => void) => void,
    searchBenefit: (descriptionTerm:string, page?: number, size?: number, successHandler?: ()=> void, errorHandler?: (error: Error) => void) => void,
    searchWorkItem: (descriptionTerm:string, page?: number, size?: number, successHandler?: ()=> void, errorHandler?: (error: Error) => void) => void,
    searchCommissions: (descriptionTerm:string, page?: number, size?: number, successHandler?: ()=> void, errorHandler?: (error: Error) => void) => void,
    defaultOrder: (current: Benefit, next: Benefit) => 0 | 1 | -1,
    alphabeticalSort: (current: Benefit, next: Benefit) => 0 | 1 | -1
  }