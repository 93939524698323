import React from 'react';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import './Footer.css';

const AntdFooter = Layout.Footer;

function Footer() {
    const { t } = useTranslation();

    return (
        <AntdFooter className='footer'>
        Nexus Group ©2024
        <span className='dot'>•</span>
        <span className='created-with'>{t('createdWith')}</span>
        <span className='hearth'>♥</span>
      </AntdFooter>
    );
  }
  
  export default Footer;
  
