export const COMPANIES = {
    SEDE: 'SEDE',
    MERCEDES: 'MERCEDES',
    CARLSBERG: 'CARLSBERG',
    LEROY_MERLIN: 'LEROY MERLIN',
    WELLA: 'WELLA',
    FARFETCH: 'FARFETCH',
    CELFOCUS: 'CELFOCUS',
    SANTANDER: 'SANTANDER',
    MAERSK: 'MAERSK',
    BOOST: 'BOOST',
    SHIELD: 'BNP',
    BNP: 'BNP',
    GLS: 'GLS',
    NOS: 'NOS',
}