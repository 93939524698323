import React from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { cacheToken } from '../../shared/clients/App';

import './Logout.css';

function Logout() {
  const { locale } = useParams();
  const { instance } = useMsal();
  const navigate = useNavigate();

  React.useEffect(() => {
    document.title = `Logout`;
    cacheToken(null);
    navigate(`/${locale}`, { replace: true });
    instance.logout();
  }, []);
}

export default Logout;