import {CloseCircleFilled} from "@ant-design/icons";
import { useState, useMemo, useEffect } from "react";
import useBenefits from '../../shared/hooks/useBenefits';

import { AutoComplete } from "antd";
import {DEFAULT_PAGE_SIZE} from '../../shared/constants/query';
import { useTranslation } from "react-i18next";

const {Option} = AutoComplete;

const WorkItemSelector = ({
    onWorkItemSelectHandler = null, 
    onWorkItemClearHandler = null, 
    onWorkItemChangeHandler = null,
    onWorkItemResultsHandler = null
}) => {
    
    const {searchResults, alphabeticalSort, searchWorkItem} = useBenefits();
    const {t} = useTranslation();
    const [searchTimer, setSearchTimer] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [updatedResults, setUpdatedResults] = useState(false);

    const searchResultsOrdered = useMemo(()=>{
        if(searchResults === null){
            return [];
        } else {
            return searchResults?.content.sort(alphabeticalSort) ?? [];
        }
    },[searchResults, alphabeticalSort]);

    const searchOptions = useMemo(()=>{
        if(searchResultsOrdered === null){
            return [];
        } else {
            return searchResultsOrdered.map(item => <Option key={item.benefitId} value={item.benefitId}>{item.description}</Option>)
        }
    },[searchResultsOrdered]);

    useEffect(()=> {
        if(updatedResults) {
            if(onWorkItemResultsHandler !== null) {
                const results = searchResults !== null ? {
                    content: searchResultsOrdered,
                    pagination: {... searchResults.pagination ?? null}
                } : null;
                onWorkItemResultsHandler(results);
            } 
            setUpdatedResults(false); 
        }
    },[searchResults, searchResultsOrdered, onWorkItemResultsHandler])

    const onWorkItemChange = (text) => {
        const wasSelected = searchResultsOrdered.some(item => item.benefitId === text);
        const wasEmpty = text && text.length < 0 ? true : false;
        if(!wasSelected || wasEmpty) {
            setSearchValue(text);
            clearTimeout(searchTimer);
            const timer = setTimeout(() => {
                searchWorkItem(text, 1, DEFAULT_PAGE_SIZE, ()=> {
                    setUpdatedResults(true);
                })
            }, 500);
            setSearchTimer(timer);    
            if(onWorkItemChangeHandler !== null){
                onWorkItemChangeHandler(text);
            };    
        }
    };

    const onWorkItemSelect = (value) => {
        const result = searchResultsOrdered.find(item => item.benefitId === value);
        if(result){
            setSearchValue(result.description);
            if(onWorkItemSelectHandler !== null){
                onWorkItemSelectHandler(result);
            };
        }else{
            defaultWorkItemClear(); 
        }
    }

    const defaultWorkItemClear = () => {
        setSearchValue(null);
    }

    const onWorkItemClear = () => {
        defaultWorkItemClear(); 
        if(onWorkItemClearHandler !== null){
            onWorkItemClearHandler();
        }
    }

    return (
        <AutoComplete
            value={searchValue}
            placeholder={t('benefitsExampleLbl')}
            onChange={(text) => onWorkItemChange(text)} 
            allowClear={{
                clearIcon: <CloseCircleFilled />,
            }}
            className='fullWidth'
            onSelect={(value) => onWorkItemSelect(value)}
            onClear={() => onWorkItemClear()}
        >
            {searchOptions}    
        </AutoComplete>
    )
}

export default WorkItemSelector;