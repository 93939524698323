import { PublicClientApplication } from "@azure/msal-browser";
import { findBoostITAccount } from "../../utils/findBoostITAccount";
import { cacheToken } from "./App";

const TENANT_ID = `${process.env.REACT_APP_TENANT_ID}`;
const CLIENT_ID = `${process.env.REACT_APP_CLIENT_ID}`;
const APP_SCOPE = `${process.env.REACT_APP_AUTH_SCOPE}`; 

const msalConfig = {
  auth: {
    clientId: CLIENT_ID,
    authority: `https://login.microsoftonline.com/${TENANT_ID}`,
    redirectUri: "/",
  },
};

const loginRequest = {
  scopes: [`${process.env.REACT_APP_AUTH_SCOPE}`],
};

const msalInstance = new PublicClientApplication(msalConfig);

await msalInstance.initialize();

const tryFetchToken = (account) => {
  if (account) {
    msalInstance
      .acquireTokenSilent({
        scopes: [APP_SCOPE],
        account: account,
    })
    .then((response) => {
      const {accessToken} = response;
      console.log("API responseHandler: tryFetchToken -> success. Token saved", `${accessToken.substring(0,5)}...`);
      cacheToken(accessToken);
    })
    .catch((error) => {
      console.log("API responseHandler: tryFetchToken -> error. Token not fetched.", error);
      msalInstance.loginRedirect(loginRequest);
    });
  }
}

const responseHandler = (response) => {
  console.log('responseHandler was called...');
  if (response !== null) {
    console.log("API responseHandler -> Login redirect success with account.");    
    cacheToken(response.accessToken);
  } else {
    const currentAccounts = msalInstance.getAllAccounts();    
    console.log("API responseHandler -> Picking Boost id account.", currentAccounts);
    if (currentAccounts.length === 0) {
      console.log("API responseHandler -> No accounts found. Redirecting to MSAL login.");
      msalInstance.loginRedirect(loginRequest);
    } else if (currentAccounts.length > 0) {
      const account = findBoostITAccount(currentAccounts);
      tryFetchToken(account);
    }
  }
}

msalInstance.handleRedirectPromise().then(responseHandler);

export { msalInstance, loginRequest, tryFetchToken};