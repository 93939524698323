import { Navigate } from 'react-router-dom';
import useUser from "../../shared/hooks/useUser";
import { useEffect } from 'react';

const ProtectedRoute = ({permission = '', redirectPath = '/', children}) => {

  const { hasAccess, finishLoading } = useUser();
  
  useEffect(()=> {
    if(finishLoading && !hasAccess(permission)) {
      return <Navigate to={redirectPath} replace="true" />;
    }
  },[finishLoading, hasAccess]);
  
  return children;
}

export default ProtectedRoute;