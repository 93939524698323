import { useState, useCallback } from "react";
import useAppClient from "./useAppClient";
import { BenefitState, Benefit, BenefitsApiRoot, UseBenefitsType, BenefitsState, BenefitFormState, BenefitFormDefaultsValue, BenefitFormItemType, BenefitAPI, BenefitsMap } from "./useBenefits.types";
import { benefitsMap } from "../constants/benefits";
import { getValue } from "../../utils/getValue";
import { pickFirstNotNull } from "../../utils/pickFirstNotNull";
import { evalFirstNotNull } from "../../utils/evalFirstNotNull";
import { workItemsMap } from "../constants/work-items";
import { BENEFIT_GROUPS } from "../constants/benefit-groups";
import { useTranslation } from "react-i18next";
import { commissionsMap } from "../constants/commissions";
import { AxiosResponse } from "axios";

const useBenefits = ():UseBenefitsType => {
    const {t} = useTranslation()
    const apiInstance = useAppClient(true);
    const [formBenefit, setFormBenefit] = useState<BenefitFormState>(null);
    const [benefits, setBenefits] = useState<BenefitsState>(null);
    const [benefit, setBenefit] = useState<BenefitState>(null);
    const [searchResults, setSearchResults] = useState<BenefitsState>(null);

    const defaultBenefits:BenefitAPI = {sortOrder: 0, isRepeat:false};

    const defaultWorkMeans:BenefitAPI = {
        alerts: false,
        grouped: BENEFIT_GROUPS.MEANS_OF_WORK,
        isEditable: true,
        isRequired: false,
        requiresApproval: false,
        socialSecurity: false,
        socialSecurityCompany: 0,
        socialSecurityEmployee: 0,
        isDaily: false,
    }

    const defaultCommissions:BenefitAPI = {
        alerts: false,
        grouped: BENEFIT_GROUPS.COMMISSION,
        isEditable: true,
        isRequired: false,
        requiresApproval: false,
        socialSecurity: false,
        socialSecurityCompany: 0,
        socialSecurityEmployee: 0,
        isDaily: false,
    }

    const defaultOrder = (current:Benefit, next:Benefit) => {
        const currentSort = Number(current.sortOrder);
        const nextSort = Number(next.sortOrder)
         
        if (currentSort < nextSort) {
            return -1;
        } else if (currentSort === nextSort) {
            return 0;
        } else {
            return 1;
        }
    };

    const alphabeticalSort = (current:Benefit, next:Benefit) => {
        const currentSort = current.description;
        const nextSort = next.description;

        if (currentSort < nextSort) {
            return -1;
          }
          if (currentSort > nextSort) {
            return 1;
          }
          return 0;
    };

    const fetch = useCallback((url:string, successHandler?: ()=> void, errorHandler?: (error:Error) => void) => {
        apiInstance
        .get<BenefitsApiRoot | Benefit>(url)
        .then((response) => {
            const data = response?.data;
            if (data){
                if ('content' in data) {
                    const ordered = data.content.sort(defaultOrder);
                    setBenefits({
                        ... data,
                        content: ordered,
                    });
                } else {
                    setBenefit(data);
                }

                if(successHandler !== undefined){
                    successHandler();
                }
            }
        })
        .catch((error) => {
            if(errorHandler !== undefined){
                errorHandler(error);
            }            
        });
    },[setBenefits, defaultOrder]);

    const fetchBenefits = (page = 1, size = 10, successHandler?: ()=> void, errorHandler?: (error:Error) => void) => fetch(`/hr-simulation/benefits?type=BaseSalary&type=ExtraSalary&page=${
        page
      }&size=${size}`, successHandler, errorHandler)
    
    const fetchBenefit = (benefitId:string, successHandler?: ()=> void, errorHandler?: (error: Error) => void) => fetch(`/hr-simulation/benefits/${benefitId}`, successHandler, errorHandler)

    const fetchWorkItems = (page = 1, size = 10, successHandler?: ()=> void, errorHandler?: (error:Error) => void) => fetch(`/hr-simulation/benefits?type=MeansOfWork&page=${
        page
      }&size=${size}`, successHandler, errorHandler)

      const fetchCommissions = (page = 1, size = 10, successHandler?: ()=> void, errorHandler?: (error:Error) => void) => fetch(`/hr-simulation/benefits?type=Commission&page=${
        page
      }&size=${size}`, successHandler, errorHandler)

    const createBenefit = (data: BenefitAPI, successHandler?: (response: AxiosResponse)=> void, errorHandler?: (error: Error) => void) => {
        apiInstance.post('/hr-simulation/benefits', {
            ...data
        })
        .then((response) => {
            if (response === undefined && errorHandler !== undefined){
                errorHandler(new Error(t('benefitValidateFail')));
            } else if(successHandler !== undefined){
                successHandler(response);
            }
        })
        .catch((error) => {
            if(errorHandler !== undefined){
                errorHandler(error);
            }
        });
    }

    const updateBenefit = (data: BenefitAPI, successHandler?: (response: AxiosResponse)=> void, errorHandler?: (error:Error) => void) => {
        apiInstance.put(`/hr-simulation/benefits/${data.benefitId}`, {
            ...data
        })
        .then((response) => {
            if (response === undefined && errorHandler !== undefined){
                errorHandler(new Error(t('benefitValidateFail')));
            } else if(successHandler !== undefined){
                successHandler(response);
            }
        })
        .catch((error) => {
            if(errorHandler !== undefined){
                errorHandler(error);
            }
        });
    }

    const deleteBenefit = (data: BenefitAPI, successHandler?: ()=> void, errorHandler?: (error:Error) => void) => {
        apiInstance.delete(`/hr-simulation/benefits/${data.benefitId}`)
        .then(() => {
            if(successHandler !== undefined){
                successHandler();
            }
        })
        .catch((error) => {
            if(errorHandler !== undefined){
                errorHandler(error);
            }
        });
    }

    const search = useCallback((url:string, successHandler?: ()=> void, errorHandler?: (error:Error) => void) => {
        if(url.length < 1) {
            setSearchResults(null);
        } else {
            apiInstance
            .get<BenefitsApiRoot>(url)    
            .then((response) => {
                const data = response?.data;
                if (data){
                    const ordered = data.content.sort(defaultOrder);
                    setSearchResults({
                        ... data,
                        content: ordered,
                    });
                    if(successHandler !== undefined){
                        successHandler();
                    }
                }
            })
            .catch((error) => {
                if(errorHandler !== undefined){
                    errorHandler(error);
                }
            });    
        }
    },[setSearchResults])


    const searchBenefit = (descriptionTerm:string, page = 1, size = 9999, successHandler?: ()=> void, errorHandler?: (error:Error) => void) => search(descriptionTerm && descriptionTerm.length > 0? `/hr-simulation/benefits?type=ExtraSalary&type=BaseSalary&page=${
        page
      }&size=${size}&search=${descriptionTerm}` : '', successHandler, errorHandler)

    const searchWorkItem =  (descriptionTerm:string, page = 1, size = 9999, successHandler?: ()=> void, errorHandler?: (error:Error) => void) => search(descriptionTerm && descriptionTerm.length > 0? `/hr-simulation/benefits?type=MeansOfWork&page=${
        page
    }&size=${size}&search=${descriptionTerm}` : '', successHandler, errorHandler)

    const searchCommissions =  (descriptionTerm:string, page = 1, size = 9999, successHandler?: ()=> void, errorHandler?: (error:Error) => void) => search(descriptionTerm && descriptionTerm.length > 0? `/hr-simulation/benefits?type=Commission&page=${
        page
    }&size=${size}&search=${descriptionTerm}` : '', successHandler, errorHandler)

    const convertFormToAPI = (formData:BenefitFormState, convertMap:BenefitsMap, defaults:BenefitAPI): BenefitAPI => {
        const data:BenefitAPI = {};

        if(formData){
            convertMap.forEach( item => {             
                const formValue = formData[item.formField] === undefined ? null : formData[item.formField];

                if(
                    Array.isArray(item.apiField) && 
                    item.output.type === BenefitFormItemType.DATE_RANGE && 
                    Array.isArray(formValue)
                ) {
                    item.apiField.forEach((element, index) => {
                        data[element] = formValue[index];
                    });
                } else if(Array.isArray(item.apiField) && item.output.type === BenefitFormItemType.NUMBER && item.output.refField !== undefined){
                    const refField = convertMap.find(benefit => benefit.formField === item.output.refField) ?? null;
                    const refValue = formData[item.output.refField] ?? null;

                    const apiTargetFieldIndex = refField && refField.output.valueCheck?.true === refValue ? 1 : 0;
                    const apiOppositeFieldIndex = Number(!Boolean(apiTargetFieldIndex));
                    data[item.apiField[apiTargetFieldIndex]] = Number(formValue);
                    data[item.apiField[apiOppositeFieldIndex]] = null;

                } else if(Array.isArray(item.apiField) === false) {
                    let convertedValue = formValue; 

                    if(item.output.type === BenefitFormItemType.NUMBER ) {
                        convertedValue = formValue === null ? null : Number(formValue);
                    } else if (item.output.type === BenefitFormItemType.BOOL) {
                        convertedValue = Boolean(formValue === null ? false : formValue);
                    } else if (item.output.type === BenefitFormItemType.STRING && item.output.valueCheck === undefined) {
                        convertedValue = formValue === null ? null : String(formValue);
                    } else if (item.output.type === BenefitFormItemType.STRING && item.output.valueCheck !== undefined) {
                        convertedValue = formValue === item.output.valueCheck?.true ? true : false;
                    }

                    data[item.apiField] = convertedValue;
                }
            });
        }
        return {...defaults, ...data};
    }

    const convertFormToBenefitAPI = (formData:BenefitFormState): BenefitAPI => convertFormToAPI(formData, benefitsMap, defaultBenefits);

    const convertFormToWorkItemAPI = (formData:BenefitFormState): BenefitAPI => convertFormToAPI(formData, workItemsMap, {...defaultBenefits, ...defaultWorkMeans});

    const convertFormToCommissionsAPI = (formData:BenefitFormState): BenefitAPI => convertFormToAPI(formData, commissionsMap, {...defaultBenefits, ...defaultCommissions});

    const computeForm = (benefit:Benefit | null, initial:BenefitFormDefaultsValue, convertMap:BenefitsMap, defaults:BenefitAPI) => {
        if(benefit === null) {
            setFormBenefit(initial.defaults);
        } else {
            const data: BenefitFormState = {}; 

            convertMap.forEach( item => { 
                if(Array.isArray(item.apiField) && item.output.type === BenefitFormItemType.DATE_RANGE){
                    data[item.formField] = item.apiField.map(key => getValue(benefit, key, item.output.type));
                } else if(Array.isArray(item.apiField) && item.output.type === BenefitFormItemType.NUMBER){
                    data[item.formField] = pickFirstNotNull(benefit, item.apiField);
                } else if(Array.isArray(item.apiField) && item.output.type === BenefitFormItemType.STRING){
                    data[item.formField] = evalFirstNotNull(benefit, item.apiField, item.output.valueCheck);     
                } else if(!Array.isArray(item.apiField) && item.output.valueCheck !== undefined){    
                    data[item.formField] = evalFirstNotNull(benefit, item.apiField, item.output.valueCheck);
                } else if(!Array.isArray(item.apiField)){    
                    data[item.formField] = getValue(benefit, item.apiField, item.output.type);
                }
            });
            setFormBenefit({...defaults, ...data});
        }
    }

    const computeFormBenefit = (benefit:Benefit | null, initial:BenefitFormDefaultsValue) => computeForm(benefit, initial, benefitsMap, defaultBenefits)

    const computeFormWorkItem = (benefit:Benefit | null, initial:BenefitFormDefaultsValue) => computeForm(benefit, initial, workItemsMap, {...defaultBenefits, ...defaultWorkMeans})

    const computeFormCommissions = (benefit:Benefit | null, initial:BenefitFormDefaultsValue) => computeForm(benefit, initial, commissionsMap, {...defaultBenefits, ...defaultCommissions})

    return {
        searchResults,
        benefits,
        benefit,
        formBenefit,
        fetchBenefits,
        fetchBenefit,
        fetchWorkItems,
        fetchCommissions,
        computeFormBenefit,
        convertFormToBenefitAPI,
        computeFormWorkItem,
        computeFormCommissions,
        convertFormToWorkItemAPI,
        convertFormToCommissionsAPI,
        createBenefit,
        updateBenefit,
        deleteBenefit,
        searchBenefit,
        searchWorkItem,
        searchCommissions,
        defaultOrder,
        alphabeticalSort
    }
}

export default useBenefits;