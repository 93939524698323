import { Form, InputNumber, DatePicker } from "antd";
import { useTranslation } from "react-i18next";

const BenefitForm = () =>{
    const { t } = useTranslation();
    return (
        <>
        <Form.Item
          name="value"
          label={t("newValue")}
          rules={[{ required: true, message: t("enterNumberMessage") }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="date"
          label={t("validSince")}
          rules={[{ required: true, message: t("validDateMessage") }]}
        >
          <DatePicker picker="month" placeholder={t("selectMonth")} />
        </Form.Item>
      </>
    )
}

export default BenefitForm;