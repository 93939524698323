import { Form, Input, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import { licensePlateRegEx } from "../../../utils/licensePlate";

const LicensePlatesForm = () =>{
    const { t } = useTranslation();
    return (
      <>
        <Form.Item
          name="value"
          label={t("newValue")}
          rules={[
            { required: true, message: t("enterValidLicensePlate") },
            {
              pattern: licensePlateRegEx,
              message: t("enterValidLicensePlate"),
            },
          ]}
        >
          <Input onInput={e => (e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.toUpperCase()} />
        </Form.Item><Form.Item
          name="date"
          label={t("validSince")}
          rules={[{ required: true, message: t("validDateMessage") }]}
        >
          <DatePicker picker="month" placeholder={t("selectMonth")} />
        </Form.Item>
      </>
    )
}

export default LicensePlatesForm;