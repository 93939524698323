import {useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
    SearchOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
} from "@ant-design/icons";

import {Space, Row, Col, Typography, Button, Card, Table, Divider, Pagination, notification} from 'antd';
const { Title } = Typography;

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../../shared/constants/query";

import WorkItemSelector from '../work-items-selector';

import DeleteModal from '../delete-modal';
import useBenefits from "../../shared/hooks/useBenefits";
import useScrollToElement from '../../shared/hooks/useScrollToElement';
import StatusBadge from '../status-badge';
import EditWorkItems from './EditWorkItems';

const WorkItems = () => {
    const {searchResults, benefits, fetchWorkItems, deleteBenefit, searchWorkItem, alphabeticalSort} = useBenefits();
    const {t} = useTranslation();
    const { scrollToElement } = useScrollToElement();
    const [current, setCurrent] = useState(1);
    const [fetchingWorkItems, setFetchingWorkItems] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showCreateNew, setShowCreateNew] = useState(false);
    const [deleteModal, setDeleteModal] = useState({shouldOpen: false, data:{}});
    const [selectorResults, setSelectorResults] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);

    const renderWorkItems = useMemo(() => {        
        if(benefits && selectorResults === null) {
            return {
                content: benefits.content?.sort(alphabeticalSort), 
                pagination: {... benefits.pagination}
            };
        }
        if(selectorResults !== null && searchResults === null){
            return selectorResults;
        }
        if(selectorResults !== null && searchResults !== null){
            return searchResults;
        }
    },[selectorResults, benefits, searchResults])

    const fetchWorkItemPage = (page) => {
        setFetchingWorkItems(true);
        fetchWorkItems(page, DEFAULT_PAGE_SIZE, () => {
            setFetchingWorkItems(false);
        });
    }

    const fetchWorkItemSearchPage = (page) => {
        setFetchingWorkItems(true);
        searchWorkItem(searchTerm, page, DEFAULT_PAGE_SIZE, () => {
            setFetchingWorkItems(false);
        });
    }

    const doFirstRender = () => {
        fetchWorkItemPage(DEFAULT_PAGE);
    }

    useEffect(()=> {
        if(benefits === null){
            doFirstRender();
        }
    },[benefits, doFirstRender]);

    const onWorkItemChangeHandler = (text) => {
        if (text !== undefined && text.length === 0) {
            onWorkItemClearHandler();
        } else {
            setSearchTerm(text);
        }
    }

    const onWorkItemResultsHandler = (results) => {
        setSelectorResults(results);
    }

    const callAppropriateFetch = (page = DEFAULT_PAGE) => {
        if(searchTerm !== null) {
            fetchWorkItemSearchPage(page);
        } else {
            fetchWorkItemPage(page);
        }
    }

    const onPaginationChange = (page) => {        
        setCurrent(page);
        callAppropriateFetch(page);
        setFetchingWorkItems(true);
    }

    const onCreateNew = () => {
        setShowCreateNew(true);
    }

    const onWorkItemSelectHandler = (workItem) => {
        setSelectedItem(workItem)
    }

    const onWorkItemClearHandler = () => {
        setSelectorResults(null);
        setSelectedItem(null);
        setSearchTerm(null);
    }

    const onEdit = (record) => {
        setSelectedItem(record);
        setTimeout(() => {
            scrollToElement(document.getElementById('edit-work-items'));        
        }, 500);
    }

    const onDeleteOkHandler = () => {
        deleteBenefit(
            deleteModal.data.record,
            () => {
                setSelectedItem(null);
                callAppropriateFetch(current);
                setDeleteModal({shouldOpen: false, data:{}})
                notification.success({
                    message: t('workItemDeletedSuccess'),
                    placement: "bottomRight",
                });                    
            },
            (error) => {
                setDeleteModal({shouldOpen: false, data:{}})
                notification.error({
                    message: t('workItemDeletedFail'),
                    description: error.message,
                    placement: "bottomRight",
                });
            }       
        )    
    }
    
    const onDeleteCancelHandler = () => {
        setDeleteModal({shouldOpen: false, data:{}});
        setSelectorResults(null);
    }

    const onDelete = (record) => {
        setDeleteModal({shouldOpen: true, data:{
            record,
            content: t('workItemsDeleteConfirmation', {label: record.description})
        }})
    }

    const onSuccessHandler = (refetch = false) => {
        if(refetch){
            callAppropriateFetch(current);
        }
    }
    
    const onCancelHandler = (refetch = false) => {
        setSelectedItem(null);
        setShowCreateNew(false);
        if(refetch){
            callAppropriateFetch(current);
        }
    }

    const columns = [
        {
            title: t("workItemsStatusLabel"),
            dataIndex: "status",
            width: '20%',
            key: "status",
            render: (_, record) => <StatusBadge status={record.status} />,
        },
        {
            title: t("workItemsDescriptionLabel"),
            dataIndex: "description",
            key: "description",
            width: '70%',
            render: (_, record) => record.description,
        },
        {
            title: t("actionLabel"),
            key: "action",
            width: 90,
            align: "center",
            render: (_, record) => (
              <Space size="middle">
                <EditOutlined className='IconTable' onClick={() => onEdit(record)}/>
                <DeleteOutlined className='IconTable' onClick={() => onDelete(record)}/>
              </Space>
            ),
        }
    ];

    return (
        <>
             <Row justify={'center'}>
                <Col span={24}>
                    <Title level={1} className="pageTitle">
                    { t('workItemsLbl')} 
                    </Title>
                </Col>
            </Row>
            <Row justify={'center'} className="paddingBottom2Rem paddingTop1Rem">
                <Col sm={24} md={15}>
                    <Row>
                        <Col sm={24} md={24}>
                            {showCreateNew ? (
                            <Row>
                                <Col sm={24} md={24}>
                                    <Title level={3} className="marginZero">{t("workItemsCreateNewLbl")}</Title>
                                </Col>
                            </Row>
                            ) : (
                                <Row>
                                    <Col sm={24} md={10}>
                                        <Title level={3} className="marginZero">{selectedItem ? t("workItemsEditLbl") : t("workItemsConsultLbl")}</Title>
                                    </Col>
                                    <Col sm={24} md={14} className="alignRight">
                                        <Button type="primary" icon={<PlusOutlined />} onClick={onCreateNew}>{t('workItemsCreateNewLbl')}</Button>
                                    </Col>
                                </Row>
                            )}
                            <Divider className="marginBottom1Rem marginTop05Rem" />                                
                        </Col>
                    </Row>
                    {!showCreateNew && (
                        <Row className="paddingTop1Rem">
                            <Col sm={24} md={24}>
                                <Card
                                    title={
                                        <Row justify="center">
                                            <Col sm={24} md={12}>
                                                <b>{t('workItemsListLbl')}</b>
                                            </Col>
                                            <Col sm={24} md={10}>
                                                <WorkItemSelector 
                                                    onWorkItemClearHandler={onWorkItemClearHandler}
                                                    onWorkItemSelectHandler={onWorkItemSelectHandler}
                                                    onWorkItemResultsHandler={onWorkItemResultsHandler}
                                                    onWorkItemChangeHandler={onWorkItemChangeHandler}
                                                />
                                            </Col>       
                                            <Col sm={24} md={2} className='alignRight'>
                                                <Button type='primary'><SearchOutlined /></Button>
                                            </Col>       
                                        </Row>
                                    }
                                >
                                <Table
                                    loading={fetchingWorkItems}
                                    size="middle"
                                    columns={columns}
                                    dataSource={renderWorkItems?.content}
                                    pagination={false}
                                    tableLayout="fixed"
                                    rowKey="benefitId"
                                    onRow={(record, _) => ({
                                        className: selectedItem && record.benefitId === selectedItem.benefitId ? 'selected-row' : null
                                      })
                                    }
                                />
                                {renderWorkItems && renderWorkItems?.pagination.totalPages > 1 && 
                                    (
                                        <Pagination
                                            onChange={onPaginationChange}
                                            current={current}
                                            total={renderWorkItems.pagination.totalElements}
                                            showSizeChanger={false}
                                        />
                                    )
                                }
                                </Card>
                            </Col>
                    </Row>
                    )}
                    <div id='edit-work-items'>
                        {
                            (selectedItem || showCreateNew) && (
                                <>
                                    <EditWorkItems 
                                        selectedWorkItem={selectedItem} 
                                        createNew={showCreateNew} 
                                        onCancelHandler={onCancelHandler}
                                        onSuccessHandler={onSuccessHandler}
                                    />
                                </>
                            )
                        }
                    </div>
                    { deleteModal && deleteModal.shouldOpen && (
                        <DeleteModal
                            shouldOpen={deleteModal.shouldOpen}
                            data={deleteModal.data}
                            onOkHandler={onDeleteOkHandler}
                            onCancelHandler={onDeleteCancelHandler}
                        />
                        )
                    }
                </Col>
            </Row>
        </>
    )
}

export default WorkItems;