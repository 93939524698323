import {useEffect, useState, useMemo} from 'react';
import { useTranslation } from 'react-i18next';

import EditGroupOrBenefit from './EditGroupOrBenefit';
import EditGroupBenefits from '../benefit-group-editor';

import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    SearchOutlined,
} from "@ant-design/icons";

import {Space, Row, Col, Typography, Button, Card, Table, Divider, Alert, Pagination, notification} from 'antd';

const { Title } = Typography;

import useBenefitGroups from '../../shared/hooks/useBenefitGroups';

import GroupSelector from '../benefit-group-selector';

import DeleteModal from '../delete-modal';

const statusModel = {success: false, visible: false, message: '', description: ''};

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from "../../shared/constants/query";

const EDIT_MODE = {addGroup: false, isEdit: false, shouldShow: false, selectedId: null};

const BenefitGroups = () => {
    const {searchResults, groups, fetch:fetchGroups, fetchAll:fetchAllGroups, deleteGroup, searchGroup, alphabeticalSort } = useBenefitGroups();

    const {t} = useTranslation();
    const [current, setCurrent] = useState(1);
    const [fetchingGroups, setFetchingGroups] = useState(false);
    const [deleteModal, setDeleteModal] = useState({shouldOpen: false, data:{}});
    const [selectorResults, setSelectorResults] = useState(null);

    const [editMode, setEditMode] = useState(EDIT_MODE);
    const [status, setStatus] = useState(statusModel);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);

    const shouldUnselectGroup = useMemo(()=> {
        const {selectedId} = editMode;
        return selectedGroupId !== null && selectedId !== null && selectedGroupId === selectedId;
    },[
        editMode.selectedId,
        selectedGroupId
    ]);

    const renderGroups = useMemo(() => {
        if(groups && selectorResults === null) {
            return {
                content: groups.content?.sort(alphabeticalSort), 
                pagination: {... groups.pagination}
            };
        }
        if(selectorResults !== null && searchResults === null){
            return selectorResults;
        }
        if(selectorResults !== null && searchResults !== null){
            return searchResults;
        }
    },[selectorResults, groups, searchResults])

    const fetchGroupPage = (page) => {
        setFetchingGroups(true);
        fetchGroups(page, DEFAULT_PAGE_SIZE ,()=>{
            setFetchingGroups(false);
        });
    }

    const fetchGroupSearchPage = (page) => {
        setFetchingGroups(true);
        searchGroup(searchTerm, page, DEFAULT_PAGE_SIZE, () => {
            setFetchingGroups(false);
        });
    }
    
    const doFirstRender = () => {
        fetchGroupPage(DEFAULT_PAGE);
    }

    useEffect(()=>{
        if(groups === null){
            doFirstRender()
        }
    },[groups, doFirstRender]);

    const onCreateNew = () => {
        setEditMode(prev => ({...prev, addGroup: true, isEdit: false, shouldShow: true, selectedId: null}));
    }

    const onGroupChangeHandler = (text) => {
        if (text !== undefined && text.length === 0) {
            onGroupClearHandler();
        } else {
            setSearchTerm(text);
        }
    }

    const onGroupResultsHandler = (results) => {
        setSelectorResults(results);
    }

    const onGroupSelectHandler = (group) => {
        setSelectedGroupId(group.benefitGroupId); 
    }

    const onGroupClearHandler = () => {
        setSelectorResults(null);
        setSelectedGroupId(null); 
        setSearchTerm(null); 
    }

    const onAdd = (record) => {
        setSelectedGroupId(null);
        setEditMode(prev => ({...prev, addGroup: false, isEdit: false, shouldShow: true, selectedId: record.benefitGroupId}));
    }

    const onEdit = (record) => {
        setSelectedGroupId(null);
        setEditMode(prev => ({...prev, addGroup: false, isEdit: true, shouldShow: true, selectedId: record.benefitGroupId}));
    }

    const onDeleteOkHandler = () => {
        deleteGroup(
            deleteModal.data.record.benefitGroupId, 
            ()=> {
                setEditMode(prev => ({...prev, ...EDIT_MODE}));
                fetchGroups(current);
                if(selectedGroupId && deleteModal.data.record.benefitGroupId === selectedGroupId){
                    setSelectedGroupId(null);
                }
                setDeleteModal({shouldOpen: false, data:{}});
                notification.success({
                    message: t('benefitGroupsEditDeleteGroupNameSuccess'),
                    placement: "bottomRight",
                });       
            },(error) => {
                setDeleteModal({shouldOpen: false, data:{}})
                notification.error({
                    message: error.message ?? '',
                    placement: "bottomRight",
                });
            }
        );
    }

    const onDeleteCancelHandler = () => {
        setDeleteModal({shouldOpen: false, data:{}})
        setSelectorResults(null);
    }

    const onDelete = (record) => {
        setDeleteModal({shouldOpen: true, data:{
            record,
            content: t('benefitGroupsDeleteConfirmation', {label: record.description})
        }})
    }

    const callAppropriateFetch = (page = DEFAULT_PAGE) => {
        if(searchTerm !== null){ 
            fetchGroupSearchPage(page);
        } else {
            fetchGroupPage(page);
        }
    }

    const onPaginationChange = (page) => {
        setCurrent(page);
        callAppropriateFetch(page);
        setFetchingGroups(true);
    }

    const editModeCloseHandler = () => {
        setEditMode(prev => ({...prev, shouldShow: false}));
    }

    const onAlertClose = () => {
        setStatus(statusModel);    
    }

    const columns = [
        {
            title: t("benefitGroupsTableNameCol"),
            dataIndex: "description",
            width: 350,
            key: "description",
            align: "left",
            render: (_, record) => record.description,
            onCell: (record, _) => {
                return {
                    onClick: (_) => {
                        setSelectedGroupId(record.benefitGroupId);
                    },
                };
            }
        },
        {
            title: t("actionLabel"),
            key: "action",
            width: 80,
            align: "center",
            render: (_, record) => (
              <Space size="middle">
                <PlusOutlined className='IconTable' onClick={() => onAdd(record)}/>
                <EditOutlined className='IconTable' onClick={() => onEdit(record)}/>
                <DeleteOutlined className='IconTable' onClick={() => onDelete(record)}/>
              </Space>
            ),
        }
    ];

    const createGroupHandler = () => {
        fetchAllGroups();
    }

    const updateGroupHandler = () => {
        if(shouldUnselectGroup) {
            setSelectedGroupId(null);
        }
        callAppropriateFetch(current);
    }

    const addBenefitHandler = () => {
        if (shouldUnselectGroup) {
            setSelectedGroupId(null);
        }
        callAppropriateFetch(current);
    }

    return (
        <>
            <Row justify={'center'}>
                <Col span={24}>
                    <Title level={1} className="pageTitle">
                    { t('benefitGroups')} 
                    </Title>
                </Col>
            </Row>
            <Row justify={'center'} className="paddingBottom2Rem paddingTop1Rem">
                <Col sm={24} md={15}>
                    <Row>
                        <Col sm={24} md={24}>
                            <Row>
                                <Col sm={24} md={10}>
                                    <Title level={3} className="marginZero">{t("benefitGroupsConsultLbl")}</Title>
                                </Col>
                                    <Col sm={24} md={14} className="alignRight">
                                    <Button type="primary" icon={<PlusOutlined />} onClick={onCreateNew}>{t('benefitGroupsCreateNewLbl')}</Button>
                                </Col>
                            </Row>
                            <Divider className="marginBottom1Rem marginTop05Rem" />                                
                        </Col>
                    </Row>

                    <Row className="paddingTop1Rem">
                        <Col sm={24} md={24}>
                            <Card
                                title={
                                    <Row justify="center">
                                        <Col sm={24} md={12}>
                                            <b>{t('benefitGroupsListLbl')}</b>
                                        </Col>
                                        <Col sm={24} md={10}>
                                            <GroupSelector 
                                                onGroupSelectHandler={onGroupSelectHandler}
                                                onGroupClearHandler={onGroupClearHandler}
                                                onGroupResultsHandler={onGroupResultsHandler}
                                                onGroupChangeHandler={onGroupChangeHandler}
                                            />
                                        </Col>
                                        <Col sm={24} md={2} className='alignRight'>
                                            <Button type='primary'><SearchOutlined /></Button>
                                        </Col>       
                                    </Row>
                                }
                            >
                                <Table
                                    loading={fetchingGroups}
                                    size="middle"
                                    columns={columns}
                                    dataSource={renderGroups?.content}
                                    pagination={false}
                                    tableLayout="fixed"
                                    rowKey="benefitGroupId"
                                    onRow={(record, _) => ({
                                        className: selectedGroupId && record.benefitGroupId === selectedGroupId ? 'selected-row' : null
                                      })
                                    }
                                />
                                {renderGroups && renderGroups?.pagination.totalPages > 1 && 
                                    (
                                        <Pagination
                                            onChange={onPaginationChange}
                                            current={current}
                                            total={renderGroups.pagination.totalElements}
                                            showSizeChanger={false}
                                    />
                                    )
                                }
                            </Card>
                        </Col>
                    </Row>

                    {status && status.visible && (
                        <Row className="paddingTop1Rem">
                            <Col sm={24} md={24}>
                                <Alert 
                                    type={status.success? 'success' : 'error'}
                                    showIcon 
                                    closable
                                    message={status.message}
                                    description={status.description}
                                    onClose={() => onAlertClose()}
                                />                        
                            </Col>
                        </Row>
                    )}

                    {selectedGroupId && (
                        <Row className="paddingTop1Rem">
                            <Col sm={24} md={24}>             
                                <EditGroupBenefits 
                                    selectedGroupId={selectedGroupId}
                                />
                            </Col>
                        </Row>
                    )}

                </Col>
            </Row>

            <EditGroupOrBenefit 
                editMode={editMode} 
                editModeCloseHandler={editModeCloseHandler} 
                createGroupHandler={createGroupHandler}
                addBenefitHandler={addBenefitHandler}
                updateGroupHandler={updateGroupHandler}
            />

            { deleteModal && deleteModal.shouldOpen && (
                    <DeleteModal
                        shouldOpen={deleteModal.shouldOpen}
                        data={deleteModal.data}
                        onOkHandler={onDeleteOkHandler}
                        onCancelHandler={onDeleteCancelHandler}
                    />
                )
            }
        </>
    )
}

export default BenefitGroups;