
const useScrollToElement = () => {
    
    const scrollToElement = (nodeElement: HTMLElement) => {
        if (nodeElement !== null){
            nodeElement.scrollIntoView({ 
                behavior: 'smooth', 
                block: 'nearest', 
                inline: 'start' 
            });
        }
    }

    return {
        scrollToElement
    }
};

export default useScrollToElement;



