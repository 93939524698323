import { DatePicker, Form, Input, Tabs } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import Loading from "../../loading/Loading";
import { useEmployeeContext } from "../Employee.context";
import { useLicensePlates } from "../../../shared/hooks/useEmployeeLicensePlate";
import FieldWithHistory from "./FieldWithHistory";
import { IField } from "./FieldWithHistory/FiledWithHistory.interface";

import LicensePlatesForm from "./LicensePlatesForm";

const EmployeeOtherInfo = () => {
  const employee = useEmployeeContext();
  const {
    data: licensePlates,
    isLoading,
    createData: createLicensePlate,
    deleteData: deleteLicensePlate,
  } = useLicensePlates(employee.id);
  const { t } = useTranslation();

  if (isLoading) return <Loading />;

  const onCreateLicensePlate = (values: IField) => {
    createLicensePlate({
      ...values,
      plateId: values.value,
      validSince: values.date.format("YYYY-MM-01"),
    });
  };

  const onDeleteLicensePlate = (values: IField) => {
    deleteLicensePlate({
      ...values,
      plateId: values.value,
      validSince: values.date.format("YYYY-MM-DD"),
    });
  };

  const licensePlatesTab = {
    label: t("employee.licensePlate"),
    key: "licensePlate",
    children: (
      <FieldWithHistory
        data={licensePlates.map(({ plateId, isCurrent, validSince }) => ({
          value: plateId,
          isCurrent,
          date: dayjs(validSince),
        }))}
        modalDescription={t("employee.newLicensePlate")}
        onCreate={onCreateLicensePlate}
        onDelete={onDeleteLicensePlate}
        showActiveValue={true}
        valueFormComponent={
          <LicensePlatesForm />
        }
      />
    ),
  };
  return <Tabs tabPosition="left" items={[licensePlatesTab]} />;
};

export default EmployeeOtherInfo;
