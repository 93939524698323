import { useStoreState } from "easy-peasy";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import ProtectedRoute from "./protected-route";

import Welcome from "../../views/welcome";
import App from "../../views/app";
import Benefits from "../../views/benefits";
import BenefitGroups from "../../views/benefits-groups";
import Configurations from '../../views/configurations';
import Calculator from "../../views/calculator";
import Employees from "../../views/employees";
import Itineraries from "../../views/itineraries";
import Logout from "../../views/logout";
import NotFound from "../../views/notFound";
import Forbidden from "../../views/forbidden";
import PerDiem from "../../views/perDiem";
import PerDiems from "../../views/perDiems";
import TravelReport from "../../views/travelReport";
import TravelReports from "../../views/travelReports";
import WorkItems from "../../views/work-items";
import Commissions from "../../views/commissions";
import Employee from "../../views/employee";
import Proposals from '../../views/proposals';

import { MODULE_PERMISSIONS } from "../../shared/constants/module-permissions";

const Router = () => {
  const localeState = useStoreState((state) => state.userModel.locale);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to={`/${localeState}`} />} />
        <Route path="/:locale/logout/" element={<Logout />} />
        <Route path="/:locale/403/" element={<Forbidden />} />
        <Route path="/:locale/404/" element={<NotFound />} />
        
        <Route path="/:locale/" element={<App />}>
          <Route path="/:locale/" element={<Welcome />} />
          <Route path="/:locale/company/:companyId/" element={<Welcome />} />

          <Route
            path="/:locale/company/:companyId/employee/:employeeId/"
            element={<Employee />}
          />

          <Route
            path="/:locale/company/:companyId/configurations/"
            element={
              <ProtectedRoute redirectPath={`/${localeState}/403`} permission={MODULE_PERMISSIONS.BO_BENEFITS}>
                <Configurations />
              </ProtectedRoute>
            }
          />

          <Route
            path="/:locale/company/:companyId/configurations/benefits"
            element={
              <ProtectedRoute redirectPath={`/${localeState}/403`} permission={MODULE_PERMISSIONS.BO_BENEFITS}>
                <Benefits />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:locale/company/:companyId/configurations/benefits-group"
            element={
              <ProtectedRoute redirectPath={`/${localeState}/403`} permission={MODULE_PERMISSIONS.BO_BENEFITS}>
                <BenefitGroups />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:locale/company/:companyId/configurations/work-items"
            element={
              <ProtectedRoute redirectPath={`/${localeState}/403`} permission={MODULE_PERMISSIONS.BO_BENEFITS}>
                <WorkItems />
              </ProtectedRoute>
            }
          />

          <Route
            path="/:locale/company/:companyId/configurations/commissions"
            element={
              <ProtectedRoute redirectPath={`/${localeState}/403`} permission={MODULE_PERMISSIONS.BO_BENEFITS}>
                <Commissions />
              </ProtectedRoute>
            }
          />

          <Route path="/:locale/company/:companyId/calculator/" element={
            <ProtectedRoute redirectPath={`/${localeState}/403`} permission={MODULE_PERMISSIONS.SIMULATOR}>
              <Calculator />
            </ProtectedRoute>
          }/>
          
          <Route path="/:locale/company/:companyId/employees/" element={
            <ProtectedRoute redirectPath={`/${localeState}/403`} permission={MODULE_PERMISSIONS.EMPLOYEES}>
              <Employees />
            </ProtectedRoute>
          }/>
          
          <Route path="/:locale/company/:companyId/itineraries/" element={
            <ProtectedRoute redirectPath={`/${localeState}/403`} permission={MODULE_PERMISSIONS.EMPLOYEES}>
              <Itineraries />
             </ProtectedRoute>
          }/>

          <Route
            path="/:locale/company/:companyId/employee/:employeeId/travel-report/:travelReportId/"
            element={
              <ProtectedRoute redirectPath={`/${localeState}/403`} permission={MODULE_PERMISSIONS.TRAVEL_REPORTS}>
                <TravelReport />
              </ProtectedRoute>
            }
          />

          <Route
            path="/:locale/company/:companyId/employee/:employeeId/travel-reports/"
            element={
              <ProtectedRoute redirectPath={`/${localeState}/403`} permission={MODULE_PERMISSIONS.TRAVEL_REPORTS}>
                <TravelReports />
              </ProtectedRoute>
            }
          />
          
          <Route
            path="/:locale/company/:companyId/employee/:employeeId/per-diem/:reportType/:perDiemId/"
            element={
              <ProtectedRoute redirectPath={`/${localeState}/403`} permission={MODULE_PERMISSIONS.TRAVEL_REPORTS}>
                <PerDiem />
              </ProtectedRoute>
            }
          />

          <Route
            path="/:locale/company/:companyId/employee/:employeeId/per-diems/:reportType/"
            element={
              <ProtectedRoute redirectPath={`/${localeState}/403`} permission={MODULE_PERMISSIONS.TRAVEL_REPORTS}>
                <PerDiems />
              </ProtectedRoute>
            }
          />

          <Route
            path="/:locale/company/:companyId/employee/:employeeId/per-diems/:reportType/"
            element={
              <ProtectedRoute redirectPath={`/${localeState}/403`} permission={MODULE_PERMISSIONS.TRAVEL_REPORTS}>
                <PerDiems />
              </ProtectedRoute>
            }
          />

          <Route
            path="/:locale/proposals/"
            element={
              <ProtectedRoute redirectPath={`/${localeState}/403`} permission={MODULE_PERMISSIONS.PROPOSALS}>
                <Proposals />
              </ProtectedRoute>
            }
          />

          <Route
            path="/:locale/company/:companyId/proposals/"
            element={
              <ProtectedRoute redirectPath={`/${localeState}/403`} permission={MODULE_PERMISSIONS.PROPOSALS}>
                <Proposals />
              </ProtectedRoute>
            }
          />

          <Route
            path="/:locale/company/:companyId/proposals/:proposalId"
            element={
              <ProtectedRoute redirectPath={`/${localeState}/403`} permission={MODULE_PERMISSIONS.PROPOSALS}>
                <Proposals />
              </ProtectedRoute>
            }
          />   
       
        </Route>
        <Route path="*" element={<Navigate to={`/${localeState}/404/`} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
