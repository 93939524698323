import {
    DownCircleOutlined,
    ExclamationCircleOutlined,
    PlusCircleFilled,
    EditOutlined, 
    DeleteOutlined, 
    DownloadOutlined
  } from "@ant-design/icons";
  import {
    Button,
    Col,
    DatePicker,
    Form,
    InputNumber,
    Modal,
    Pagination,
    Row,
    Space,
    Table,
    Typography,
    Tooltip,
  } from "antd";
  import dayjs from "dayjs";
  import { useStoreState } from "easy-peasy";
  import moment from "moment";
  import React from "react";
  import { useTranslation } from "react-i18next";
  import { useParams, useNavigate } from "react-router-dom";
  import useAppClient from "../../shared/hooks/useAppClient";
  import { capitalize } from "../../utils/capitalize";
  
  import { useEmployeeDetails } from "../../shared/hooks/useEmployeeDetails";
  
  const { Title } = Typography;
  const { confirm } = Modal;
  
  function TravelReports() {
    const apiInstance = useAppClient(true);
    const { locale, companyId, employeeId } = useParams();
    const [loadingTravelReports, setLoadingTravelReports] = React.useState(false);
    const [travelReports, setTravelReports] = React.useState(null);
    const [pagination, setPagination] = React.useState(null);
    const [travelReport, setTravelReport] = React.useState(null);
    const [form] = Form.useForm();
    const [modal, setModal] = React.useState({ open: false });
    const [current, setCurrent] = React.useState(1);
    const reportType = 'KILOMETERS';
    const companyName = useStoreState((state) => state.selectedCompanyModel.name);
    const employee = useEmployeeDetails(employeeId);
    const [downloadReports, setDownloadReports] = React.useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
  
    const getTravelReports = async (current) => {
      setLoadingTravelReports(true);
      apiInstance
        .get(
          `/travel-reports/travel-reports?employeeId=${employeeId}&page=${
            current - 1
          }&reportType=${reportType}`
        )
        .then((response) => {
          setTravelReports(response.data.content);
          setPagination(response.data.pagination);
          setLoadingTravelReports(false);
        });
    };
  
    const onFormChange = (_, allValues) => {
      setTravelReport(allValues);
    };
  
    const onChange = (page) => {
      setCurrent(page);
    };
  
    const onDownload = (record, e) => {
      const btn = e.currentTarget;
      btn.setAttribute("disabled", "disabled");
      document.body.style.cursor = "progress";
      apiInstance
        .post(
          `travel-reports/travel-reports/export`,
          {
            reportIds: [record.id],
          },
          {
            headers: {
              "content-type": "application/json",
              accept: "application/pdf",
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          const fileName = `Kms - ${employee.name} — ${record.year}-${record.month}.pdf`;
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.style.cursor = "default";
          btn.removeAttribute("disabled");
        })
        .catch((error) => {
          btn.removeAttribute("disabled");
          error?.response?.data?.text().then((result) => {
            const responseJSON = JSON.parse(result);
            const dates = responseJSON.errorDates
              .map((date) => moment(date).format("DD, MMMM YYYY"))
              .join(", ");
            Modal.error({
              title: t("unableToDownloadTitle"),
              content: (
                <>
                  <p>
                    {t("unableToDownloadContent1")} <strong>{dates}</strong>.
                  </p>
                  <p>{t("unableToDownloadContent2")}</p>
                </>
              ),
              okText: t("understoodButtonText"),
            });
          });
          document.body.style.cursor = "default";
        });
    };
  
    const onDownloadMultiple = (e) => {
      const btn = e.currentTarget;
      btn.setAttribute("disabled", "disabled");
      document.body.style.cursor = "progress";
      apiInstance
        .post(
          `travel-reports/travel-reports/export`,
          {
            reportIds: downloadReports,
          },
          {
            headers: {
              "content-type": "application/json",
              accept: "application/pdf",
            },
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          const fileName = `Kms - reports.pdf`;
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.style.cursor = "default";
          btn.removeAttribute("disabled");
        });
    };
  
    const travelReportForm = () => {
      return (
        <Form
          form={form}
          name="travelReport"
          layout="vertical"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ date: null, valuePerKM: 0.36 }}
          onValuesChange={onFormChange}
          autoComplete="off"
        >
          <Row>
            <Col span={12}>
              <Form.Item
                name="date"
                label={t("dateLabel")}
                rules={[{ required: true, message: t("dateRequiredMessage") }]}
              >
                <DatePicker picker="month" size="large" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="valuePerKM"
                label={t("valuePerKmLabel")}
                rules={[
                  { required: true, message: t("valuePerKmRequiredMessage") },
                ]}
              >
                <InputNumber
                  min="0.01"
                  max="100"
                  size="large"
                  step="0.01"
                  initialvalues="0.36"
                  addonBefore={t('euroSymbol')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      );
    };
  
    const onDelete = (record) => {
      confirm({
        title: `${t("deleteTravelReportFor")} ${capitalize(
          dayjs().month(record.month - 1).format("MMMM")
        )}, ${record.year}?`,
        icon: <ExclamationCircleOutlined />,
        onOk: () => {
          apiInstance
            .delete(`/travel-reports/travel-reports/${record.id}`)
            .then(() => {
              getTravelReports(current);
            })
            .catch((error) => {
              console.warn("error", error);
            });
        },
        onCancel: () => {
          setModal({ open: false });
        },
      });
    };
  
    const onEdit = (record) => {
      navigate(`/${locale}/company/${companyId}/employee/${employeeId}/travel-report/${record.id}`);
    }
  
    const hideModal = (error) => {
      setTravelReport(null);
      setModal({ open: false });
      form.setFieldsValue(null);
      if (error) {
        Modal.error({
          title: t("modalErrorTitle"),
          content: error.response.data.error,
        });
      }
    };
  
    const onAdd = () => {
      setModal({
        open: true,
        title: t("addTravelReportTitle"),
        content: <>{travelReportForm()}</>,
      });
    };
  
    React.useEffect(() => {
      getTravelReports(current);
    }, [current]);
  
    React.useEffect(() => {
      document.title = `${t("travelReports")} — ${
        companyName }${employee?.name ? " — " + employee.name : ""}`;
    }, [companyName, employee]);
  
    const submitTravelReport = (e) => {
      let month = Number(travelReport.date.format("M"));
      let year = Number(travelReport.date.format("YYYY"));
      e.currentTarget.setAttribute("disabled", "");
      apiInstance.post('/travel-reports/travel-reports/kms', {
        employee: {
          id: employeeId
        },
        company: {
          id: companyId
        },
        month,
        year,
        valuePerKM: travelReport.valuePerKM
      })
      .then((_) => {
        getTravelReports(current);
        form.resetFields();
        hideModal();
      })
      .catch((error) => {
        console.warn("error", error);
        form.resetFields();
        hideModal(error);
      });
    };
  
    const columns = [
      {
        title: t("yearLabel"),
        dataIndex: "year",
        key: "year",
        render: (_, record) => record.year,
      },
      {
        title: t("monthLabel"),
        dataIndex: "month",
        key: "month",
        render: (_, record) =>
          capitalize(dayjs().month(record.month - 1).format("MMMM")),
      },
      {
        title: t("actionLabel"),
        key: "action",
        width: 190,
        align: "right",
        render: (_, record) => (
          <Space size="middle">
            <Tooltip placement="left" title={t("deleteActionLabel")}>
              <DeleteOutlined className='IconTable' onClick={() => onDelete(record)} />
            </Tooltip>
            <Tooltip placement="top" title={t("editActionLabel")}>
              <EditOutlined className='IconTable' onClick={() => onEdit(record)} />
            </Tooltip>
            <Tooltip placement="right" title={t("exportActionLabel")}>
              <DownloadOutlined className='IconTable' onClick={(e) => onDownload(record, e)} />
            </Tooltip>
          </Space>
        ),
      },
    ];
  
    const rowSelection = {
      onChange: (selectedRowKeys, _) => {
        setDownloadReports(selectedRowKeys);
      },
    };
  
    return (
      <>
        <Row>
          <Col span={24}>
            <Title level={1} className="pageTitle">
            { employee?.name ?? ''} 
            </Title>
          </Col>
        </Row>
        <Row>
          <Col span={18}>
            <Title level={2} className="pageSubTitle">
              {t("travelReportsTitle")}
            </Title>
          </Col>
          <Col span={6} align="right">
            {downloadReports !== null && downloadReports.length > 0 && (
              <>
                <Button
                  type="primary"
                  size="medium"
                  icon={<DownCircleOutlined />}
                  onClick={onDownloadMultiple}
                >
                  {t("bulkDownload")}
                </Button>
                &nbsp;&nbsp;&nbsp;
              </>
            )}
            <Button
              type="primary"
              size="medium"
              icon={<PlusCircleFilled />}
              onClick={() => {
                onAdd();
              }}
            >
              {t("travelReportButton")}
            </Button>
          </Col>
        </Row>
        <Table
          loading={loadingTravelReports}
          dataSource={travelReports}
          columns={columns}
          pagination={false}
          rowKey="id"
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
        />
        {travelReports && pagination.totalPages > 1 && (
          <Pagination
            onChange={onChange}
            current={current}
            total={pagination.totalElements}
            showSizeChanger={false}
          />
        )}
        {modal.open === true && (
          <Modal
            title={modal.title}
            open={modal.open}
            onOk={(e) => {
              submitTravelReport(e);
            }}
            onCancel={() => {
              hideModal();
            }}
          >
            {modal.content}
          </Modal>
        )}
      </>
    );
  }
  
  export default TravelReports;
  