import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { useStoreState } from "easy-peasy";

import enUS from "../../translations/en-US.json";
import ptPT from "../../translations/pt-PT.json";

import dayjs from "dayjs";
import "dayjs/locale/pt";

const withTranslation = (Component) => {
    return (props) => {
        const localeState = useStoreState((state) => state.userModel.locale);

        dayjs.locale(localeState);

        if (!i18next.isInitialized) {
          i18next.use(initReactI18next).init({
            lng: localeState,
            debug: true,
            resources: {
              en: {
                translation: enUS,
              },
              pt: {
                translation: ptPT,
              },
            },
            fallbackLng: "pt",
          });
        }

        return (
            <Component {...props} />
        )
    }
}

export default withTranslation;