import react from 'react';

import { CloseCircleOutlined } from "@ant-design/icons";

import {
    Button,
    Card,
    Col,
    Form,
    InputNumber,
    Row,
    Select,
    Typography,
    Space,
} from "antd";

const { Title } = Typography;
const { Option } = Select;

import { useTranslation } from "react-i18next";

const SalaryFormBaseExtra = ({
    shouldLoadDefaults = false,
    baseSalary = [],
    includedBaseSalary = [],
    baseSalaryHandleAdd,
    onIncludedBaseSalaryChange,
    baseSalaryHandleRemove
}) => {
    const { t } = useTranslation();

    return (
        <Card
            title={
            <Row align="middle">
                <Col xs={24} sm={12}>
                    <Title level={5} className="margin0">
                        {t("baseAndExtra")}
                    </Title>
                </Col>
                <Col xs={24} sm={12}>
                    <Form.Item
                        name="allowances"
                        rules={[
                            {
                            required: false,
                            message: t("additionalBaseAndExtra"),
                            },
                        ]}
                        className="marginBottom0"
                    >
                        <Select
                            placeholder={t("selectAdditionalBaseAndExtra")}
                            onChange={baseSalaryHandleAdd}
                            allowClear
                        >
                        {baseSalary &&
                            baseSalary.map((allowance, index) => {
                            return (
                                <Option
                                    key={`allowance-${index}`}
                                    value={allowance.baseSalaryCode}
                                    disabled={
                                        allowance.isDefault ||
                                        allowance.disabledByExclusivity
                                    }
                                >
                                    {allowance.baseSalaryDescription}
                                </Option>
                            );
                        })}  
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        }
        >
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        { 
            !shouldLoadDefaults && includedBaseSalary && includedBaseSalary.map((allowance) => (
            <Row
                justify="space-between"
                align="middle"
                key={`includedBaseSalary-${allowance.baseSalaryCode}`}
                className={!allowance.isActive ? 'row-disabled' : ''}
            >
                <Col xs={12}>{allowance.baseSalaryDescription}</Col>
                <Col xs={5}>
                <Form.Item
                    name={`includedBaseSalary-value-${allowance.baseSalaryCode}`}
                    initialValue={allowance.valueValue ?? 0}
                    style={{marginBottom: '0'}}
                >
                    <InputNumber
                        className="fullWidth"
                        step={1}
                        min={0}
                        status={allowance.status}
                        placeholder={t("valueOfAllowance")}
                        addonAfter={t('euroSymbol')}
                        onChange={(value) => {
                            onIncludedBaseSalaryChange(
                                value,
                                'valueValue',
                                allowance.baseSalaryCode,
                                true
                            );
                        }}
                    />
                </Form.Item>                              
                </Col>
                <Col xs={5}>
                <Form.Item
                    name={`includedBaseSalary-month-${allowance.baseSalaryCode}`}
                    initialValue={allowance.monthValue ?? 0}
                    style={{marginBottom: '0'}}
                >
                    <InputNumber
                        readOnly={!allowance.isEditable}
                        className="fullWidth"
                        min={1}
                        max={14}
                        placeholder={t("lengthOfAllowance")}
                        addonAfter={t("months")}
                        onChange={(value) => {
                            onIncludedBaseSalaryChange(
                                value,
                                'monthValue',
                                allowance.baseSalaryCode,
                                false
                            );
                        }}
                    />
                </Form.Item>
                </Col>
                <Col xs={1} align="right">
                <Button
                    type="text"
                    shape="circle"
                    size="small"
                    icon={<CloseCircleOutlined />}
                    disabled={allowance.isDisabled ?? false}
                    className={allowance.isDisabled? 'btn-disabled' : null}
                    onClick={() => {
                        baseSalaryHandleRemove(allowance.baseSalaryCode);
                    }}
                ></Button>
                </Col>
            </Row>
            ))
        }
        </Space>
      </Card>
    );
}

export default SalaryFormBaseExtra;