import react from 'react';

import { CloseCircleOutlined } from "@ant-design/icons";

import {
    Button,
    Card,
    Col,
    Form,
    InputNumber,
    Row,
    Select,
    Typography,
    Space, 
} from "antd";

const { Title } = Typography;
const { Option } = Select;

import { useTranslation } from "react-i18next";

const SalaryFormWorkMeans = ({
    shouldLoadDefaults = false,
    meansOfWork = [],
    includedMeansOfWork = [],
    workMeansHandleAdd,
    onWorkMeansChange,
    workMeansHandleRemove
}) => {
    const { t } = useTranslation();

    return (
        <Card
            title={
            <Row align="middle">
                <Col xs={24} sm={12}>
                    <Title level={5} className="margin0">
                        {t("workMeans")}
                    </Title>
                </Col>
                <Col xs={24} sm={12}>
                    <Form.Item
                        name="extra-cost"
                        rules={[
                        {
                            required: false,
                            message: t("workMeansQuestion"),
                        },
                        ]}
                        className="marginBottom0"
                    >
                        <Select
                            placeholder={t(
                                "selectWorkMeansToInclude"
                            )}
                            onChange={workMeansHandleAdd}
                            allowClear
                        >
                        {meansOfWork &&
                            meansOfWork.map((cost, index) => {
                            return (
                                <Option
                                    key={`cost-${index}`}
                                    value={cost.meansOfWorkCode}
                                    disabled={
                                        cost.isDefault ||
                                        cost.disabledByExclusivity
                                }
                                >
                                    {cost.meansOfWorkDescription}
                                </Option>
                            );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            }
        >
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            {
            !shouldLoadDefaults && includedMeansOfWork &&
                includedMeansOfWork.map((cost) => {
                return (
                    <Row
                        justify="space-between"
                        align="middle"
                        key={`includedMeansOfWork-${cost.meansOfWorkCode}`}
                        className={!cost.isActive ? 'row-disabled' : ''}
                    >
                        <Col xs={17}>{cost.meansOfWorkDescription}</Col>
                        <Col xs={5}>
                            <Form.Item
                                name={`includedMeansOfWork-value-${cost.meansOfWorkCode}`}
                                initialValue={cost.value ?? 0}
                                style={{marginBottom: '0'}}
                            >
                            <InputNumber
                                readOnly={!cost.isEditable}
                                className="fullWidth"
                                step={1}
                                min={0}
                                max={cost.maximumValue ? cost.maximumValue : null}
                                status={cost.status}
                                placeholder={t("valueOfAdditionalCost")}
                                addonAfter={t('euroSymbol')}
                                onChange={(value) => {
                                    onWorkMeansChange(value, cost.meansOfWorkCode);
                                }}
                            />
                            </Form.Item>
                        </Col>
                        <Col xs={1} align="right">
                            <Button
                                type="text"
                                shape="circle"
                                size="small"
                                icon={<CloseCircleOutlined />}
                                disabled={cost.isDisabled ?? false}
                                className={cost.isDisabled? 'btn-disabled' : null}                                    
                                onClick={() => {
                                    workMeansHandleRemove(cost.meansOfWorkCode);
                                }}
                            />
                        </Col>
                    </Row>
                );
            })}
            </Space>
        </Card>
    );
}

export default SalaryFormWorkMeans;