import { useStoreState, useStoreActions } from "easy-peasy";
import { useCallback, useMemo } from 'react';
import {LOCAL_STORAGE_KEYS} from '../../shared/constants/localStorage';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const useUser = () => {
    const rbacObj = useStoreState((state) => state.rbacModel.rbac);
    const {id, name, permissions} = useStoreState((state) => state.selectedCompanyModel);
    const { companyId } = useParams();
    const [finishLoading, setFinishLoading] = useState(false);

    const setCompany = useStoreActions(
        (state) => state.selectedCompanyModel.setCompany
    );

    const globalPermissions = useMemo(() => {
        return rbacObj?.access?.globalAccess?.permissions ?? [];
    },[rbacObj]);

    const companiesCollection = useMemo(() => {
        return rbacObj?.access?.companiesAccess ?? [];
    },[rbacObj]);

    const saveCompany = (companyId) => {
        if (companyId && companiesCollection.length > 0){
            const company = companiesCollection?.find(company => company.id === companyId)
            setCompany(company);
            localStorage.setItem(LOCAL_STORAGE_KEYS.USER_COMPANY, company);
        }
    }

    const hasAccess = useCallback((permission) => {
        return [...permissions, ...globalPermissions].some(item => item === permission);
    }, [globalPermissions, permissions, rbacObj])

    useEffect(() => {
        if(companyId !== undefined && companiesCollection.length > 0 && !finishLoading){
            saveCompany(companyId);
            setFinishLoading(true);
        }
    },[companyId, rbacObj, companiesCollection, permissions, finishLoading, setFinishLoading]);

    return {
        id, name, permissions,
        globalPermissions,
        hasAccess,
        saveCompany,
        finishLoading
    }
}

export default useUser;