import React from "react";
import { useEffect, useCallback } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import useAppClient from "../hooks/useAppClient";

const withAuth = (Component) => {
    return (props) => {
        const apiInstance = useAppClient();

        const token = useStoreState((state) => state.userModel.token);
        const setRBAC = useStoreActions((state) => state.rbacModel.setRBAC);
        
        const getMe = useCallback(() => {
          if( apiInstance !== undefined) {
            apiInstance.get("/users/me").then((response) => {
              if(response?.data) {
                setRBAC(response.data);
              }
            });
          }
        }, [apiInstance]);
            
        const getMeWithToken = async () => {
          if (token && token.length > 1) {
            getMe();
          };
        };

        useEffect(() => {      
          getMeWithToken();       
        }, [token]);
        
        return (
            <Component {...props} />
        )
    }
}

export default withAuth;