import { createStore, action } from "easy-peasy";

const userModel = {
    token: null,
    setToken: action((state, payload) => {
        state.token = payload;
    }),
    locale: 'pt',
    setLocale: action((state, payload) => {
        state.locale = payload;
    })
}

const rbacModel = {
    rbac: {},
    permissions: [],
    setRBAC: action((state, payload) => {
        state.rbac = payload;
    }),
    setPermissions: action((state, payload) => {
        state.permissions = payload;
    }),
}

const employeeModel = {
    employee: {},
    setEmployee: action((state, payload) => {
        state.employee = payload;
    })
};

const selectedCompanyModel = {
    id: String,
    name: String,
    permissions: [],
    setCompany: action((state, { name, id, permissions}) => {
        state.name = name;
        state.id = id;
        state.permissions = permissions;
    })
};

const menuModel = {
    collapsed: false,
    setCollapsed: action((state, payload) => {
        state.collapsed = payload;
    })
}


const storeModel = {
    userModel,
    rbacModel,
    employeeModel,
    selectedCompanyModel,
    menuModel,
};

export default createStore(storeModel);
