import { useContext } from "react";
import {RowContext} from './Row.context';
import {DragOutlined } from "@ant-design/icons";

export const DragHandle = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);

    return (
        <DragOutlined
            className='IconTable'
            style={{
            cursor: 'move',
            }}
            ref={setActivatorNodeRef}
            {...listeners}
        />
    );
};
