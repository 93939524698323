import { BenefitsMap, BenefitFormItemType, BenefitFormDefaultsValue } from "../hooks/useBenefits.types";
import dayjs from "dayjs";

export const benefitsMap: BenefitsMap = [
    {formField: 'benefitId', apiField: 'benefitId', output: { type: BenefitFormItemType.STRING }},    
    {formField: 'description', apiField: 'description', output: { type: BenefitFormItemType.STRING }},
    {formField: 'category', apiField: 'grouped', output: { type: BenefitFormItemType.STRING }},
    {formField: 'state', apiField: 'status', output: { type: BenefitFormItemType.BOOL }},
    {formField: 'isRequired', apiField: 'isRequired', output: { type: BenefitFormItemType.BOOL }},
    {formField: 'socialSecurity', apiField: 'socialSecurity', output: { type: BenefitFormItemType.BOOL }},
    {formField: 'stateRange', apiField: ['startDate', 'endDate'], output: { type: BenefitFormItemType.DATE_RANGE }},
    {formField: 'proposals', apiField: 'isDefault', output: { type: BenefitFormItemType.BOOL }},
    {formField: 'group', apiField: 'benefitGroupId', output: { type: BenefitFormItemType.STRING }},  
    {formField: 'defaultValue', apiField: 'defaultValue', output: { type: BenefitFormItemType.NUMBER }},
    {formField: 'defaultValueCheck', apiField: 'isEditable', output: { type: BenefitFormItemType.STRING, valueCheck: {true: 'editable', false: 'readOnly'} }},
    {formField: 'frequency', apiField: 'isDaily', output: { type: BenefitFormItemType.STRING, valueCheck: {true: 'daily', false: 'monthly'} }},
    {formField: 'maxValue', apiField: 'maximumValue', output: { type: BenefitFormItemType.NUMBER }},
    {formField: 'months', apiField: 'defaultMonths', output: { type: BenefitFormItemType.NUMBER }},
    {formField: 'irs', apiField: 'irs', output: { type: BenefitFormItemType.STRING, valueCheck: {true: 'yes', false: 'no'} }},
    {formField: 'companyPercentage', apiField: 'socialSecurityCompany', output: { type: BenefitFormItemType.NUMBER }},
    {formField: 'employeePercentage', apiField: 'socialSecurityEmployee', output: { type: BenefitFormItemType.NUMBER }},
    {formField: 'vatDeductible', apiField: 'deductVat', output: { type: BenefitFormItemType.BOOL }},
    {formField: 'vatValue', apiField: 'vat', output: { type: BenefitFormItemType.NUMBER }},
    {formField: 'tribAuto', apiField: 'taTax', output: { type: BenefitFormItemType.NUMBER }},
    {formField: 'employeeValue', apiField: ['commissionValue','commissionPercentage'], output: { type: BenefitFormItemType.NUMBER, refField: 'employeeCheck' }}, 
    {formField: 'employeeCheck', apiField: ['commissionValue','commissionPercentage'], output: { type: BenefitFormItemType.STRING, valueCheck: {true: 'perc', false: 'euro'} }}, 
    {formField: 'alertVisual', apiField: 'alerts', output: { type: BenefitFormItemType.BOOL }},
    {formField: 'alertVisualOrange', apiField: 'alertPercentage1', output: { type: BenefitFormItemType.NUMBER }},
    {formField: 'alertVisualRed', apiField: 'alertPercentage2', output: { type: BenefitFormItemType.NUMBER }},
    {formField: 'superiorApproval', apiField: 'requiresApproval', output: { type: BenefitFormItemType.BOOL }},
    {formField: 'approvalValue', apiField: ['approvalValue','approvalPercentage'], output: { type: BenefitFormItemType.NUMBER, refField: 'approvalCheck' }},
    {formField: 'approvalCheck', apiField: ['approvalValue','approvalPercentage'], output: { type: BenefitFormItemType.STRING, valueCheck: {true: 'perc', false: 'euro'} }}, 
];

export const formDefaults:BenefitFormDefaultsValue = {
    defaults: {
        benefitId: null,
        defaultValueCheck: 'editable',            
        frequency: 'daily',
        irs: 'yes',
        employeeCheck: 'perc',
        approvalCheck: 'perc',
        stateRange:[dayjs(), null]
    },
    disabled: {
        state: true,
        vat: true,
        alerts: true,
        approval: true
    },
    labels: {
        employeeValue: '',
        approvalValue: ''
    }
}
