import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";

type UseHandleApiDataResult<T> = {
  data: T[];
  isLoading: boolean;
  createData: (dataItem: T) => void;
  deleteData: (dataItem: T) => void;
};


const useHandleApiData = <T,>(
  extraParams: string,
  fetchFn: (params: string) => Promise<AxiosResponse<any, any>>,
  createFn: (params: string, item: T) => Promise<AxiosResponse<any, any>>,
  deleteFn: (params: string, item: T) => Promise<AxiosResponse<any, any>>
): UseHandleApiDataResult<T> => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = () => {
    setIsLoading(true);
    fetchFn(extraParams).then((response) => {
      setData(response.data.content);
      setIsLoading(false);
    });
  };

  const deleteData = (dataItem: T) => {
    setIsLoading(true);
    deleteFn(extraParams, dataItem).then(() => {
      fetchData();
    });
  };

  const createData = (dataItem: T) => {
    setIsLoading(true);
    createFn(extraParams, dataItem).then(() => {
      fetchData();
    });
  };

  useEffect(() => {
    fetchData();
  }, [extraParams]);

  return { data, isLoading, createData, deleteData };
};

export default useHandleApiData;
