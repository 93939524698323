import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Space,
  Typography,
  Tooltip
} from "antd";

export const getItinerariesColumns = (t, onDelete, onEdit) => {
    return [
      {
        title: t("originLabel"),
        rowKey: 'id',
        width: '35%',
        children: [
          {
            title: t("clientLabel"),
            dataIndex: "from",
            key: "from",
          },
          {
            title: t("locationLabel"),
            dataIndex: "fromLocation",
            key: "fromLocation",
          },
        ],
      },
      {
        title: t("destinationLabel"),
        width: '35%',
        children: [
          {
            title: t("clientLabel"),
            dataIndex: "to",
            key: "to",
          },
          {
            title: t("locationLabel"),
            dataIndex: "toLocation",
            key: "toLocation",
          },
        ],
      },
      {
        children: [
          {
            title: t('distanceLabel'),
            dataIndex: 'distance',
            key: 'distance',
            render: (text, _) => (
              <>
                <Typography.Text>{text}</Typography.Text>
                <Typography.Text disabled> km</Typography.Text>
              </>
            )
          },    
        ],
      },
      {
        children: [
          {
            title: t('actionLabel'),
            key: 'action',
            width: 140,
            align: 'right',
            render: (_, record) => (
              <Space size="middle">
                <Tooltip placement="left" title={t("deleteActionLabel")}>
                  <DeleteOutlined className='IconTable' onClick={() => onDelete(record.id, record.from, record.to)} />
                </Tooltip>
                <Tooltip placement="right" title={t("editActionLabel")}>
                  <EditOutlined className='IconTable' onClick={() => onEdit(record)} />
                </Tooltip>
              </Space>
            )
          }
        ]
      },
    ];
}