import useHandleApiData from "./useHandleApiData";
import { Employees } from "../clients/Employees";

export const useEmployeeContractKMs = (employeeId: string) => {
  return useHandleApiData(
    employeeId,
    Employees.contractKMs,
    Employees.createContractKMs,
    Employees.deleteContractKMs
  );
};
