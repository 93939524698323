import { LogoutOutlined } from "@ant-design/icons";
import { Button, Col, Layout, Row } from "antd";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, Link } from "react-router-dom";
import i18next from "i18next";
import CompanyPicker from '../company-picker';
import LoadingCompany from "../loading/Company";

import "./Header.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import useUser from '../../shared/hooks/useUser';

const {Header: AntdHeader}  = Layout;

function Header() {
  const { t } = useTranslation();

  const { locale, companyId } = useParams();
  const navigate = useNavigate();

  const {saveCompany} = useUser();
  const rbacObj = useStoreState((state) => state.rbacModel.rbac);
  const companiesCollection = rbacObj?.access?.companiesAccess ?? [];
  const setLocale = useStoreActions((state) => state.userModel.setLocale);
  const isLoading = !rbacObj?.company? true : false;

  const changeLocaleFn = (url, newLocale) => {
    const localePattern = /\/([a-z]{2})\//;
    const match = url.match(localePattern);

    if (match) {
      return url.replace(localePattern, `/${newLocale}/`);
    } else {
      const domainPattern = /^(https?:\/\/[^\/]+)\//;
      const domainMatch = url.match(domainPattern);
      if (domainMatch) {
        return url.replace(domainPattern, `$1/${newLocale}/`);
      }
    }
    return url;
  };

  const changeLocale = () => {

    const newUrl = locale === "pt"? 
    changeLocaleFn(window.location.pathname, "en") :
    changeLocaleFn(window.location.pathname, "pt");

    const newLocale = locale === "pt"? "en" : "pt";
    setLocale(newLocale);
    navigate(newUrl, { replace: true });
    i18next.changeLanguage(newLocale);
  };

  const logout = () => {
    navigate(`/${locale}/logout`, { replace: true });
  };

  useEffect(() => {
    const userCompany = rbacObj?.company;
    if(userCompany && companyId === undefined) {
      saveCompany(userCompany.id);
      navigate(`/${locale}/company/${userCompany.id}/`);
    }
  }, [rbacObj, companyId, companiesCollection, navigate, saveCompany]);

const onChange = (value) => {
  if (companiesCollection?.find(company => company.id === value)) {
    saveCompany(value);
    navigate(`/${locale}/company/${value}/`);  
  }
};

  return (
    <AntdHeader id="header">
      <Row>
        <Col span={12} className="app-menu">
          <Link to="/"><span className="app-logo">{t('appName')}</span></Link>
          <span className="logo-spliter"> | </span>
          <span className="user-info">
          { isLoading ? (
              <LoadingCompany />
            ) : (
              <>{rbacObj?.firstName} {rbacObj?.lastName}</>
            )
          }
            </span>
        </Col>
        <Col span={12} align="right" className="user-menu">
          { isLoading ? (
              <LoadingCompany />
            ) : (
              <>
                <CompanyPicker onChange={onChange} options={
                  companiesCollection?.map(
                    (company) => ({ value: company.id, label: company.name})
                  )} 
                  selectedValue={companyId} 
                /> 
            </>
            )
          }

          <Button type="link" onClick={changeLocale}>
            {locale === "pt" ? (
              <span className="fi fi-pt"></span>
            ) : (
              <span className="fi fi-gb"></span>
            )}
          </Button>
          <Button type="link" onClick={logout}>
            <LogoutOutlined />
          </Button>
        </Col>
      </Row>
    </AntdHeader>
  );
}

export default Header;
