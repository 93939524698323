import {CloseCircleFilled} from "@ant-design/icons";
import { useState, useMemo, useEffect } from "react";
import useBenefits from '../../shared/hooks/useBenefits';

import { AutoComplete } from "antd";
import {DEFAULT_PAGE_SIZE} from '../../shared/constants/query';
import { useTranslation } from "react-i18next";

const {Option} = AutoComplete;

const BenefitSelector = ({
    onBenefitSelectHandler = null, 
    onBenefitClearHandler = null, 
    onBenefitChangeHandler = null,
    onBenefitResultsHandler = null
}) => {
    const {searchResults, searchBenefit, alphabeticalSort} = useBenefits();
    const {t} = useTranslation();
    const [searchTimer, setSearchTimer] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [updatedResults, setUpdatedResults] = useState(false);

    const searchResultsOrdered = useMemo(()=>{
        if(searchResults === null){
            return [];
        } else {
            return searchResults?.content.sort(alphabeticalSort) ?? [];
        }
    },[searchResults, alphabeticalSort]);

    const searchOptions = useMemo(()=>{
        if(searchResultsOrdered === null){
            return [];
        } else {
            return searchResultsOrdered.map(item => <Option key={item.benefitId} value={item.benefitId}>{item.description}</Option>)
        }
    },[searchResultsOrdered]);

    useEffect(()=> {
        if(updatedResults) {
            if(onBenefitResultsHandler !== null) {
                const results = searchResults !== null ? {
                    content: searchResultsOrdered,
                    pagination: {... searchResults.pagination ?? null}
                } : null;
                onBenefitResultsHandler(results);
            }
            setUpdatedResults(false); 
        }
    },[searchResults, searchResultsOrdered, onBenefitResultsHandler, updatedResults])

    const onBenefitChange = (text) => {
        const wasSelected = searchResultsOrdered.some(item => item.benefitId === text);
        const wasEmpty = text && text.length < 0 ? true : false;
        if(!wasSelected || wasEmpty) {
            setSearchValue(text);
            clearTimeout(searchTimer);
            const timer = setTimeout(() => {
                searchBenefit(text, 1, DEFAULT_PAGE_SIZE, () => {
                    setUpdatedResults(true);
                })
            }, 500);
            setSearchTimer(timer);    
            if(onBenefitChangeHandler !== null){
                onBenefitChangeHandler(text);
            };    
        }
    };

    const onBenefitSelect = (value) => {
        const result = searchResultsOrdered.find(item => item.benefitId === value);
        if(result){
            setSearchValue(result.description);
            if(onBenefitSelectHandler !== null){
                onBenefitSelectHandler(result);
            };
        }else{
            defaultBenefitClear(); 
        }
    }

    const defaultBenefitClear = () => {
        setSearchValue(null);
    }

    const onBenefitClear = () => {
        defaultBenefitClear(); 
        if(onBenefitClearHandler !== null){
            onBenefitClearHandler();
        }
    }

    return (
        <AutoComplete
            value={searchValue}
            placeholder={t('benefitsExampleLbl')}
            onChange={(text) => onBenefitChange(text)} 
            allowClear={{
                clearIcon: <CloseCircleFilled />,
            }}
            className='fullWidth'
            onSelect={(value) => onBenefitSelect(value)}
            onClear={() => onBenefitClear()}
        >
            {searchOptions}    
        </AutoComplete>
    )
}

export default BenefitSelector;