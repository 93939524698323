import react from 'react';

import {
    Checkbox,
    Col,
    Divider,
    Form,
    InputNumber,
    Row,
    Select,
    Typography,
} from "antd";

const { Title } = Typography;
const { Option } = Select;

import { useTranslation } from "react-i18next";


const SalaryFormHeader = ({
    locations = [],
    maritalStatus = [],
    contractType = []
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Row justify="center">
                <Col sm={24} md={21}>
                    <Title level={3}>{t("inputs")}</Title>
                    <Divider />
                </Col>
            </Row>
            <Row justify="center">
                <Col sm={24} md={10}>
                    <Form.Item
                        label={t("location")}
                        name="locations"
                        rules={[
                            {
                                required: true,
                                message: t("pleaseTellUsWhereYouLive"),
                            },
                        ]}
                    >
                        <Select placeholder={t("pleaseSelectALocation")}>
                            {locations &&
                            locations.map((location, index) => {
                        return (
                            <Option
                                key={`location-${index}`}
                                value={location.locationCode}
                            >
                                {location.locationDescription}
                            </Option>
                            );
                        })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col sm={24} md={{ span: 10, offset: 1 }}>
                    <Form.Item
                        label={t("status")}
                        name="status"
                        rules={[
                            {
                                required: true,
                                message: t("tellUsYourLegalStatus"),
                            },
                        ]}
                    >
                        <Select 
                            placeholder={t("tellUsYourLegalStatus")}>
                            {maritalStatus &&
                            maritalStatus.map((status, index) => {
                                return (
                                    <Option
                                        key={`maritalStatus-${index}`}
                                        value={status.maritalStatusCode}
                                    >
                                        {status.maritalStatusDescription}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="center">
                <Col sm={24} md={10}>
                    <Row justify="space-between">
                        <Col xs={24} sm={24}>
                            <Form.Item
                                label={t("dependants")}
                                name="dependants"
                                rules={[
                                    {
                                    required: true,
                                    message: t("howManyDependantsDoYouHave"),
                                    },
                                ]}
                            >
                                <InputNumber
                                    placeholder={t("numberOfDependants")}
                                    min={0}
                                    max={100}
                                    className="fullWidth" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col sm={24} md={{ span: 10, offset: 1 }}>
                    <Form.Item
                        label={t("contractType")}
                        name="contractType"
                        rules={[
                            {
                                required: true,
                                message: t("pleaseSelectContractType"),
                            },
                        ]}
                    >
                        <Select placeholder={t("pleaseSelectContractType")}>
                        {contractType &&
                        contractType.map((contract, index) => {
                            return (
                                <Option
                                    key={`contractType-${index}`}
                                    value={contract.contractTypeCode}
                                >
                                    {contract.contractTypeDescription}
                                </Option>
                            );
                        })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="center">
                <Col xs={24} sm={{ span: 21 }}>
                    <Form.Item
                            label={t("disability")}
                            name="checkboxes"
                            valuePropName="checked"
                    >
                        <Checkbox.Group>
                            <Checkbox value="own">{t("own")}</Checkbox>
                            <Checkbox value="partner">{t("partner")}</Checkbox>
                            <Checkbox value="descendant">{t("descendant")}</Checkbox>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
        </>       
    )
}

export default SalaryFormHeader;