import React, { useMemo, useEffect, useState, useCallback } from "react";
import { Menu as AntdMenu, Layout } from "antd";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTranslation } from "react-i18next";
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Icon, { 
  MenuFoldOutlined, 
  MenuUnfoldOutlined, 
  CalculatorOutlined, 
  TeamOutlined, 
  CarOutlined,
  FormOutlined, 
  SettingOutlined 
} from '@ant-design/icons';
import LoadingMenu from "../loading/Menu";
import { MENU_KEYS } from "../../shared/constants/menu";
import { MODULE_PERMISSIONS } from "../../shared/constants/module-permissions";
import "./Menu.css";
import useUser from '../../shared/hooks/useUser';

const { Sider } = Layout;

const Menu = () => {
  const { t } = useTranslation();
  const { locale, companyId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { globalPermissions } = useUser();
  const {permissions: userPermissions} = useUser();
  const rbacObj = useStoreState((state) => state.rbacModel?.rbac);
  const collapsed = useStoreState((state) => state.menuModel.collapsed);
  const setCollapsed = useStoreActions((state) => state.menuModel.setCollapsed);
  const [selectedKey, setSelectedKey] = useState('');

  const getIem = ({label, key, icon, ...other}) => ({
    label,
    key,
    icon,
    ...other
  });

  const menuItems = useMemo(() => {    

    const items = [];

    let addConfigMenu = false;

    const configMenu = getIem({
      label: t('configurations'),
      key: MENU_KEYS.CONFIGURATIONS,
      icon: <SettingOutlined  />,
    });

    if(globalPermissions) {
      globalPermissions.reduce(
        (accumulator, permission) => {
          if (permission === MODULE_PERMISSIONS.SIMULATOR) {
            accumulator.push(getIem({
              label: t('calculator'),
              path: `/${locale}/company/${companyId}/calculator`,
              key: MENU_KEYS.CALCULATOR,
              icon: <CalculatorOutlined />
            }));
          }

          if (permission === MODULE_PERMISSIONS.BO_BENEFITS) {
            addConfigMenu = true;
            configMenu.children = [
              getIem({
                label: t('benefits'),
                path: `/${locale}/company/${companyId}/configurations/benefits`,
                key: MENU_KEYS.BENEFITS,
                icon: <></>
              }),
              getIem({
                label: t('benefitGroups'),
                path: `/${locale}/company/${companyId}/configurations/benefits-group`,
                key: MENU_KEYS.BENEFITS_GROUP,
                icon: <></>
              }),
              getIem({
                label: t('workItems'),
                path: `/${locale}/company/${companyId}/configurations/work-items`,
                key: MENU_KEYS.WORK_ITEMS,
                icon: <></>
              }),
              getIem({
                label: t('commissions'),
                path: `/${locale}/company/${companyId}/configurations/commissions`,
                key: MENU_KEYS.COMMISSIONS,
                icon: <></>
              })
            ];      
          }
          return accumulator;
        },
        items
      );  
    }
    
    if(userPermissions) {
      userPermissions.reduce(
        (accumulator, permission) => {
          if (permission === MODULE_PERMISSIONS.EMPLOYEES) {
            accumulator.push(getIem({
              label: t('employees'),
              path: `/${locale}/company/${companyId}/employees`,
              key: MENU_KEYS.EMPLOYEES,
              icon: <TeamOutlined />
            }));
          }
  
          if (permission === MODULE_PERMISSIONS.ITINERARIES) {
            accumulator.push(getIem({
              label: t('itineraries'),
              path: `/${locale}/company/${companyId}/itineraries`,
              key: MENU_KEYS.ITINERARIES,
              icon: <CarOutlined />
            }));
          }
  
          if (permission === MODULE_PERMISSIONS.PROPOSALS) {
            accumulator.push(getIem({
              label: t('proposals'),
              path: `/${locale}/company/${companyId}/proposals`,
              key: MENU_KEYS.PROPOSALS,
              icon: <FormOutlined className="icon" />
            }));
          }
          return accumulator;
        },
        items
      );  
    }
 
    if(addConfigMenu) {
      items.push(configMenu);
    }

    return items;
  }, [globalPermissions, userPermissions, locale, companyId])

  useEffect(() => {  
    if (location.pathname.includes("itineraries")) {
      setSelectedKey(MENU_KEYS.ITINERARIES);
    } else if (location.pathname.endsWith("calculator")) {
      setSelectedKey(MENU_KEYS.CALCULATOR);      
    } else if (location.pathname.includes("employee")) {
      setSelectedKey(MENU_KEYS.EMPLOYEES);
    } else if (location.pathname.includes("proposals")) {
      setSelectedKey(MENU_KEYS.PROPOSALS);
    } else if (location.pathname.endsWith("benefits")) {
      setSelectedKey(MENU_KEYS.BENEFITS);
    } else if (location.pathname.endsWith("benefits-group")) {
      setSelectedKey(MENU_KEYS.BENEFITS_GROUP);
    } else if (location.pathname.endsWith("work-items")) {
      setSelectedKey(MENU_KEYS.WORK_ITEMS);
    } else if (location.pathname.endsWith("configurations")) {
      setSelectedKey(MENU_KEYS.CONFIGURATIONS);
    } else {
      setSelectedKey('');
    }
  }, [location.pathname, MENU_KEYS]);

  const handleMenuClick = useCallback(({key}) => {
    let path;
    const pathLockup = (item, itemKey) => {
      if(item.path && item.key === itemKey) {
        path = item.path;
      }
      if (!path && item.children) {
        item.children.every((menuItem) => {
          path = pathLockup(menuItem, itemKey);
          if (path) {
            return false;
          }
          return true;
        });
      }
      return path;
    };

    let location = null;
    menuItems.every((menuItem) => {
      const path = pathLockup(menuItem, key); 
      if(path){
        location = path;
        return false;
      }
      return true;
    });

    if(location){
      navigate(location);
    }

  },[navigate, menuItems])

  return (
    <Sider 
      id="side" 
      collapsible 
      collapsed={collapsed} 
      onCollapse={(value) => setCollapsed(value)} 
      trigger={null}
    > 
      <div className="menu-wrapper">
        {rbacObj && Object.keys(rbacObj).length === 0 ? (
            <>
              <LoadingMenu />
            </>
          ) : (
            <>
              <AntdMenu 
                mode="inline"
                items={menuItems} 
                style={{border: 0}} 
                selectedKeys={[selectedKey]} 
                onClick={handleMenuClick}  
                />
              <div className="trigger-wrapper">
                <Icon 
                  onClick={() => setCollapsed(!collapsed)} 
                  component={collapsed ? MenuUnfoldOutlined : MenuFoldOutlined} 
                  className="trigger-menu"
                />
              </div>
            </>            
          )
        }
      </div>
    </Sider>
  )
}

export default Menu;