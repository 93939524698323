
export const getSimulationBody = ({
    formData, 
    includedBaseSalary, 
    includedExtraSalary , 
    includedMeansOfWork, 
    includedCommission
}) => {
    return {
        simulationDetails: {
          contractTypeCode: formData.contractType,
          locationCode: formData.locations,
          maritalStatusCode: formData.status,
          descendants: formData.dependants,
          handicap: {
            own: formData.checkboxes?.includes("own") ? true : false,
            partner: formData.checkboxes?.includes("partner") ? true : false,
            descendant: formData.checkboxes?.includes("descendant") ? true : false,
          },
        },
        salaryBaseEntries: includedBaseSalary.map((entry) => ({
          salaryEntryCode: entry.baseSalaryCode,
          salaryEntryDetails: {
            value: entry.valueValue,
            months: entry.monthValue
          }
        })),
        salaryExtraEntries: includedExtraSalary.map((entry) => ({
            salaryEntryCode: entry.extraSalaryCode,
            salaryEntryDetails: {
              value: entry.valueValue,
              months: entry.monthValue,
          },
        })),
        meansOfWork: includedMeansOfWork.map((entry) => ({
          meansOfWorkCode: entry.meansOfWorkCode,
          value: entry.value,
        })),
        commissionEntries: includedCommission.map((entry) => ({
            commissionCode: entry.commissionCode,
            commissionValue: entry.value,
            commissionRepeat: entry.paymentRepeat
        })),
        salesValue: {
          days: formData.billingDays,
          months: formData.billingMonths,
          hour: formData.saleValueHour,
          day: formData.saleValueDay,
          month: formData.saleValueMonth,
          year: formData.saleValueYear
        },
    }
};