export const MENU_KEYS = {
    CALCULATOR: 'item-calculator',
    EMPLOYEES: 'item-employees',
    ITINERARIES: 'item-itineraries',
    PROPOSALS: 'item-proposals',
    CONFIGURATIONS: 'item-configurations',
    BENEFITS: 'item-benefits',
    BENEFITS_GROUP: 'item-benefits-group',
    WORK_ITEMS: 'item-work-items',
    COMMISSIONS: 'item-commissions'
};