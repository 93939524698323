import { DatePicker, Form, Input, Modal } from "antd";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { IField } from "./FiledWithHistory.interface";

interface ICreateNewFieldModalForm {
  open: boolean;
  onCreate: (values: IField) => void;
  onCancel: () => void;
  description?: string;
  valueFormComponent?: ReactNode;
}

const CreateNewFieldModalForm = ({
  open,
  onCreate,
  onCancel,
  description,
  valueFormComponent,
}: ICreateNewFieldModalForm) => {
  const [form] = Form.useForm();

  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      title={description}
      okText={t("add")}
      cancelText={t("cancel")}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((error) => console.log(error));
      }}
    >
      <Form form={form} layout="vertical" name="new_form_with_children">
        {valueFormComponent && (
          valueFormComponent
        )}
      </Form>
    </Modal>
  );
};

export default CreateNewFieldModalForm;
