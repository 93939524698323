import { useState } from "react";
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Typography,
    notification
  } from "antd";

import { useTranslation } from "react-i18next";

const { TextArea } = Input;
const { Title } = Typography;

import "./Proposals.css";

import useAppClient from "../../shared/hooks/useAppClient";
import ProposalHistory from "./components/ProposalHistory";
import Loading from "../../components/loading/Cats";

const Proposals = ({
    catsHandler,
    shouldRefetchProposal = false
}) => {
    const apiInstance = useAppClient();
    const [form] = Form.useForm();
    const [catsID, setCatsID ] = useState(null);
    const [isFetching, SetIsFetching] = useState(false);
    const [catDetails, setCatDetails ] = useState(null);
    const { t } = useTranslation();
        
    const fetchCatsDetails = () => {
        if(!isFetching) {
            SetIsFetching(true);
        }

        apiInstance
        .get(`/candidates/candidates/${catsID}`)
        .then((response) => {
            if(response?.data){
                
                const {
                    candidateId,
                    catsId, 
                    firstName, 
                    middleName, 
                    lastName, 
                    title, 
                    notes, 
                    emails,
                    currentPay,
                    desiredPay,
                    dateAvailable,
                    address,
                    currentEmployer,
                    phones,
                } = response.data;                

                const content = {
                    address: `${address.street}, ${address.postalCode} - ${address.city}. ${address.state}`, //SIM
                    availabilityDate: dateAvailable,
                    catsId,
                    candidateId,
                    currentSalary: currentPay,
                    desiredSalary: desiredPay,
                    email: emails?.primary ?? '-',
                    name: `${firstName} ${middleName} ${lastName}`,
                    phoneNumber: phones.map(phone => (`${phone.extension} ${phone.number}`)).join(', ') ?? '-',
                    position: title,
                    notes,
                    currentEmployer
                };
                
                setCatDetails(content);
                form.setFieldsValue(content);
                if (catsHandler !== undefined) {
                    catsHandler(content);
                }
            } else {
                setCatDetails(null);
                if (catsHandler !== undefined) {
                    catsHandler(null);
                }
            }
            SetIsFetching(false);

            if(!response){
                setCatDetails(null);
                if (catsHandler !== undefined) {
                    catsHandler(null);
                }
                notification.error({
                    message: t('proposalsErrorMessage'),
                    description: t('proposalsErrorDescription'),
                    placement: "bottomRight",
                }); 
            }
        }).catch((_) => {
            notification.error({
                message: t('proposalsErrorMessage'),
                description: t('proposalsErrorDescription'),
                placement: "bottomRight",
            });                
        });                

    }

    const updateCatsId = (newCatsId) => {
        setCatsID(newCatsId);
    }

    return (
        <Form
            form={form}
            layout="vertical"
            name="proposals-form"
            className="proposalsForm"
        >
            {
                isFetching ? (
                    <Row>
                        <Col sm={24} md={21}>
                            <Loading />
                        </Col>
                    </Row>
                ) : (
                    <>
                        <Row>
                            <Col sm={24} md={21}>
                                <Title level={3}>{t("proposalsCandidateDetails")}</Title>
                                <Divider />
                            </Col>
                        </Row>
        
                        <Row>
                            <Col sm={24} md={14}>
                            <Form.Item
                                label={t("proposalsCandidateCatsIdLabel")}
                                name="queryCatsID"
                                rules={[
                                {
                                    required: true,
                                    message: t("proposalsCandidateCatsIdRequired"),
                                },
                                ]}
                            >
                                <Input placeholder={t('proposalsCandidateCatsIdPlaceholder')} onChange={(e) => updateCatsId(e.currentTarget.value)} />
                            </Form.Item>
                            </Col>
                            <Col sm={24} md={{ span: 6, offset: 1 }}>
                            <Form.Item
                                label={' '}
                                name="candidateCatsIdButton"
                            >
                                <Button
                                    type="primary"
                                    onClick={() => fetchCatsDetails()}
                                    disabled={isFetching || !catsID}
                                    block
                                >
                                    {t('proposalsCandidateCatsIdButtonLabel')}
                                </Button>
                            </Form.Item>
                            </Col>
                        </Row>   
                        {
                            catDetails && (
                                <>
                                    <Row>
                                        <Col sm={24} md={14}>
                                            <Form.Item
                                                label={t("proposalsCandidateNameLabel")}
                                                name="name"
                                            >
                                                <Input readOnly className='read-only'/>
                                            </Form.Item>
                                        </Col>
                                        <Col sm={24} md={{ span: 6, offset: 1 }}>
                                            <Form.Item
                                                label={t("proposalsCandidateRoleLabel")}
                                                name="position"
                                            >
                                                <Input readOnly className='read-only'/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={24} md={21}>
                                            <Form.Item
                                                label={t("proposalsCandidateStreetLabel")}
                                                name="address"
                                            >
                                                <Input readOnly className='read-only'/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={24} md={14}>
                                            <Form.Item
                                            label={t("proposalsCandidateEmailsLabel")}
                                            name="email"
                                            >
                                            <Input readOnly className='read-only'/>
                                            </Form.Item>
                                        </Col>
                                        <Col sm={24} md={{ span: 6, offset: 1 }}>
                                            <Form.Item
                                                label={t("proposalsCandidatePhoneLabel")}
                                                name="phoneNumber"
                                            >
                                                <Input readOnly className='read-only'/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={24} md={21}>
                                            <Title level={3}>{t("proposalsCandidateEmployerLabel")}</Title>
                                            <Divider />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={24} md={10}>
                                            <Form.Item
                                                label={t("proposalsCandidateEmployerNameLabel")}
                                                name="currentEmployer"
                                            >
                                                <Input readOnly className='read-only'/>
                                            </Form.Item>
                                        </Col>
                                        <Col sm={24} md={{ span: 10, offset: 1 }}>
                                            <Form.Item
                                                label={t("proposalsCandidateEmployerDateFreeLabel")}
                                                name="availabilityDate"
                                            >
                                                <Input readOnly className='read-only'/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
        
                                    <Row>
                                        <Col sm={24} md={10}>
                                            <Form.Item
                                                label={t("proposalsCandidateEmployerSalaryLabel")}
                                                name="currentSalary"
                                            >
                                                <Input readOnly className='read-only'/>
                                            </Form.Item>
                                            </Col>
                                        <Col sm={24} md={{ span: 10, offset: 1 }}>
                                            <Form.Item
                                                label={t("proposalsCandidateEmployerNewSalaryLabel")}
                                                name="desiredSalary"
                                            >
                                                <Input readOnly className='read-only'/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
        
                                    <Row>
                                        <Col sm={24} md={21}>
                                            <Form.Item
                                                label={t("proposalsCandidateNotesLabel")}
                                                name="notes"
                                            >
                                                <TextArea readOnly autoSize={{ minRows: 4, maxRows: 10 }} className='read-only'/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
        
                                    <Row>
                                        <Col sm={24} md={21}>
                                            <Title level={3}>{t("proposalsCandidateHistory")}</Title>
                                            <Divider />
                                        </Col>
                                    </Row>
        
                                    <Row>
                                        <Col sm={24} md={21}>
                                            <ProposalHistory candidate={catDetails} keyPrefix="proposals" shouldRefetchProposal={shouldRefetchProposal}/>
                                        </Col>
                                    </Row>
                                </>
                            )
                        }
                    </>
                )
            }
      </Form>
    )
}

export default Proposals;