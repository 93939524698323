import React from 'react';
import Icon, {LoadingOutlined } from "@ant-design/icons";
import "./Menu.css";

const Menu = () => {
    return (
        <Icon className="loading-menu" component={LoadingOutlined} />
    )
}

export default Menu;