import useHandleApiData from "./useHandleApiData";
import { Employees } from "../clients/Employees";

export const useLicensePlates = (employeeId: string) => {
  return useHandleApiData(
    employeeId,
    Employees.licensePlates,
    Employees.createLicensePlate,
    Employees.deleteLicensePlate
  );
};
