import React, {useState, useEffect} from "react"

import {
    Modal
} from 'antd';

const DeleteModal = ({
    shouldOpen = false, 
    data={}, 
    onCancelHandler = null, 
    onOkHandler = null 
}) => {
    const [modal, setModal] = useState({title: '', open: false, content: ''});

    useEffect(()=> {
        if(shouldOpen) {
            setModal({content: data.content ?? '', title: data.title ?? '', open: true});
        }
    },[shouldOpen, data, setModal])

    const onOk = () => {
        if (onOkHandler !== null) {
            onOkHandler();
        }
    }

    const onCancel = () => {
        setModal({open: false, content: '', title: ''});
        if (onCancelHandler !== null) {
            onCancelHandler();
        }
    }

    return (
        <Modal
            title={modal.title}
            open={modal.open}
            onOk={() => {
                onOk();
            }}
            onCancel={() => {
                onCancel();
            }}
        >
            {modal.content}
        </Modal>
    )
}

export default DeleteModal