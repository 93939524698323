import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Employees } from "../clients/Employees";
import { IEmployee } from "../../components/employee/Employee.interface";
import { civilState } from "../../utils/civilState";
import useUser from "./useUser";

export const useEmployeeDetails = (employeeId: string | undefined) => {
  const [employee, setEmployee] = useState<IEmployee | null>(null);
  const { name } = useUser();

  const { t } = useTranslation();

  useEffect(() => {
    if (employeeId) {
      Employees.single(employeeId)
        .then((response) => {
          const employeeData: IEmployee = response.data;
          //convert a company id into a name
          employeeData.companyName = name;
          //convert civil state to a comprehensible string
          employeeData.civilState = civilState(t, employeeData.civilState);

          setEmployee(employeeData);
        })
        .catch((error) => {
          console.warn("error", error);
        });
    }
  }, [employeeId]);

  return employee;
};
