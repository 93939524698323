import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import useAppClient from "../../../shared/hooks/useAppClient";
import Output from './SalaryFormOutput';
import ProposalHistory from '../../proposals/components/ProposalHistory';
import SalaryFormHeader from "./SalaryFormHeader";
import SalaryFormFooter from "./SalaryFormFooter";
import SalaryFormFinance from "./SalaryFormFinance";
import SalaryFormBaseExtra from "./SalaryFormBaseExtra";
import SalaryFormExtraSalary from "./SalaryFormExtraSalary";
import SalaryFormWorkMeans from "./SalaryFormWorkMeans";
import SalaryFormCommissions from "./SalaryFormCommissions";

import {
    Button,
    Col,
    Form,
    Row,
    notification,
    Space, 
    Alert,
    Modal,
    Input
} from "antd";

import {
  useParams,
  useNavigate,
} from "react-router-dom";

const { TextArea } = Input;

import Proposals from '../../proposals';
import Loading from '../../loading/Loading';
import { useState, useEffect, useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { getSimulationBody } from "../../../utils/getSimulationBody";
import { getActiveBenefits } from "../../../utils/getActiveBenefits";

import "./SalaryForm.css";

const modelObject = { open: false, content: <></>, footer: <></>};
const runStatusModel = {success: false, visible: false, message: '', description: ''};

const SalaryForm = ({
    isProposal,
}) => {
  const apiInstance = useAppClient();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [disabledForm, setDisabledForm] = useState(false);
  const [modal, setModal] = useState(modelObject);

  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState(null);
  const [extraSalary, setExtraSalary] = useState([]);
  const [meansOfWork, setMeansOfWork] = useState([]);
  const [includedExtraSalary, setIncludedExtraSalary] = useState([]);
  const [includedMeansOfWork, setIncludedMeansOfWork] = useState([]);
  const [baseSalary, setBaseSalary] = useState([]);
  const [commissions, setCommissions] = useState([]);
  const [includedBaseSalary, setIncludedBaseSalary] = useState([]);
  const [includedCommission, setIncludedCommission] = useState([]);
  const [runStatus, SetRunStatus] = useState({success: false, visible: false, message: '', description: ''});
  const [shouldLoadDefaults, setShouldLoadDefaults] = useState(false);
  const [simulation, setSimulation] = useState(null);
  const [shouldSave, setShouldSave] = useState(false);
  const [replaceProposal, setReplaceProposal] = useState(false);
  const [catsData, setCatsData ] = useState(null);
  const [shouldRefetchProposal, setShouldRefetchProposal] = useState(false);
  const [shouldShowProposalLoading, setShouldShowProposalLoading] = useState(false);
  const [exportProposal, setExportProposal] = useState(false);
  const [newProposal, setNewProposal] = useState(undefined);

  const shouldShowProposalControls = useMemo(()=> {
    return isProposal && simulation && simulation.simulationOutput;
  },[isProposal, simulation])
  
  const navigate = useNavigate();
  const { locale, companyId, proposalId } = useParams();

  const canBeShown = useMemo(() => {
    return data !== null;
  },[data]);

  const baseSalaryHandleAdd = (value) => {
    form.setFieldsValue({ "allowances": null });
    const selectedBaseSalary = baseSalary.find((entry) => {
      let exclusivityClause = entry.exclusiveEntries;
      if (exclusivityClause.length > 0) {
        exclusivityClause.map((exclusion) => {
          let removeExclusive = baseSalary.find((entry) => {
            return entry.baseSalaryCode === exclusion;
          });
          if (removeExclusive) {
            removeExclusive.disabledByExclusivity = true;
          }
        });
      }  
      return entry.baseSalaryCode === value;
    });
    selectedBaseSalary.status = undefined;
    selectedBaseSalary.valueValue = selectedBaseSalary.defaultValue;
    selectedBaseSalary.monthValue = selectedBaseSalary.defaultMonths;
    selectedBaseSalary.disabledByExclusivity = true;

    setIncludedBaseSalary([...includedBaseSalary, selectedBaseSalary]);
  }

  const statusCalculator = ({benefits, codeColumnName, lookupCode, value}) => {
    const refBenefit = benefits.find((entry) => {
      return entry[codeColumnName] === lookupCode;
    });

    if(refBenefit.alerts){
      const currentSalary = includedBaseSalary.find((entry) => {
        return entry.baseSalaryCode === data.constraints.grossSalaryCode;
      }).valueValue;

      const percentage = ((value / currentSalary) * 100).toFixed(2);
      let status;
      if (percentage > refBenefit.alertPercentage1 && percentage < refBenefit.alertPercentage2 ) {
        status = 'warning';
      } else if (percentage >  refBenefit.alertPercentage2 && percentage < Infinity ) {
        status = 'error'
      }

      return status;    
    }
  }

  const onIncludedBaseSalaryChange = (value, type, id, updateStatus) => {
    let tempArray = [...includedBaseSalary];
    const item = tempArray.find((entry) => {
      return entry.baseSalaryCode === id;
    });
    if (item){
      item[type] = value;
      if(updateStatus){
        item.status = statusCalculator({benefits: baseSalary, codeColumnName: 'baseSalaryCode', lookupCode: id, value});
      }
      setIncludedBaseSalary(tempArray);    
    }
  };

  const baseSalaryHandleRemove = (value) => {
    const includedBaseSalaryTempArray = [...includedBaseSalary];
    const allowanceTempArray = [...baseSalary];
    includedBaseSalaryTempArray.find((entry) => {
      let exclusivityClause = entry.exclusiveEntries;
      if (exclusivityClause.length > 0) {
        exclusivityClause.map((exclusion) => {
          allowanceTempArray.find((entry) => {
            if (entry.baseSalaryCode === exclusion) {
              entry.isDefault = false;
              entry.disabledByExclusivity = false;
              return true;
            } else {
              return false;
            }
          });
        });
      }
      if (entry.baseSalaryCode === value) {
        entry.isDefault = false;
        entry.disabledByExclusivity = false;
        return true;
      } else {
        return false;
      }
    });
    let removeIncludedBaseSalaryIndex = includedBaseSalaryTempArray.findIndex(
      (entry) => {
        return entry.baseSalaryCode === value;
      }
    );
    includedBaseSalaryTempArray.splice(removeIncludedBaseSalaryIndex, 1);
    setBaseSalary(allowanceTempArray);
    setIncludedBaseSalary(includedBaseSalaryTempArray);
  } 

  const extraSalaryHandleAdd = (value) => {
    form.setFieldsValue({ "extra-salary": null });
    const selectedIncome = extraSalary.find(entry => entry.extraSalaryCode === value);
    selectedIncome.valueValue = selectedIncome.defaultValue;
    selectedIncome.monthValue = selectedIncome.defaultMonths;
    selectedIncome.disabledByExclusivity = true;
    selectedIncome.status = undefined;

    const exclusivityClause = selectedIncome.exclusiveEntries;
    if(exclusivityClause.length > 0){
      const exclusiveSalaries = exclusivityClause.map((excludeCode) => {
        return {...extraSalary.find(element => element.extraSalaryCode === excludeCode), disabledByExclusivity: true};        
      });

      const newExtraSalary = extraSalary.map(item => ({
          ...item,
          ...exclusiveSalaries.find(element => element.extraSalaryCode === item.extraSalaryCode)
        })
      ).map(item => item.extraSalaryCode !== selectedIncome.extraSalaryCode ? item : selectedIncome);

      setExtraSalary(newExtraSalary);
    }
    setIncludedExtraSalary([...includedExtraSalary, selectedIncome]);
  };

  const extraSalaryHandleRemove = (value) => {      
    let includedExtraSalaryTempArray = [...includedExtraSalary];
    let extraSalaryTempArray = [...extraSalary];

    const incomeToRemove = includedExtraSalaryTempArray.find(entry => entry.extraSalaryCode === value);
    incomeToRemove.disabledByExclusivity = false;

    const exclusivityClause = incomeToRemove.exclusiveEntries;
    
    if(exclusivityClause.length > 0){
      const exclusiveSalaries = exclusivityClause.map((excludeCode) => {
        return {...extraSalaryTempArray.find(element => element.extraSalaryCode === excludeCode), disabledByExclusivity: false};        
      });

      const newExtraSalary = extraSalaryTempArray.map(item => ({
        ...item,
        ...exclusiveSalaries.find(element => element.extraSalaryCode === item.extraSalaryCode)
      })
      ).map(item => item.extraSalaryCode !== incomeToRemove.extraSalaryCode ? item : incomeToRemove);

      setExtraSalary(newExtraSalary);

    } else{
      const newExtraSalary = extraSalaryTempArray.map(item => item.extraSalaryCode == value ? {...item, disabledByExclusivity: false } : item);
      setExtraSalary(newExtraSalary);  
    }
    const newIncludedExtraSalary = includedExtraSalaryTempArray.filter(item => item.extraSalaryCode !== value)
    setIncludedExtraSalary(newIncludedExtraSalary);
  };

  const workMeansHandleAdd = (value) => {
    form.setFieldsValue({ "extra-cost": null });
    let selectedCost = meansOfWork.find((entry) => {
      return entry.meansOfWorkCode === value;
    });
    selectedCost.disabledByExclusivity = true;
    selectedCost.value = selectedCost.defaultValue;
    setIncludedMeansOfWork([...includedMeansOfWork, selectedCost]);
  };

  const workMeansHandleRemove = (value) => {
    let includedMeansOfWorkTempArray = [...includedMeansOfWork];
    includedMeansOfWorkTempArray.find((entry) => {
      if (entry.meansOfWorkCode === value) {
        entry.isDefault = false;
        entry.disabledByExclusivity = false;
        return true;
      } else {
        return false;
      }
    });
    let removeIncludedMeansOfWorkIndex = includedMeansOfWorkTempArray.findIndex(
      (entry) => {
        return entry.meansOfWorkCode === value;
      }
    );
    includedMeansOfWorkTempArray.splice(removeIncludedMeansOfWorkIndex, 1);
    setIncludedMeansOfWork(includedMeansOfWorkTempArray);
  };

  const onWorkMeansChange = (value, id) => {
    let tempArray = [...includedMeansOfWork];
    let item = tempArray.find((entry) => {
      return entry.meansOfWorkCode === id;
    });
    item.value = value;
    setIncludedMeansOfWork(tempArray);
  };

  const onCommissionChange = (value, id) => {
    let tempArray = [...includedCommission];
    let item = tempArray.find((entry) => {
      return entry.commissionCode === id;
    });
    item.value = value;
    item.status = statusCalculator({benefits: commissions, codeColumnName: 'commissionCode', lookupCode: id, value});

    setIncludedCommission(tempArray);
  };

  const commissionHandleRemove = (value) => {
    const includedCommissionTempArray = [...includedCommission];
    includedCommissionTempArray.find((entry) => {
      if(entry.commissionCode === value){
        entry.isDefault = false;
        entry.disabledByExclusivity = false;
        return true;
      } else {
        return false;
      }
    });

    const removeIncludedCommissionIndex = includedCommissionTempArray.findIndex((entry) => {
      return entry.commissionCode === value;
    });
    includedCommissionTempArray.splice(removeIncludedCommissionIndex, 1);
    setIncludedCommission(includedCommissionTempArray);
  }

  const onCommissionPaymentChange = (value, id) => {
    const tempArray = [...includedCommission];
    const item = tempArray.find((entry) => {
      return entry.commissionCode === id;
    });
    item.paymentRepeat = value.target.value === 'month';
    setIncludedCommission(tempArray);
  }

  const commissionsHandleAdd = (value) => {
    form.setFieldsValue({ "commissions": null });

    const selectedCommission = commissions.find((entry) => {
      return entry.commissionCode === value;
    });
    selectedCommission.status = undefined;
    selectedCommission.disabledByExclusivity = true;
    selectedCommission.value = selectedCommission.defaultValue;
    setIncludedCommission([...includedCommission, selectedCommission])
  }

  const onFinish = (formData) => {
    const oldBenefitFound = includedBaseSalary.some(entry => entry.isActive === false) || 
    includedCommission.some(entry => entry.isActive === false) ||
    includedExtraSalary.some(entry => entry.isActive === false) ||
    includedMeansOfWork.some(entry => entry.isActive === false);
    
    if(oldBenefitFound) {
      api.error({
        message: t('calculatorErrorMessage'),
        description: t('calculatorErrorOldDataDescription'),
        placement: "bottomRight",
      });
    } else {
      const simulationBody = getSimulationBody({
        formData,
        includedBaseSalary,
        includedExtraSalary,
        includedMeansOfWork,
        includedCommission
      });
  
      setDisabledForm(true);
      apiInstance
        .post(`/hr-simulation/simulator/simulation${ shouldSave? '?saveSimulation=true': ''}`, simulationBody, {
          headers: {
            'Accept-Language': locale,
          }}).then((response) => {
            setDisabledForm(false);

            if(response && response.data) {
              const { simulationOutput, simulationInput, simulationId } = response?.data;
              simulationHandler({isNew: true, simulationId, simulationInput, simulationOutput});
            } else {
              SetRunStatus({success: false, visible: true, message: t('calculatorErrorMessage'), description: `${t('calculatorUnknownErrorMessage')}`});
            }
        })
        .catch((error) => {
          setDisabledForm(false);
          
          const {data: {message}} = error.response;
          const outDescription = message !== undefined? message : t('apiError400Description');
          SetRunStatus({success: false, visible: true, message: t('apiError400Message'), description: outDescription});
        });
    }
  };
  
  const setFormStaticDefaults = useCallback(() => {
    form.setFieldsValue({
      "billingDays": 21,
      "billingMonths": 11,
      "saleValueHour": 0.0,
      "saleValueDay": 0.0,
      "saleValueMonth": 0.0,
      "saleValueYear": 0.0,
    });
  },[form]);

  const setFormDefaults = useCallback(()=> {

    const defaultIncludedBaseSalary = baseSalary.filter((entry)=> {
      entry.status = undefined;
      if (entry.isDefault === true){
        let exclusivityClause = entry.exclusiveEntries;
        if (exclusivityClause.length > 0) {
          exclusivityClause.map((exclusion) => {
            let removeExclusive = baseSalary.find(
              (entry) => {
                return entry.baseSalaryCode === exclusion;
              }
            );
            if (removeExclusive) {
              removeExclusive.disabledByExclusivity = true;
            }
          });
        }

        entry.valueValue = entry.defaultValue;
        entry.monthValue = entry.defaultMonths;
        entry.disabled = entry.isRequired;
        entry.isActive = true;
        return true;
      } else {
        return false;
      }
    });

    setIncludedBaseSalary(defaultIncludedBaseSalary);
    defaultIncludedBaseSalary.forEach(item => {
      form.setFieldValue(`includedBaseSalary-value-${item.baseSalaryCode}`, item.valueValue);
      form.setFieldValue(`includedBaseSalary-month-${item.baseSalaryCode}`, item.monthValue);
    });

    const defaultIncludedExtraSalary = extraSalary.filter((entry) => {
      entry.status = undefined;
      if (entry.isDefault === true) {
        let exclusivityClause = entry.exclusiveEntries;
        if (exclusivityClause.length > 0) {
          exclusivityClause.map((exclusion) => {
            let removeExclusive = extraSalary.find(
              (entry) => {
                return entry.extraSalaryCode === exclusion;
              }
            );
            if (removeExclusive) {
              removeExclusive.disabledByExclusivity = true;
            }
          });
        }
        entry.valueValue = entry.defaultValue;
        entry.monthValue = entry.defaultMonths;
        entry.isDisabled = entry.isRequired;
        entry.isActive = true;
        return true;
      } else {
        return false;
      }
    })

    setIncludedExtraSalary(defaultIncludedExtraSalary);
    defaultIncludedExtraSalary.forEach(item => {
      form.setFieldValue(`includedExtraSalary-value-${item.extraSalaryCode}`, item.valueValue);
      form.setFieldValue(`includedExtraSalary-month-${item.extraSalaryCode}`, item.monthValue);
    });

    const defaultIncludedMeansOfWork = meansOfWork.filter((entry) => {
      entry.status = undefined;
      if (entry.isDefault === true) {
        entry.value = entry.defaultValue;
        entry.isDisabled = entry.isRequired;
        entry.isActive = true;
        return true;
      } else {
        return false;
      }
    });

    setIncludedMeansOfWork(defaultIncludedMeansOfWork);
    defaultIncludedMeansOfWork.forEach(item => {
      form.setFieldValue(`includedMeansOfWork-value-${item.meansOfWorkCode}`, item.value);
    });

    const defaultIncludedCommission = commissions.filter((entry) => {
      entry.status = undefined;
      if (entry.isDefault === true) {
        entry.value = entry.defaultValue;
        entry.isDisabled = entry.isRequired;
        entry.isActive = true;
        return true;
      } else {
        return false;
      }
    });

    setIncludedCommission(defaultIncludedCommission);

    defaultIncludedCommission.forEach(item => {
      form.setFieldValue(`includedCommission-value-${item.commissionCode}`, item.value);
      form.setFieldValue(`includedCommission-paymentRepeat-${item.commissionCode}`, item.paymentRepeat? 'month' : 'single');
    });

  }, [extraSalary, meansOfWork, baseSalary, commissions]);

  const resetForm = useCallback(() => {    
    form.resetFields();
    setCatsData(null);
    setSimulation(null);
    SetRunStatus(runStatusModel);
    setShouldLoadDefaults(true);
    setNewProposal(undefined);

    if(proposalId) {
      navigate(`/${locale}/company/${companyId}/proposals`);
    }

  },[form, locale, companyId, proposalId, setCatsData, setSimulation, SetRunStatus, setShouldLoadDefaults, setNewProposal]);

  const getReferenceData = useCallback(() => {
    apiInstance
      .get(`/hr-simulation/simulator/reference-data/`,{
        headers: {
          'Accept-Language': locale,
      }})
      .then((response) => {
        const {data} = response; 
        setData({...data});
        const {extraSalary, meansOfWork, baseSalary, commissions} = data;
        setExtraSalary(getActiveBenefits(extraSalary));
        setMeansOfWork(getActiveBenefits(meansOfWork));
        setBaseSalary(getActiveBenefits(baseSalary));
        setCommissions(getActiveBenefits(commissions));
        setShouldLoadDefaults(true);
      })
      .catch((_) => {
        api.error({
          message: t('calculatorErrorMessage'),
          description: t('calculatorErrorDescription'),
          placement: "bottomRight",
        });
      });
  }, [locale, api.error, setIncludedMeansOfWork, setData, setExtraSalary, setMeansOfWork, setBaseSalary, setCommissions]);

  const onExtraSalaryChange = (value, type, id, updateStatus) => {
    let tempArray = [...includedExtraSalary];
    const item = tempArray.find((entry) => {
      return entry.extraSalaryCode === id;
    });
    if(item){
      item[type] = value;
      if(updateStatus){
        item.status = statusCalculator({benefits: extraSalary, codeColumnName: 'extraSalaryCode', lookupCode: id, value});
      }
      setIncludedExtraSalary(tempArray);  
    }
  };

  const tryAutoSave = useCallback(() => {
    form.submit();
  },[form]);

  const fetchProposal = useCallback(() => {
    if(proposalId){
      apiInstance
      .get(`/hr-proposals/hr-proposals/${proposalId}?page=1&size=50`,{
        headers: {
          'Accept-Language': locale,
      }})
      .then((response) => {
        if(response && response.status === 200 && response?.data){
          const { content } = response?.data;
          fetchSimulation(content.find(item => item.proposalId === proposalId).simulationId);
        }
      });
    };
  },[proposalId]);

  const onAlertClose = () => {
    SetRunStatus(runStatusModel);    
  }

  const onBillingDaysChange = () => {
    onSaleValuesRefresh()
  }

  const onBillingMonthsChange = () => {
    onSaleValuesRefresh();
  }

  const onSaleValuesRefresh = () => {
    const inputs = {
      hour: form.getFieldValue('saleValueHour'),
      day: form.getFieldValue('saleValueDay'),
      month: form.getFieldValue('saleValueMonth'),
      year: form.getFieldValue('saleValueYear')
    };
    onSaleValueHourChange(isNaN(inputs.hour)? 0 : inputs.hour);
  }

  const getBilling = () => ({
    days: isNaN(form.getFieldValue('billingDays'))? 0 : form.getFieldValue('billingDays'),
    months: isNaN(form.getFieldValue('billingMonths'))? 0 : form.getFieldValue('billingMonths')
  });

  const onSaleValueHourChange = (value) => {
    const {days, months} = getBilling();
   
    form.setFieldsValue({
      'saleValueDay': (value*8).toFixed(2),
      'saleValueMonth': (value*8*days).toFixed(2),
      'saleValueYear': (value*8*days*months).toFixed(2),
    })
  }

  const onSaleValueDayChange = (value) => {
    const {days, months} = getBilling();
   
    form.setFieldsValue({
      'saleValueHour': (value/8).toFixed(2),
      'saleValueMonth': (value*days).toFixed(2),
      'saleValueYear': (value*days*months).toFixed(2),
    })    
  }

  const catsHandler = (data) => {
    setCatsData(data);
  }

  const shouldSaveNewHandler = ({simulationId}) => {
    if(simulationId){
      if(replaceProposal && catsData) {
        setModal({
          open: true,
          title: t('proposalSaveTitle'),
          content: <>
              <p>{t('proposalSaveReplace')}</p>
              <ProposalHistory shouldReplace={true} candidate={catsData} simulationId={simulationId} keyPrefix="calculator-replace" proposalUpdateHandler={proposalUpdateHandler}  />
          </>,
          footer: <></>
        })  
      } else {
        setShouldRefetchProposal(false);
        setShouldShowProposalLoading(true);
        apiInstance.post('/hr-proposals/hr-proposals', {
          simulationId,
          candidateId: catsData.candidateId,
          notes: catsData.proposalNotes?? '',
        })
        .then((response) => {
          setShouldShowProposalLoading(false);
          const success = response && response.status === 201;
          if(success){
            setNewProposal(response.data);
            setShouldRefetchProposal(true);
          };
          setModal({
            open: true,
            title: t('proposalSaveNewTitle'),
            content: <>
                {success ? (
                  <>
                    <CheckOutlined className="status-ok"/> {t('proposalSaveNewSuccess')}
                  </>
                ) : (
                  <>
                    <CloseOutlined className="status-red"/> {t('proposalSaveNewFail')}
                  </>
                )}
            </>,
            footer: <></>
          })  
        })
        .catch((error) => {
          const {data: {message}} = error.response;
          const outDescription = message !== undefined? message : t('proposalSaveNewFail');
          SetRunStatus({success: false, visible: true, message: t('apiError400Message'), description: outDescription});
        });
      }
      setShouldSave(false);
      setReplaceProposal(false);
    }
  }

  const simulationHandler = useCallback(({isNew, simulationId, simulationInput, simulationOutput, oldDataFound = false}) => {
    setSimulation({simulationId, simulationInput, simulationOutput});

    if(shouldSave){
      shouldSaveNewHandler({simulationId, simulationOutput});
    };
    if (!shouldSave) {
      const outMessage = isNew? t('calculatorSuccessMessage') : t('calculatorSuccessRecoverMessage');
      
      let outDescription = t('calculatorSuccessDescription');
      if (oldDataFound) {
        outDescription += t('calculatorOldBenefitMessage');
      }
      
      SetRunStatus({
        success: true, 
        visible: true, 
        message: outMessage,
        description: outDescription
      });
      if(proposalId){
        navigate(`/${locale}/company/${companyId}/proposals`);
      }
    }
  },[shouldSave, proposalId, SetRunStatus, navigate, shouldSaveNewHandler, setSimulation]);

  const fetchSimulation = useCallback((simulationId) => {
    if(simulationId){
      apiInstance
      .get(`/hr-simulation/hr-simulation/${simulationId}?`,{
        headers: {
          'Accept-Language': locale,
      }})
      .then((response) => {
        if(response && response.status === 200 && response?.data){
          const { simulationInput, simulationOutput } = response?.data;

          let oldDataFound = false;

          const newBaseSalary = simulationInput.salaryBaseEntries.map(
            entry => {
              let selectedAllowance = baseSalary.find((element) => element.baseSalaryCode === entry.salaryEntryCode);
              if(selectedAllowance === undefined){
                oldDataFound = true;
                selectedAllowance = {... data.baseSalary.find((item) => item.baseSalaryCode === entry.salaryEntryCode), isActive: false, isDisabled: false}
              }else {
                selectedAllowance.isDisabled = selectedAllowance.isRequired;
              }
              const exclusivityClause = selectedAllowance.exclusiveEntries;
               if (exclusivityClause.length > 0) {
                exclusivityClause.map((exclusion) => {
                const removeExclusive = baseSalary.find((item) => {
                  return item.baseSalaryCode === exclusion;
                });
                if (removeExclusive) {
                  removeExclusive.disabledByExclusivity = true;
                }
              });
              } else {
                selectedAllowance.disabledByExclusivity = false;
              };

            selectedAllowance.valueValue = entry.salaryEntryDetails.value;
            selectedAllowance.monthValue = entry.salaryEntryDetails.months;
            return selectedAllowance;
          });

          setIncludedBaseSalary(newBaseSalary);
          newBaseSalary.forEach(item => {
            form.setFieldValue(`includedBaseSalary-value-${item.baseSalaryCode}`, item.valueValue);
            form.setFieldValue(`includedBaseSalary-month-${item.baseSalaryCode}`, item.monthValue);
          });

          const newExtraSalary = simulationInput.salaryExtraEntries.map(
            entry => {
              let selectedIncome = extraSalary.find(
                element => element.extraSalaryCode === entry.salaryEntryCode
              )

              if(selectedIncome === undefined) {
                oldDataFound = true;
                selectedIncome = {... data.extraSalary.find((item) => item.extraSalaryCode === entry.salaryEntryCode), isActive: false, isDisabled: false}
              } else {
                selectedIncome.isDisabled = selectedIncome.isRequired;
              }
              const exclusivityClause = selectedIncome.exclusiveEntries;
              if (exclusivityClause.length > 0) {
                exclusivityClause.map((exclusion) => {
                  const removeExclusive = extraSalary.find((item) => {
                    return item.extraSalaryCode === exclusion;
                  });
                  if (removeExclusive) {
                    removeExclusive.disabledByExclusivity = true;
                  }            
                });
              } else {
                selectedIncome.disabledByExclusivity = true;
              }
              selectedIncome.valueValue = entry.salaryEntryDetails.value;
              selectedIncome.monthValue = entry.salaryEntryDetails.months;

              return selectedIncome;
            }
          );

          setIncludedExtraSalary(newExtraSalary);
          newExtraSalary.forEach(item => {
            form.setFieldValue(`includedExtraSalary-value-${item.extraSalaryCode}`, item.valueValue);
            form.setFieldValue(`includedExtraSalary-month-${item.extraSalaryCode}`, item.monthValue);
          });

          const newMeansOfWork = simulationInput.meansOfWork.map(
            entry => {
              let selectedCost = meansOfWork.find(
                element => element.meansOfWorkCode === entry.meansOfWorkCode
              )
              if (selectedCost === undefined) {
                oldDataFound = true;
                selectedCost = {... data.meansOfWork.find((item) => item.meansOfWorkCode === entry.meansOfWorkCode), isActive: false, isDisabled: false}
              } else {
                selectedCost.isDisabled = selectedCost.isRequired;
              }
              selectedCost.value = entry.value;
              selectedCost.disabledByExclusivity = true;

              return selectedCost;
            }
          )

          setIncludedMeansOfWork(newMeansOfWork);
          newMeansOfWork.forEach(item => {
            form.setFieldValue(`includedMeansOfWork-value-${item.meansOfWorkCode}`, item.value);
          });

          const newCommision = simulationInput.commissionEntries.map(
            entry => {
              let selectedCommission = commissions.find(
                element =>
                  element.commissionCode === entry.commissionCode
              );
              if (selectedCommission === undefined){
                oldDataFound = true;
                selectedCommission = {... data.commissions.find((item) => item.commissionCode === entry.commissionCode), isActive: false, isDisabled: false}
              } else {
                selectedCommission.isDisabled = selectedCommission.isRequired;
              }
              selectedCommission.value = entry.commissionValue;
              selectedCommission.paymentRepeat = entry.commissionRepeat;
              selectedCommission.disabledByExclusivity = true;

              return selectedCommission;
            }
          );

          setIncludedCommission(newCommision);
          newCommision.forEach(item => {
            form.setFieldValue(`includedCommission-value-${item.commissionCode}`, item.value);
            form.setFieldValue(`includedCommission-paymentRepeat-${item.commissionCode}`, item.paymentRepeat? 'month' : 'single');
          });

          const checkboxNames = [];
          for (const [key, value] of Object.entries(simulationInput.simulationDetails.handicap)) {
            if(value === true){
              checkboxNames.push(key);
            }
          }

          form.setFieldsValue({
            "locations": simulationInput.simulationDetails.locationCode,
            "status": simulationInput.simulationDetails.maritalStatusCode,
            "dependants": simulationInput.simulationDetails.descendants,
            "contractType": simulationInput.simulationDetails.contractTypeCode,
            "checkboxes": checkboxNames,
            "billingDays": simulationInput.salesValue.days,
            "billingMonths": simulationInput.salesValue.months,
            "saleValueHour": simulationInput.salesValue.hour,
            "saleValueDay": simulationInput.salesValue.day,
            "saleValueMonth": simulationInput.salesValue.month,
            "saleValueYear": simulationInput.salesValue.year
          });
          simulationHandler({isNew: false, simulationId, simulationInput, simulationOutput, oldDataFound});
        }
      });
    };
  },[data, form, extraSalary, baseSalary, commissions, 
    setIncludedCommission, setIncludedMeansOfWork,
    setIncludedExtraSalary, setIncludedBaseSalary, simulationHandler
  ]);

  const saveNewOrReplaceProposal = (tryReplace) => {
    hideModal();
    setShouldSave(true);
    setReplaceProposal(tryReplace);
  }

  const updateCatsNotes = (e) => {
    if(catsData) {
      setCatsData({...catsData, proposalNotes: e.target.value});
    }
  }

  const proposalActionHandler = (trySave = false) => {
    const hasCats = catsData !== null;
    if(hasCats) {
      setExportProposal(false);
      setShouldRefetchProposal(false);

      if(!trySave){
        setExportProposal(true);
        apiInstance.get(`/hr-proposals/hr-proposals/export/${newProposal.proposalId}`,{
          headers: {
            'Accept-Language': locale,
            accept: "application/pdf",
        },
        responseType: 'blob',
      }).then((response) => {
          if(response && response?.data){
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            const contentDisposition = response.headers.get("content-disposition");
            let fileName = `Proposal - ${newProposal.proposalId}.pdf`;
            
            if (contentDisposition !== undefined){
               fileName = contentDisposition.split('; ')[1].replace('filename=','');
            }

            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();  
          }
          setExportProposal(false);
        });
      } else {

        setModal({
          open: true,
          title: trySave ? t('proposalSaveTitle') : t('proposalExportTitle'),
          content: <>
            {trySave ? (
              <>
                {t('proposalSaveContent')}
                <p>{t('proposalsNotesLabel')}</p>
                <p>
                  <TextArea placeholder={t('proposalsNotesPlaceholder')} autoSize={{ minRows: 4, maxRows: 10 }} onChange={updateCatsNotes}/>
                </p>
              </>
            ) : (
              <>
                {exportProposal ? (
                  <Loading />
                ) : (
                  <>{t('proposalExportContent')}</>
                )}
              </>
            )}
          </>,
          footer: <>
            {trySave && (
              <>
                <Button onClick={() => saveNewOrReplaceProposal(true)}>
                  {t('proposalSaveReplaceBtn')}
                </Button>
                <Button type="primary" onClick={() => saveNewOrReplaceProposal(false)}>
                  {t('proposalSaveNewBtn')}
                </Button>
              </>
            )}
          </>
        });
      }

    } else {
      setModal({
        open: true,
        title: t('proposalSaveTitle'),
        content: <>
              <CloseOutlined className="color-red" /> {t('proposalSaveNoCATS')}
            </>,
        footer: <></>
      })
    } 
  }

  const hideModal = () => {
    setModal({ open: false });
  };

  const proposalUpdateHandler = ({status}) => {
    if(status === 200){
      setShouldRefetchProposal(true);
    }
    hideModal();
}

  const saveProposalHandler = () => {
    proposalActionHandler(true);
  }

  const exportProposalHandler = () => {
    proposalActionHandler(false);
  }

  useEffect(() => {
    if(!canBeShown){
      setFormStaticDefaults();
      getReferenceData();
    }
    if(isProposal && proposalId) {
      fetchProposal();
    }
    if(shouldLoadDefaults) {
      setFormDefaults();
      setFormStaticDefaults();
      setShouldLoadDefaults(false);
    };
    if(shouldShowProposalLoading){
      api.info({
        message: t('calculatorProposalSavingTitle'),
        description: t('calculatorProposalSavingBody'),
        placement: "bottomRight",
        duration: 2,
      });
      setShouldLoadDefaults(false);
    }
    shouldSave && tryAutoSave();
  }, [getReferenceData, canBeShown, shouldSave, isProposal, proposalId, shouldLoadDefaults, setShouldLoadDefaults, shouldShowProposalLoading, api.info]);

  return (
    <>
        { !canBeShown  &&
          (
            <Loading />
          )
        }
        {
          !shouldLoadDefaults && canBeShown && data && isProposal && 
            (
              <Row justify="center">
                <Col sm={24} md={{ span: 24, offset: 3 }}>
                  <Proposals catsHandler={ catsHandler } shouldRefetchProposal={shouldRefetchProposal} />
                </Col>
              </Row>
            )
        }

        { canBeShown && data && 
          (
          <Form
            form={form}
            layout="vertical"
            name="salary-form"
            onFinish={onFinish}
            disabled={data === null || disabledForm === true}
          >
            {contextHolder}
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
              {/* TOP FORM HEADER */}
              <SalaryFormHeader 
                locations={data?.locations ?? []}
                maritalStatus={data?.maritalStatus ?? []}
                contractType={data?.contractType ?? []}
              />
              {/* SECTIONS */}
              <Row justify="center">
                <Col sm={24} md={21}>
                  <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    {/* SECTION: BASE & EXTRA */}
                    <SalaryFormBaseExtra
                          shouldLoadDefaults={shouldLoadDefaults}
                          baseSalary={baseSalary}
                          includedBaseSalary={includedBaseSalary}
                          baseSalaryHandleAdd={baseSalaryHandleAdd}
                          onIncludedBaseSalaryChange={onIncludedBaseSalaryChange}
                          baseSalaryHandleRemove={baseSalaryHandleRemove}
                    />
                    {/* SECTION: ADDITIONAL INCOME */}
                    <SalaryFormExtraSalary 
                      shouldLoadDefaults={shouldLoadDefaults}
                      extraSalary={extraSalary}
                      includedExtraSalary={includedExtraSalary}
                      extraSalaryHandleAdd={extraSalaryHandleAdd}
                      onExtraSalaryChange={onExtraSalaryChange}
                      extraSalaryHandleRemove={extraSalaryHandleRemove}
                    />

                    {/* SECTION: WORK MEANS */}
                    <SalaryFormWorkMeans
                      shouldLoadDefaults={shouldLoadDefaults}                      
                      meansOfWork={meansOfWork}
                      includedMeansOfWork={includedMeansOfWork}
                      workMeansHandleAdd={workMeansHandleAdd}
                      onWorkMeansChange={onWorkMeansChange}
                      workMeansHandleRemove={workMeansHandleRemove}                  
                    />

                    {/* SECTION: COMMISSIONS */}
                    <SalaryFormCommissions 
                      shouldLoadDefaults={shouldLoadDefaults}
                      commissions={commissions}
                      includedCommission={includedCommission}
                      commissionsHandleAdd={commissionsHandleAdd}
                      onCommissionChange={onCommissionChange}
                      commissionHandleRemove={commissionHandleRemove}
                      onCommissionPaymentChange={onCommissionPaymentChange}
                    />

                    {/* SECTION: FINANCING ELEMENTS */}
                    {
                      isProposal && simulation === null && (
                        <SalaryFormFinance 
                          isProposal={isProposal}
                          showAsOutput={false}
                          simulationOutput={null}
                          financialData={data?.financialData ?? null}
                          onBillingDaysChange={onBillingDaysChange}
                          onBillingMonthsChange={onBillingMonthsChange}
                          onSaleValueHourChange={onSaleValueHourChange}
                          onSaleValueDayChange={onSaleValueDayChange}
                        />
                      )
                    }
                  </Space>
                </Col>
              </Row>

              {runStatus && runStatus.visible && (
                  <Row justify="center">
                    <Col sm={24} md={21}>
                      <Alert 
                        type={runStatus.success? 'success' : 'error'}
                        showIcon 
                        closable
                        message={runStatus.message}
                        description={runStatus.description}
                        onClose={() => onAlertClose()}
                      />
                    </Col>
                  </Row>
                )
              }

              {
                simulation && simulation.simulationOutput && (
                  <Output 
                    simulationOutput={simulation.simulationOutput} 
                    isProposal={isProposal} 
                    financialData={data?.financialData ?? null} 
                    onBillingDaysChange={onBillingDaysChange}
                    onBillingMonthsChange={onBillingMonthsChange}
                    onSaleValueHourChange={onSaleValueHourChange}
                    onSaleValueDayChange={onSaleValueDayChange}
                  />
                )
              }

              <SalaryFormFooter
                resetForm={resetForm}
                saveProposalHandler={saveProposalHandler}
                exportProposalHandler={exportProposalHandler}
                shouldShowProposalControls={shouldShowProposalControls}
                newProposal={newProposal}
              />

            </Space>
            <Modal
              title={modal.title}
              open={modal.open}
              onCancel={() => {
                hideModal();
              }}
              footer={modal.footer}
            >
              {modal.content}
            </Modal>            
          </Form>
          )
        }
    </>
  )
}

export default SalaryForm;