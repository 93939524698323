export const TYPE_BREADCRUMB = {
    EMPLOYEE: 'EMPLOYEE',
    EMPLOYEES: 'EMPLOYEES',
    TRAVEL_REPORT: 'TRAVEL_REPORT',
    TRAVEL_REPORTS: 'TRAVEL_REPORTS',
    CALCULATOR: 'CALCULATOR',
    ITINERARIES: 'ITINERARIES',
    PROPOSALS: 'PROPOSALS',
    CONFIGURATIONS: 'CONFIGURATIONS',
    BENEFITS: 'BENEFITS',
    BENEFITS_GROUPS: 'BENEFITS_GROUPS',
    WORK_ITEMS: 'WORK_ITEMS',
    COMMISSIONS: 'COMMISSIONS'
}