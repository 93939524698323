import React from "react";

import { ConfigProvider, Layout, Flex } from "antd";
import { Outlet } from "react-router";
import { useStoreState } from "easy-peasy";
import { AuthenticatedTemplate } from "@azure/msal-react";

import withTranslation from "../../shared/hoc/withTranslation";
import withAuth from '../../shared/hoc/withAuth';

import Breadcrumbs from "../../components/breadcrumbs";
import Header from "../../components/header";
import SideMenu from '../../components/side-menu';

import "./App.css";

import enUS from "./../../translations/en-US";
import ptPT from "./../../translations/pt-PT";

import MainContent from "../../components/main-content";

const { Content } = Layout;

const App = () => {
  const localeState = useStoreState((state) => state.userModel.locale);

  return (
    <ConfigProvider locale={localeState === 'en' ? enUS : ptPT}>
      <AuthenticatedTemplate>
        <Flex gap="middle" wrap>
          <Layout>
            <Header />
            <Layout>
              <SideMenu />
              <Content>
                <Breadcrumbs />
                <div id="content">
                  <MainContent content={<Outlet />} />
                </div>
              </Content>
            </Layout>
          </Layout>
        </Flex>
      </AuthenticatedTemplate>
    </ConfigProvider>
  );
};

export default withAuth(withTranslation(App));
