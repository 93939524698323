
import React from 'react'

import { renderFormat } from "../../../utils/renderFormat";
import { TYPE_IRS_DETAIL } from "../../../shared/constants/irs-detail";
import { getColor } from '../../../utils/getColor';

import {
    Card,
    Col,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    Space, 
    Typography,
} from "antd";

const { Title } = Typography;
const { Option } = Select;
import { useTranslation } from "react-i18next";

import "./SalaryFormFinance.css";


const SalaryFormFinance = ({
    isProposal = false,
    showAsOutput = false,
    simulationOutput = null,
    financialData = null,
    onBillingDaysChange,
    onBillingMonthsChange,
    onSaleValueHourChange,
    onSaleValueDayChange,

}) => {
    const { t } = useTranslation();

    return (
        <Card
          title={
            <Row align="middle">
              <Col xs={24} sm={24}>
                <Title level={5} className="margin0">
                  {t("financingElements")}
                </Title>
              </Col>
            </Row>
          }
        > 
          <Space direction="vertical" size="middle">
            <Row
              justify="space-between"
              align="middle"
            >
              <Col xs={12} sm={16}>
                {t("financingElementsBilling")}
              </Col>
              <Col xs={12} sm={8}>
                <Space direction="horizontal" size="middle" style={{float: 'right'}}>
                  <Form.Item
                      name="billingDays"
                      rules={[
                        {
                          required: true,
                          message: t("financingElementsBillingQuestion"),
                        },
                      ]}
                  >
                    <Select
                      open={false}
                      className='read-only'
                      placeholder={t(
                        "financingElementsBillingInclude"
                      )}
                      onChange={() => {onBillingDaysChange()} }
                    >
                      {financialData &&
                        financialData.days.map((element, index) => {
                          return (
                            <Option
                              key={`financing-element-day-${index}`}
                              value={element}
                            >
                              {`${element} ${t('financingElementsBillingDays')}`} 
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                      name="billingMonths"
                      rules={[
                        {
                          required: true,
                          message: t("financingElementsBillingQuestion"),
                        },
                      ]}
                    >
                      <Select
                        placeholder={t(
                          "financingElementsBillingInclude"
                        )}
                        onChange={() => {onBillingMonthsChange()} }
                      >
                        {financialData &&
                          financialData.months.map((element, index) => {
                            return (
                              <Option
                                key={`financing-element-month-${index}`}
                                value={element}
                              >
                                {`${element} ${t('financingElementsMonthsLabel')}`} 
                              </Option>
                            );
                          })}
                      </Select>
                  </Form.Item>
                </Space>
              </Col>
            </Row>
            <Row
              justify="space-between"
              align="middle"
            >
              <Col xs={12} sm={4}>
                <div className="rowSpace">
                  {t("financingElementsSaleValue")}
                </div>
              </Col>
              <Col xs={12} sm={20}>
                <Space direction="horizontal" size="middle">
                  <Form.Item
                    name="saleValueHour"
                    rules={[
                      {
                        required: true,
                        message: t("financingElementsSaleValueRequired"),
                      },
                    ]}
                  >
                    <InputNumber
                      min="0.00"
                      max="999999999"
                      size="large"
                      step="0.01"
                      addonAfter={t('financingElementsRowEuroHour')}
                      onChange={(e) => { onSaleValueHourChange(e) }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="saleValueDay"
                    rules={[
                      {
                        required: true,
                        message: t("financingElementsSaleValueRequired"),
                      },
                    ]}
                  >
                    <InputNumber
                      min="0.00"
                      max="999999999"
                      size="large"
                      step="0.01"
                      addonAfter={t('financingElementsRowEuroDay')}
                      onChange={(e) => { onSaleValueDayChange(e) }}
                    />
                  </Form.Item>                              
                  <Form.Item
                    name="saleValueMonth"
                    rules={[
                      {
                        required: true,
                        message: t("financingElementsSaleValueRequired"),
                      },
                    ]}
                  >
                    <Input 
                      readOnly
                      addonAfter={t('financingElementsRowEuroMonth')}
                      size="large"
                      className='read-only'
                    />

                  </Form.Item>                      
                  <Form.Item
                    name="saleValueYear"
                    rules={[
                      {
                        required: true,
                        message: t("financingElementsSaleValueRequired"),
                      },
                    ]}
                  >
                    <Input 
                      readOnly
                      addonAfter={t('financingElementsRowEuroYear')}
                      size="large"
                      className='read-only'
                    />
                  </Form.Item>
                </Space>                    
              </Col>
            </Row>

            {showAsOutput && isProposal && simulationOutput && (
                  <>
                    {
                      simulationOutput.financialData.employeeCost && (
                        <Row
                          justify="space-between"
                          align="middle"
                        >
                            <Col sm={8} md={8}>
                                {t("financingElementsEmployeeCost")}
                            </Col>
                            <Col sm={16} md={16} align="right">
                                <span className="margin-right-small">{simulationOutput.financialData.employeeCost.hour.toFixed(2)} {t('financingElementsRowEuroHour')}</span>
                                <span className="margin-right-small">{simulationOutput.financialData.employeeCost.day.toFixed(2)} {t('financingElementsRowEuroDay')}</span>
                                <span className="margin-right-small">{simulationOutput.financialData.employeeCost.month.toFixed(2)} {t('financingElementsRowEuroMonth')}</span>                                    
                                <span>{simulationOutput.financialData.employeeCost.year.toFixed(2)} {t('financingElementsRowEuroYear')}</span>
                            </Col>
                        </Row>

                      )
                    }

                    {
                      simulationOutput.financialData.operationMargin0 && (
                        <Row
                          justify="space-between"
                          align="middle"
                        >
                          <Col sm={8} md={8}>
                              {t("financingElementsMargin0")}
                          </Col>

                          <Col sm={16} md={16} align="right">
                              <span className="margin-right-small">{simulationOutput.financialData.operationMargin0.hour.toFixed(2)} {t('financingElementsRowEuroHour')}</span>
                              <span className="margin-right-small">{simulationOutput.financialData.operationMargin0.day.toFixed(2)} {t('financingElementsRowEuroDay')}</span>                         
                              <span className="margin-right-small">{simulationOutput.financialData.operationMargin0.month.toFixed(2)} {t('financingElementsRowEuroMonth')}</span>                          
                              <span>{simulationOutput.financialData.operationMargin0.year.toFixed(2)} {t('financingElementsRowEuroYear')}</span>                                  
                          </Col>
                        </Row>
                      )
                    }

                    {
                      simulationOutput.financialData.operationMarginNext && (
                        <Row
                          justify="space-between"
                          align="middle"
                        >
                          <Col sm={8} md={8}>
                              {t("financingElementsMarginNext")}
                          </Col>

                          <Col sm={16} md={16} align="right">
                              <span className="margin-right-small">{simulationOutput.financialData.operationMarginNext.hour.toFixed(2)} {t('financingElementsRowEuroHour')}</span>
                              <span className="margin-right-small">{simulationOutput.financialData.operationMarginNext.day.toFixed(2)} {t('financingElementsRowEuroDay')}</span>                         
                              <span className="margin-right-small">{simulationOutput.financialData.operationMarginNext.month.toFixed(2)} {t('financingElementsRowEuroMonth')}</span>                          
                              <span>{simulationOutput.financialData.operationMarginNext.year.toFixed(2)} {t('financingElementsRowEuroYear')}</span>                                  
                          </Col>
                        </Row>                  
                      )
                    }

                    <Row
                        justify="space-between"
                        align="middle"
                    >
                        <Col sm={8} md={18}>
                            <span className="margin-item margin-item-blue">{t("financingElementsMargin")}</span>
                        </Col>
                        <Col sm={16} md={6} align="right">
                            <span className={`margin-item ${ getColor(simulationOutput.financialData.margin)}`}>
                                {
                                    renderFormat(
                                        TYPE_IRS_DETAIL.PERCENTAGE, 
                                        simulationOutput.financialData.margin
                                    )
                                }
                            </span>
                        </Col>
                    </Row>





                </>                
            )}               
          </Space>               
      </Card>
    )
}

export default SalaryFormFinance