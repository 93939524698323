import React, {useState, useEffect, useCallback, useMemo} from 'react'
import dayjs from "dayjs";

import {
    SaveOutlined,
    CloseOutlined,
    InfoCircleOutlined,
    PlusOutlined
} from "@ant-design/icons";

import {
    DatePicker,
    Space, 
    Row, 
    Col, 
    Typography, 
    Button, 
    Input, 
    Form,
    Switch,
    Tooltip,
    Select,
    InputNumber,
    Radio,
    Card,
    Divider,
    notification
} from 'antd';

import { useTranslation } from 'react-i18next';
import useBenefitGroups from "../../shared/hooks/useBenefitGroups";
import useBenefits from '../../shared/hooks/useBenefits';
import { formDefaults } from '../../shared/constants/benefits';
import EditGroup from '../benefits-groups/EditGroupOrBenefit';
import { BENEFIT_GROUPS } from '../../shared/constants/benefit-groups';
import { updateFormLabel } from '../../utils/updateFormLabel';
import ConfirmModal from '../confirm-modal';

const { RangePicker } = DatePicker;
const { Title } = Typography;
const { Option } = Select;

const EditBenefit = ({
    selectedBenefit = null, 
    onCancelHandler = null,
    onSuccessHandler = null,
    createNew = false,
    isEditingCommissions = false
}) => {
    const {groups, fetchAll:fetchAllGroups} = useBenefitGroups();
    const { formBenefit, computeFormBenefit, convertFormToBenefitAPI, createBenefit, updateBenefit } = useBenefits();
    const [ changed, setChanged ] = useState(false);
    const [shouldRefetch, setShouldRefetch] = useState(false);
    const [editMode, setEditMode] = useState({addGroup: true, shouldShow: false});

    const [form] = Form.useForm();
    const {t} = useTranslation();

    const [formState, setFormState] = useState(formDefaults);
    const [confirmModal, setConfirmModal] = useState({shouldOpen: false, data:{}});

    const shouldConvertBenefit = useMemo(()=> {
        return selectedBenefit !== null && selectedBenefit.benefitId !== formBenefit?.benefitId
    },[selectedBenefit, formBenefit])

    const shouldSetForm = useMemo(()=> {
        return !changed && formBenefit !== null && formBenefit.benefitId !== form.getFieldValue('benefitId')
    },[changed, formBenefit, formBenefit, form.getFieldValue]);

    const shouldCreateNew = useMemo(()=>{
        return createNew && formState.benefitId === null || formState.benefitId === undefined
    },[createNew, formState])

    useEffect(()=> {
        if(groups === null){
            fetchAllGroups()
        }
        if(shouldConvertBenefit){
            computeFormBenefit(selectedBenefit, formState);
            setFormState(prev => ({
                ...prev,
                ...formDefaults
            }));
            setChanged(false);
        } 
        if(shouldSetForm){
            form.setFieldsValue(formBenefit);
            
            setFormState(prev => ({
                ...prev,
                disabled: {
                    state: !formBenefit.state,
                    vat: !formBenefit.vatDeductible,
                    alerts: !formBenefit.alertVisual,
                    approval: !formBenefit.superiorApproval
                },
                labels:{
                    approvalValue: selectedBenefit.approvalPercentage !== null ? t('percentSymbol') : t('euroSymbol'),
                    employeeValue: selectedBenefit.commissionPercentage !== null ? t('percentSymbol') : t('euroSymbol')
                }
            }));
            setChanged(true);
        }
    },[groups, fetchAllGroups, selectedBenefit, formBenefit, computeFormBenefit, form.setFieldsValue, setChanged, formDefaults, setFormState, formState]);

    const callSuccessHandler = (refetch) => {
        if(onSuccessHandler !== null) {
            onSuccessHandler(refetch);
        }
    }

    const onFinish = (values) => {
        const data = convertFormToBenefitAPI(values);
        
        if(shouldCreateNew) {
            createBenefit(
                data,
                (response) => {
                    notification.success({
                        message: isEditingCommissions ? t('commissionsCreatedSuccess') : t('benefitCreatedSuccess'),
                        placement: "bottomRight",
                    });
                    setShouldRefetch(true);
                    callSuccessHandler(true);
                    setFormState(prev => ({
                        ...prev,
                        benefitId: response.data.benefitId
                    }));
                    setChanged(false);
                    form.setFieldValue('benefitId', response.data.benefitId);
                },
                (error) => {
                    notification.error({
                        message: isEditingCommissions ? t('commissionsCreatedFail') : t('benefitCreatedFail'),
                        description: error?.message ?? '',
                        placement: "bottomRight",
                    });
                    setShouldRefetch(false);
                }
            )
        } else {
            updateBenefit(data,
                (_) => {
                    notification.success({
                        message: isEditingCommissions ? t('commissionsUpdatedSuccess') :  t('benefitUpdatedSuccess'),
                        placement: "bottomRight",
                    });                    
                    setShouldRefetch(true);
                    callSuccessHandler(true);
                },
                (error) => {
                    notification.error({
                        message: isEditingCommissions ? t('commissionsUpdatedFail') :  t('benefitUpdatedFail'),
                        description: error.message,
                        placement: "bottomRight",
                    });
                    setShouldRefetch(false);
                }            
            )
        }
        setChanged(false);
    }

    const onStateChange = (checked) => {
        const inverseChecked = !checked;
        if(inverseChecked){
            form.setFieldsValue({'stateRange': [form.getFieldValue('stateRange')[0], dayjs()]})
        }

        if(checked && formBenefit && formBenefit.stateRange !== null) {
            form.setFieldsValue({'stateRange': [form.getFieldValue('stateRange')[0], formBenefit.stateRange[1]]})
        };
        setFormState((prevState) => ({...prevState,
            disabled: {...prevState.disabled, state: inverseChecked}
        }));
    }

    const onVATChange = (checked) => {
        const inverseChecked = !checked;

        if(inverseChecked){
            form.setFieldsValue({'vatValue': null})
        }
        setFormState((prevState) => ({...prevState,
            disabled: {...prevState.disabled, vat: inverseChecked}
        }));
    }

    const onTribAutoChange = (e) => {
        const newFormState = updateFormLabel(
            formState,
            'employeeValue', 
            e.target.value === 'euro'? t('euroSymbol') : t('percentSymbol')
        );
        setFormState(newFormState);
    }

    const onAlertChange = (checked) => {
        const inverseChecked = !checked;

        if(inverseChecked){
            form.setFieldsValue({'alertVisualOrange': null, 'alertVisualRed': null})
        }
        setFormState((prevState) => ({...prevState,
            disabled: {...prevState.disabled, alerts: inverseChecked}
        }));
    }

    const onApprovalChange = (checked) => {
        const inverseChecked = !checked;

        if(inverseChecked){
            form.setFieldsValue({'approvalValue': null})
        }
        setFormState((prevState) => ({...prevState,
            disabled: {...prevState.disabled, approval: inverseChecked}
        }));
    }

    const onApprovalValueChange = (e) => {
        const newFormState = updateFormLabel(
            formState,
            'approvalValue', 
            e.target.value === 'euro'? t('euroSymbol') : t('percentSymbol')
        );
        setFormState(newFormState);
    }

    const onCreateNewGroup = () => {
        setEditMode(prev => ({...prev, shouldShow: true}));
    }

    const onCancel = () => {
        form.resetFields();
        setChanged(false);
        if (onCancelHandler !== null) {
            onCancelHandler(shouldRefetch);
        }
    }

    const onFormSubmit = useCallback(() => {
        form.validateFields().then(() => {
            form.submit();
        }).catch(() => {
            notification.error({
                message: t('benefitCreatedFail'),
                description: t('benefitCreatedFailMessage'),
                placement: "bottomRight",
            });
        });
    },[form]);

    const onConfirmSaveHandler = () => {
        onFormSubmit();
        setConfirmModal(prev => ({...prev, shouldOpen: false, data:{}}));
    }

    const evalSave = () => {
        let canSave = true;
        if (
            form.getFieldValue('alertVisual') === true && 
            form.getFieldValue('alertVisualOrange') == null && 
            form.getFieldValue('alertVisualRed') == null
        ){
            canSave = false;
            notification.error({
                message: isEditingCommissions ? t('commissionsValidateFail') :  t('benefitValidateFail'),
                description: isEditingCommissions ? t('commissionsValidateFailAlertMessage') :  t('benefitValidateFailAlertMessage'),
                placement: "bottomRight",
            });
        }
        return canSave;
    }

    const onSave = useCallback(() => {
        if(evalSave()){
            if(changed){
                setConfirmModal({shouldOpen: true, data:{
                    title: isEditingCommissions ? t('benefitReplaceConfirmTitle') :  t('benefitReplaceConfirmTitle'),
                    content: isEditingCommissions ? t('commissionsReplaceConfirmBody') :  t('benefitReplaceConfirmBody')
                }});
            } else {
                onFormSubmit();
            }    
        }        
    },[changed, evalSave]);

    const editModeCloseHandler = () => {
        setEditMode(prev => ({...prev, shouldShow: false}));
    }

    const createHandler = () => {
        fetchAllGroups();
    }

    return (
        <>
            <Row justify={'center'}>
                <Col sm={24} md={24}>
                    <Form
                        form={form}
                        layout="vertical"
                        name="benefit-form"
                        onFinish={onFinish}
                        initialValues={formState.defaults}
                        disabled={formBenefit === null && createNew === false}
                    >
                        <Row className='paddingTop2Rem'>
                            <Col sm={24} md={24} className='alignLeft'>
                                <Title level={5}>{ isEditingCommissions? t('commission') : t('benefit')}</Title>
                                <Form.Item 
                                    name="benefitId"
                                    style={{display: 'none'}}
                                >
                                    <Input type="hidden" />
                                </Form.Item>
                            </Col>
                        </Row> 
                        <Row justify={'center'}>
                            <Col sm={24} md={24}>
                                <Form.Item
                                    label={t("benefitDescription")}
                                    name="description"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("benefitRequiredGenericMessage"),
                                        },
                                    ]}
                                >
                                    <Input 
                                        placeholder={t('benefitDescriptionPlaceHolder')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className='paddingTop2Rem'>
                            <Col sm={24} md={24} className='alignLeft'>
                                <Space direction='horizontal' size={'middle'}>
                                    <Title level={5}>
                                        <span className='paddingRightHalfRem'>{t('benefitState')}</span>
                                        <Tooltip placement="right" title={t("benefitStateTooltip")}>
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    </Title>        
                                </Space>
                            </Col>                 
                        </Row>

                        <Row>
                            <Col sm={24} md={4} className='alignLeft'>
                                <Form.Item
                                    label={ !formState.disabled.state? t("benefitActive") : t("benefitInactive") }
                                    name="state"
                                    valuePropName="checked"
                                >
                                    <Switch onChange={onStateChange} />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={20} className='alignLeft'>
                                <Form.Item
                                    label={t("benefitStateDates")}
                                    name="stateRange"
                                >
                                    <RangePicker picker="day" size="large" className="fullWidth" disabled={formState.disabled.state} />
                                </Form.Item>
                            </Col> 
                        </Row>

                        <Row className='paddingTop2Rem'>
                            <Col sm={24} md={24} className='alignLeft'>
                                <Title level={5}>
                                    <span>{t('benefitProposals')}</span>
                                </Title>        
                            </Col>                 
                        </Row>
                        <Row>
                            <Col sm={24} md={3}>
                                <Form.Item
                                    label={t("benefitProposalsBasePackage")}
                                    name="proposals"
                                    valuePropName="checked"
                                >
                                    <Switch />
                                </Form.Item>        
                            </Col>
                            <Col sm={24} md={3}>
                                <Form.Item
                                    label={t("benefitProposalsRequired")}
                                    name="isRequired"
                                    valuePropName="checked"
                                >
                                    <Switch />
                                </Form.Item>
                            </Col>

                            <Col sm={24} md={6}>
                                <Form.Item
                                    label={t("benefitProposalsCategory")}
                                    name="category"
                                    className='marginRight1Rem'
                                    initialValue={isEditingCommissions ? BENEFIT_GROUPS.COMMISSION : null}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("benefitRequiredGenericMessage"),
                                        },
                                    ]}
                                >
                                    {isEditingCommissions ? (
                                        <>
                                            <Select className='fullWidth' placeholder={t('benefitProposalsCategoryHolder')} disabled={isEditingCommissions}>
                                                <Option key={BENEFIT_GROUPS.COMMISSION} value={BENEFIT_GROUPS.COMMISSION}>{t('benefitProposalsCommission')}</Option>
                                            </Select>                                        
                                        </>
                                    ) : (
                                        <>
                                          <Select className='fullWidth' placeholder={t('benefitProposalsCategoryHolder')} allowClear>
                                            <Option key={BENEFIT_GROUPS.BASE_SALARY} value={BENEFIT_GROUPS.BASE_SALARY}>{t('benefitProposalsBaseSalary')}</Option>
                                            <Option key={BENEFIT_GROUPS.EXTRA_SALARY} value={BENEFIT_GROUPS.EXTRA_SALARY}>{t('benefitProposalsExtraSalary')}</Option>
                                          </Select>             
                                        </>
                                    )}
                                </Form.Item>                       
                            </Col>                            
                            <Col sm={24} md={9}>
                                <Form.Item
                                    label={t("benefitProposalsGroupLabel")}
                                    name="group"
                                >
                                    <Select className='fullWidth' allowClear>
                                        {groups && groups.content?.map( 
                                            group => { 
                                                return (<Option key={group.benefitGroupId} value={group.benefitGroupId}>{group.description}</Option>) 
                                            }
                                        )}
                                    </Select>
                                </Form.Item>                       
                            </Col>
                            <Col sm={24} md={3} className='paddingTop2Rem alignRight'>
                                <Button type='primary' onClick={onCreateNewGroup} icon={<PlusOutlined />}>{t('benefitProposalsGroupBtn')}</Button>
                            </Col>                            
                        </Row>

                        <Row>
                            <Col sm={24} md={24}>
                                <Card>
                                    <Row>
                                        <Col sm={24} md={10}>
                                            <b className='marginTop1Rem'>{t('benefitProposalsDefaultValue')}</b>
                                        </Col>
                                        <Col sm={24} md={6} className='alignRight'>
                                            <Form.Item
                                                name="defaultValue"
                                            >
                                                <InputNumber className='fullWidth' addonAfter={t('euroSymbol')}/>
                                            </Form.Item>                                              
                                        </Col>
                                        <Col sm={24} md={8} className='alignRight'>
                                            <Form.Item
                                                name="defaultValueCheck"
                                                valuePropName="value"
                                            >
                                                <Radio.Group>
                                                    <Radio value="editable">{t('benefitProposalsEditable')}</Radio>
                                                    <Radio value="readOnly">{t('benefitProposalsReadOnly')}</Radio>
                                                </Radio.Group>    
                                            </Form.Item>
                                        </Col>
                                        <Divider className='marginZero marginBottom1Rem' />
                                    </Row>
                                    <Row>
                                        <Col sm={24} md={16}>
                                            {t('benefitProposalsFrequency')}
                                        </Col>
                                        <Col sm={24} md={8} className='alignRight'>
                                            <Form.Item
                                                    name="frequency"
                                                    valuePropName="value"
                                            >
                                                <Radio.Group>
                                                    <Radio value="monthly">{t('benefitProposalsMonthly')}</Radio>
                                                    <Radio value="daily">{t('benefitProposalsDaily')}</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={24} md={16}>
                                            {t('benefitProposalsMaxValue')}
                                        </Col>
                                        <Col sm={24} md={8}>
                                            <Form.Item
                                                name="maxValue"
                                            >
                                                <InputNumber className='fullWidth' addonAfter={t('euroSymbol')}/>
                                            </Form.Item>  
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={24} md={16}>
                                            {t('benefitProposalsMonthsLbl')}
                                        </Col>
                                        <Col sm={24} md={8} className='alignRight'>
                                            <Form.Item
                                                name="months"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("benefitRequiredGenericMessage"),
                                                    },
                                                ]}
                                            >
                                                <InputNumber className='fullWidth' addonAfter={t('benefitProposalsMonths')}/>
                                            </Form.Item>     
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={24} md={16}>
                                            {t('benefitProposalsIRS')}
                                        </Col>
                                        <Col sm={24} md={8} className='alignRight'>
                                            <Form.Item
                                                name="irs"
                                                valuePropName="value"
                                                className='marginLeft1Rem'
                                            >
                                                <Radio.Group>
                                                    <Radio value="yes">{t("benefitProposalsYes")}</Radio>
                                                    <Radio value="no">{t("benefitProposalsNo")}</Radio>
                                                </Radio.Group>                                    
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>

                        <Row className='paddingTop2Rem'>
                            <Col sm={24} md={24} className='alignLeft'>
                                <Title level={5}>
                                    <span>{t('benefitSocialSecurity')}</span>
                                </Title>
                            </Col>                       
                        </Row>
                        <Row>
                            <Col sm={24} md={6} className='alignLeft'>
                                <Form.Item
                                    label={t("benefitSocialSecurityCheck")}
                                    name="socialSecurity"
                                    valuePropName="checked"
                                >
                                    <Switch />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={6} className='alignLeft marginRight1Rem'>
                                <Form.Item
                                    label={t("benefitSocialSecurityCompany")}
                                    name="companyPercentage"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("benefitRequiredGenericMessage"),
                                        },
                                    ]}
                                >
                                    <InputNumber className='fullWidth' addonAfter={t('percentSymbol')} />
                                </Form.Item>  
                            </Col>
                            <Col sm={24} md={6} className='alignLeft'>
                                <Form.Item
                                        label={t("benefitSocialSecurityEmployee")}
                                        name="employeePercentage"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("benefitRequiredGenericMessage"),
                                            },
                                        ]}
                                    >
                                        <InputNumber className='fullWidth' max={100} min={0} addonAfter={t('percentSymbol')} />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={6}></Col>
                        </Row>

                        <Row className='paddingTop2Rem'>
                            <Col sm={24} md={24} className='alignLeft'>
                                <Title level={5}>
                                    <span>{t('benefitOtherCost')}</span>
                                </Title>        
                            </Col>                       
                        </Row>
                        <Row>
                            <Col sm={24} md={4} className='alignLeft'>
                                <Form.Item
                                    label={t("benefitOtherCostTaxDeduct")}
                                    name="vatDeductible"
                                    className='marginRight1Rem'
                                    valuePropName="checked"
                                >
                                    <Switch onChange={onVATChange} />
                                </Form.Item>  
                            </Col>
                            <Col sm={24} md={4} className='alignLeft'>
                                <Form.Item
                                        label={' '}
                                        name="vatValue"
                                >
                                    <InputNumber className='fullWidth' max={100} min={0} addonAfter={t('percentSymbol')} disabled={formState.disabled.vat} />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={16}></Col>
                        </Row>
                        <Row>
                            <Col sm={24} md={8} className='alignLeft'>
                                <Form.Item
                                        label={t("benefitOtherCostTribAuto")}
                                        name="tribAuto"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("benefitRequiredGenericMessage"),
                                            },
                                        ]}

                                >
                                    <InputNumber className='fullWidth' max={100} min={0} addonAfter={t('percentSymbol')} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={24} md={8} className='alignLeft'>
                                <Form.Item
                                        label={t("benefitOtherCostProcessCom")}
                                        name="employeeValue"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("benefitRequiredGenericMessage"),
                                            },
                                        ]}

                                    >
                                        <Input 
                                            addonAfter={formState.labels.employeeValue} 
                                            max={formState.labels.employeeValue === t("percentSymbol")  ? 100 : null}
                                        />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12} className='alignLeft'>
                                <Form.Item
                                        label={' '}
                                        name="employeeCheck"
                                        valuePropName="value"
                                        className='marginLeft1Rem'
                                    >
                                    <Radio.Group onChange={onTribAutoChange}>
                                        <Radio value="perc">{t("percentSymbol")}</Radio>
                                        <Radio value="euro">{t("benefitProposalsFixedValue")}</Radio>
                                    </Radio.Group>
                                
                                </Form.Item>
                            </Col>       
                        </Row>

                        <Row className='paddingTop2Rem'>
                            <Col sm={24} md={24} className='alignLeft'>
                                <Space direction='horizontal' size={'middle'}>
                                    <Title level={5}>
                                        <span className='paddingRightHalfRem'>{t('benefitAlerts')}</span>
                                        <Tooltip placement="right" title={t("benefitAlertsTooltip")}>
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    </Title>        
                                </Space>
                            </Col>                 
                        </Row>
                        <Row>
                            <Col sm={24} md={4} className='alignLeft'>
                                <Form.Item
                                    label={t("benefitAlertsVisual")}
                                    name="alertVisual"
                                    className='marginRight1Rem'
                                    valuePropName="checked"
                                >
                                    <Switch onChange={onAlertChange} />
                                </Form.Item>  
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={24} md={12} className='alignLeft'>
                                <Card>
                                    <Row>
                                        <Col sm={24} md={12}>
                                            <b>{t('benefitAlertsColLimit')}</b>
                                        </Col>
                                        <Col sm={24} md={12} className='alignCenter'>
                                            <b>{t('benefitAlertsColPercentSalary')}</b>
                                        </Col>
                                        <Divider />
                                    </Row>
                                    <Row className='paddingBottom1Rem'>
                                        <Col sm={24} md={12}>
                                            {t('benefitAlertsRowOrange')}
                                        </Col>
                                        <Col sm={24} md={12}>

                                            <Form.Item
                                                name="alertVisualOrange"
                                            >
                                                <InputNumber max={100} min={0} addonAfter={t('percentSymbol')} disabled={formState.disabled.alerts} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row className='paddingBottom1Rem'>
                                        <Col sm={24} md={12}>
                                            {t('benefitAlertsRowRed')}
                                        </Col>
                                        <Col sm={24} md={12}>
                                            <Form.Item
                                                name="alertVisualRed"
                                            >
                                                <InputNumber max={100} min={0} addonAfter={t('percentSymbol')} disabled={formState.disabled.alerts} />
                                            </Form.Item>  
                                        </Col>
                                    </Row>

                                </Card>
                            </Col>
                        </Row>

                        <Row className='paddingTop2Rem'>
                            <Col sm={24} md={24} className='alignLeft'>
                                <Title level={5}>
                                    <span>{t('benefitApprovals')}</span>
                                </Title>        
                            </Col>                       
                        </Row>
                        <Row>
                        <Col sm={24} md={4} className='alignLeft'>
                                <Form.Item
                                    name="superiorApproval"
                                    className='marginRight1Rem'
                                    valuePropName="checked"
                                >
                                    <Switch onChange={onApprovalChange} />
                                </Form.Item>  
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={24} md={8} className='alignLeft'>
                                <Form.Item
                                        label={t("benefitApprovalsFromValue")}
                                        name="approvalValue"
                                >
                                    <InputNumber 
                                        className='fullWidth' 
                                        addonAfter={formState.labels.approvalValue} 
                                        disabled={formState.disabled.approval} 
                                        max={formState.labels.approvalValue === t("percentSymbol")  ? 100 : null}
                                    />
                                </Form.Item>
                            </Col>
                            <Col sm={24} md={12} className='alignLeft'>
                                <Form.Item
                                        label={' '}
                                        name="approvalCheck"
                                        valuePropName="value"
                                        className='marginLeft1Rem'
                                >
                                    <Radio.Group onChange={onApprovalValueChange} disabled={formState.disabled.approval} >
                                        <Radio value="perc">{t("percentSymbol")}</Radio>
                                        <Radio value="euro">{t("benefitOtherCostFixedValue")}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>       
                        </Row>

                        <Row>
                            <Col sm={24} md={24} className='alignRight'>
                                <Space direction='horizontal' size={'middle'}>
                                    <Button onClick={() => onCancel()} icon={<CloseOutlined />}>{t('benefitCancelBtn')}</Button>
                                    <Button type='primary' onClick={() => onSave()} icon={<SaveOutlined />}>{t('benefitSaveBtn')}</Button>
                                </Space>
                            </Col>
                        </Row>    
                    </Form>
                </Col>
            </Row>
            <EditGroup editMode={editMode} editModeCloseHandler={editModeCloseHandler} createGroupHandler={createHandler}/>
            <ConfirmModal 
                shouldOpen={confirmModal.shouldOpen}
                data={confirmModal.data}
                onYesHandler={onConfirmSaveHandler} 
            />
        </>
    )
} 

export default EditBenefit;