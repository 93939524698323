import {
  DownCircleOutlined,
  ExclamationCircleOutlined,
  PlusCircleFilled,
  EditOutlined, 
  DeleteOutlined,
  DownloadOutlined
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Pagination,
  Row,
  Space,
  Table,
  Typography,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import { useStoreState } from "easy-peasy";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import useAppClient from "../../shared/hooks/useAppClient";
import "./PerDiems.css";

import { useEmployeeDetails } from "../../shared/hooks/useEmployeeDetails";

const { Title } = Typography;
const { confirm } = Modal;

const PerDiems = () => {
  const instance = useAppClient(true);
  const { locale, companyId, employeeId, reportType } = useParams();
  const [loadingPerDiems, setLoadingPerDiems] = React.useState(false);
  const [perDiems, setPerDiems] = React.useState(null);
  const [pagination, setPagination] = React.useState(null);
  const [perDiem, setPerDiem] = React.useState(null);
  const [form] = Form.useForm();
  const [modal, setModal] = React.useState({ open: false });
  const [current, setCurrent] = React.useState(1);
  const companyName = useStoreState((state) => state.selectedCompanyModel.name);
  const employee = useEmployeeDetails(employeeId);
  const [downloadReports, setDownloadReports] = React.useState(null);
  const apiHandler = React.useMemo(() => {
    return reportType === 'NATIONAL' ? 'nat' : 'int';
  },[reportType])
  const navigate = useNavigate();

  const { t } = useTranslation();

  const formatPerdiems = (perDiems) => {
    return perDiems.map((perDiem) => {
      return {
        ...perDiem,
        month: perDiem.month - 1
      }
    })
  }

  const getPerDiems = async (current) => {
    setLoadingPerDiems(true);
    instance
      .get(
        `/travel-reports/travel-reports?employeeId=${employeeId}&page=${
          current - 1
        }&reportType=${reportType}`
      )
      .then((response) => {
        setPerDiems(formatPerdiems(response.data.content));
        setPagination(response.data.pagination);
        setLoadingPerDiems(false);
      });
  };

  const onFormChange = (changedValues, allValues) => {
    setPerDiem(allValues);
  };

  const onChange = (page) => {
    setCurrent(page);
  };

  const onDownload = (record, e) => {
    const btn = e.currentTarget;
    btn.setAttribute("disabled", "disabled");
    document.body.style.cursor = "progress";
    instance
    .post(
        `travel-reports/travel-reports/export`,
        {
          reportIds: [record.id],
        },
        {
          headers: {
            "content-type": "application/json",
            accept: "application/pdf",
          },
          responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        const fileName = `AC - ${employee.name} — ${record.year}-${record.month}.pdf`;
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.style.cursor = "default";
        btn.removeAttribute("disabled");
      })
      .catch((error) => {
        btn.removeAttribute("disabled");
        error?.response?.data?.text().then((result) => {
          const responseJSON = JSON.parse(result);
          const dates = responseJSON.errorDates
            .map((date) => moment(date).format("DD, MMMM YYYY"))
            .join(", ");
          Modal.error({
            title: t("unableToDownloadTitle"),
            content: (
              <>
                <p>
                  {t("unableToDownloadContent1")} <strong>{dates}</strong>.
                </p>
                <p>{t("unableToDownloadContent2")}</p>
              </>
            ),
            okText: t("understoodButtonText"),
          });
        });
        document.body.style.cursor = "default";
      });
  };

  const onDownloadMultiple = (e) => {
    const btn = e.currentTarget;
    btn.setAttribute("disabled", "disabled");

    document.body.style.cursor = "progress";
    instance
      .post(
        `travel-reports/travel-reports/export`,
        {
          reportIds: downloadReports,
        },
        {
          headers: {
            "content-type": "application/json",
            accept: "application/pdf",
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        const fileName = `AC - reports.pdf`;
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.style.cursor = "default";
        btn.removeAttribute("disabled");
      });
  };

  const perDiemForm = (reportType) => {
    const valuePerDay = reportType === "NATIONAL" ? 50.2 : 89.35;
    return (
      <Form
        form={form}
        name="perDiem"
        layout="vertical"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ date: null, valuePerDay: valuePerDay }}
        onValuesChange={onFormChange}
        autoComplete="off"
      >
        <Row>
          <Col span={12}>
            <Form.Item
              name="date"
              label={t("dateLabel")}
              rules={[{ required: true, message: t("dateRequiredMessage") }]}
            >
              <DatePicker picker="month" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item
              name="valuePerDay"
              label={t("valuePerDayLabel")}
              rules={[
                { required: true, message: t("valuePerDayRequiredMessage") },
              ]}
            >
              <InputNumber
                min="0.01"
                max="100"
                size="large"
                step="0.01"
                initialvalues={valuePerDay}
                addonBefore={t('euroSymbol')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>  
    );
  };

  const onDelete = (record) => {
    confirm({
      title: `${t("deletePerDiemFor")} ${dayjs()
        .month(record.month)
        .format("MMMM")}, ${record.year}?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        instance
          .delete(`/travel-reports/travel-reports/${record.id}`)
          .then(() => {
            getPerDiems(current);
          })
          .catch((error) => {
            console.warn("error", error);
          });
      },
      onCancel: () => {
        setModal({ open: false });
      },
    });
  };

  const onEdit = (record) => {
    navigate(`/${locale}/company/${companyId}/employee/${employeeId}/per-diem/${reportType}/${record.id}`)
  }

  const hideModal = (error) => {
    setPerDiem(null);
    setModal({ open: false });
    form.setFieldsValue(null);
    if (error) {
      Modal.error({
        title: t("modalErrorTitle"),
        content: <p>{error.response.data.error}</p>,
      });
    }
  };

  const onAdd = () => {
    setModal({
      open: true,
      title: t("addPerDiemTitle"),
      content: <>{perDiemForm(reportType)}</>,
    });
  };

  React.useEffect(() => {
    getPerDiems(current);
  }, [current]);

  React.useEffect(() => {
    document.title = `${t("perDiems")} — ${
      companyName }${employee?.name ? " — " + employee.name : ""}`;
  }, [companyName, employee]);

  const submitPerDiem = (e) => {
    let month = Number(perDiem.date.format("M"));
    let year = Number(perDiem.date.format("YYYY"));

    e.currentTarget.setAttribute("disabled", "");
    instance.post(`/travel-reports/travel-reports/${apiHandler}`, {
      employee: {
        id: employeeId
      },
      company: {
        id: companyId
      },
      month,
      year,
      valuePerDay: perDiem.valuePerDay,
    })
    .then(() => {
      getPerDiems(current);
      form.resetFields();
      hideModal();
      })
    .catch((error) => {
      console.warn("error", error);
      form.resetFields();
      hideModal(error);
    });
  };

  const columns = [
    {
      title: t("yearLabel"),
      dataIndex: "period",
      key: "period",
      render: (_, record) => record.year,
    },
    {
      title: t("monthLabel"),
      dataIndex: "period",
      key: "period",
      render: (_, record) => (
        <p style={{ textTransform: "capitalize" }}>
          {dayjs().month(record.month).format("MMMM")}
        </p>
      ),
    },
    {
      title: t("actionLabel"),
      key: "action",
      width: 190,
      align: "right",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip placement="left" title={t("deleteActionLabel")}>
            <DeleteOutlined className='IconTable' onClick={() => onDelete(record)} />
          </Tooltip>
          <Tooltip placement="top" title={t("editActionLabel")}>
            <EditOutlined className='IconTable' onClick={() => onEdit(record)} />
          </Tooltip>
          <Tooltip placement="right" title={t("exportActionLabel")}>
            <DownloadOutlined className='IconTable' onClick={(e) => onDownload(record, e)} />
          </Tooltip>          
        </Space>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDownloadReports(selectedRowKeys);
    },
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Title level={1} className="pageTitle">
            { employee?.name ?? '' }
          </Title>
        </Col>
      </Row>
      <Row>
        <Col span={19}>
          <Title level={2} className="pageSubTitle">
            {t("perDiemsTitle")}
          </Title>
        </Col>
        <Col span={5} align="right">
          {downloadReports !== null && downloadReports.length > 0 && (
            <>
              <Button
                type="primary"
                size="medium"
                icon={<DownCircleOutlined />}
                onClick={onDownloadMultiple}
              >
                {t("bulkDownload")}
              </Button>
              &nbsp;&nbsp;&nbsp;
            </>
          )}
          <Button
            type="primary"
            size="medium"
            icon={<PlusCircleFilled />}
            onClick={() => {
              onAdd();
            }}
          >
            {t("perDiemButton")}
          </Button>
        </Col>
      </Row>
      <Table
        loading={loadingPerDiems}
        dataSource={perDiems}
        columns={columns}
        pagination={false}
        rowKey="id"
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
      />
      {perDiems && pagination.totalPages > 1 && (
        <Pagination
          onChange={onChange}
          current={current}
          total={pagination.totalElements}
          showSizeChanger={false}
        />
      )}
      {modal.open === true && (
        <Modal
          title={modal.title}
          open={modal.open}
          onOk={(e) => {
            submitPerDiem(e);
          }}
          onCancel={() => {
            hideModal();
          }}
        >
          {modal.content}
        </Modal>
      )}
    </>
  );
};

export default PerDiems;
