import { Form, Row, Col, InputNumber, DatePicker, Space } from "antd";
import { useTranslation } from "react-i18next";

const BenefitACForm = () =>{
    const { t } = useTranslation();
    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <Row justify={'center'} className='paddingTop2Rem'>
              <Col sm={24} md={8}>
                <Form.Item
                  name="valueDay"
                  label={t("newValueDay")}
                  rules={[{ required: true, message: t("enterNumberMessage") }]}
                >
                  <InputNumber />
                </Form.Item>
              </Col>
              <Col sm={24} md={8}>
                <Form.Item
                  name="valueMonth"
                  label={t("newValueMonth")}
                  rules={[{ required: true, message: t("enterNumberMessage") }]}
                >
                  <InputNumber />
                </Form.Item>
              </Col>
              
              <Col sm={24} md={8}>
                <Form.Item
                  name="date"
                  label={t("validSince")}
                  rules={[{ required: true, message: t("validDateMessage") }]}
                >
                  <DatePicker picker="month" placeholder={t("selectMonth")} />
                </Form.Item>
              </Col>
          </Row>
        </Space>
    )
}

export default BenefitACForm;