import { BenefitFormDefaultsValue, BenefitFormItemType, BenefitsMap } from "../hooks/useBenefits.types";

export const commissionsMap: BenefitsMap = [
    {formField: 'benefitId', apiField: 'benefitId', output: { type: BenefitFormItemType.STRING }},
    {formField: 'description', apiField: 'description', output: { type: BenefitFormItemType.STRING }},
    {formField: 'employeeValue', apiField: ['commissionValue','commissionPercentage'], output: { type: BenefitFormItemType.NUMBER, refField: 'employeeCheck' }}, 
    {formField: 'employeeCheck', apiField: ['commissionValue','commissionPercentage'], output: { type: BenefitFormItemType.STRING, valueCheck: {true: 'perc', false: 'euro'} }}, 
]

export const formDefaults:BenefitFormDefaultsValue = {
    defaults: {
        benefitId: null,
        employeeCheck: 'perc',
    },
    labels: {
        employeeValue: '',
    }
}