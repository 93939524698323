import { Tabs } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loading from "../loading/Loading";
import EmployeeContext from "./Employee.context";
import EmployeeBenefits from "./components/EmployeeBenefits";
import EmployeeDetail from "./components/EmployeeDetail";
import EmployeeHeader from "./components/EmployeeHeader";
import EmployeeOtherInfo from "./components/EmployeeOtherInfo";
import { useEmployeeDetails } from "../../shared/hooks/useEmployeeDetails";

const Employee = () => {
  const { employeeId } = useParams();
  const employee = useEmployeeDetails(employeeId);
  const { t } = useTranslation();

  React.useEffect(() => {
    document.title = `${t('employeeTitle')} — ${employee?.name}`;
  }, [employee]);

  return employee ? (
    <EmployeeContext.Provider value={employee}>
      <EmployeeHeader />
      <Tabs
        defaultActiveKey="details"
        items={[
          {
            label: t("employee.details"),
            key: "details",
            children: <EmployeeDetail />,
          },
          {
            label: t("employee.otherInfo"),
            key: "otherInfo",
            children: <EmployeeOtherInfo />,
          },
          {
            label: t("employee.benefits"),
            key: "benefits",
            children: <EmployeeBenefits />,
          },
        ]}
      />
    </EmployeeContext.Provider>
  ) : (
    <Loading />
  );
};

export default Employee;
