import { getCompanyColor } from "./getCompanyColor";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import {
  Dropdown,
  Space,
  Tag,
} from "antd";

export const getEmployeeColumns = (t, onCustomCell, locale, companyId) => {
    return [
        {
          title: t("erpId"),
          dataIndex: "erpId",
          key: "erpId",
          width: "100px",
          onCell: onCustomCell,
        },
        {
          title: t("name"),
          dataIndex: "name",
          key: "name",
          width: "60%",
          onCell: onCustomCell,
        },
        {
          title: t("labelClientColumn"),
          dataIndex: "clientName",
          key: "clientName",
          onCell: onCustomCell,
          render: (clientName) => {
            return (
              clientName && (
                <Tag color={getCompanyColor(clientName)} key={clientName}>
                  {clientName}
                </Tag>
              )
            );
          },
        },
        {
          title: t("actionLabel"),
          key: "action",
          width: 160,
          align: "right",
          render: (text, record) => (
            <Space size="middle">
              <Dropdown
                menu={{
                  items: [
                    {
                      key: 0,
                      label: (
                        <Link
                          to={`/${locale}/company/${companyId}/employee/${record.id}`}
                        >
                          {t("viewEmployeeLink")}
                        </Link>
                      ),
                    },
                    {
                      key: 1,
                      label: (
                        <Link
                          to={`/${locale}/company/${companyId}/employee/${record.id}/travel-reports`}
                        >
                          {t("travelReportsButton")}
                        </Link>
                      ),
                    },
                    {
                      key: 2,
                      label: (
                        <Link
                          to={`/${locale}/company/${companyId}/employee/${record.id}/per-diems/NATIONAL`}
                        >
                          {t("nPerDiemsButton")}
                        </Link>
                      ),
                    },
                    // {
                    //   key: 3,
                    //   label: (
                    //     <Link
                    //       to={`/${locale}/company/${companyId}/employee/${record.id}/per-diems/INTERNATIONAL`}
                    //     >
                    //       {t("iPerDiemsButton")}
                    //     </Link>
                    //   ),
                    // },
                  ],
                }}
              >
                <a href="/" onClick={(e) => e.preventDefault()}>
                  <MenuOutlined />
                </a>
              </Dropdown>
            </Space>
          ),
        },
    ];
}