import {
    Card,
    Col,
    Divider,
    Row,
    Typography,
    Space,
  } from "antd";

import { renderFormat } from "../../../utils/renderFormat";
import { TYPE_IRS_DETAIL } from "../../../shared/constants/irs-detail";

const { Title } = Typography;

import { useTranslation } from "react-i18next";
import "./SalaryFormOutput.css";
import SalaryFormFinance from "./SalaryFormFinance";

const Output = ({ 
    simulationOutput,
    isProposal = false,
    financialData = null,
    onBillingDaysChange,
    onBillingMonthsChange,
    onSaleValueHourChange,
    onSaleValueDayChange,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Row justify="center">
                <Col sm={24} md={21}>
                    <Title level={3}>
                        {t("results")}
                    </Title>
                    <Divider />
                </Col>
            </Row>
            <Row justify="center">
                <Col sm={24} md={10}>
                    <Card title={t("monthlyRemuneration")}>
                        <Row justify="center" className="paddingBottom20">
                            <Col sm={24} md={24}>
                                <table className="fullWidth">
                                    <tbody>
                                        <tr>
                                            <td width="50%">{t("grossSalary")}</td>
                                            <td width="50%" align="right">
                                            {
                                                renderFormat(
                                                    TYPE_IRS_DETAIL.CURRENCY, 
                                                    simulationOutput.salarySimulation.monthly.grossSalary
                                                )
                                            }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t("otherEntries")}</td>
                                            <td align="right">
                                            {
                                                renderFormat(
                                                    TYPE_IRS_DETAIL.CURRENCY, 
                                                    simulationOutput.salarySimulation.monthly.otherEntries
                                                )
                                            }
                                            </td>
                                        </tr>

                                        {simulationOutput.salarySimulation.monthly.irsDetails.map(
                                            ({ itemDescription, itemType, itemValue }, index) => {
                                            return (
                                                <tr key={`irs-details-${index}`}>
                                                <td>{itemDescription}</td>
                                                <td align="right">
                                                    { renderFormat(itemType, itemValue) }
                                                </td>
                                                </tr>
                                            );
                                            }
                                        )}
                                        <tr>
                                            <td>{t("irsTaxRate")}</td>
                                            <td align="right">
                                            {
                                                renderFormat(
                                                    TYPE_IRS_DETAIL.PERCENTAGE, 
                                                    simulationOutput.salarySimulation.monthly.irsTaxRate
                                                )
                                            }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t("irsTaxValue")}</td>
                                            <td align="right">
                                            {
                                                renderFormat(
                                                    TYPE_IRS_DETAIL.CURRENCY, 
                                                    simulationOutput.salarySimulation.monthly.irsTaxValue
                                                )
                                            }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t("socialSecurityValue")}</td>
                                            <td align="right">
                                            {
                                                renderFormat(
                                                    TYPE_IRS_DETAIL.CURRENCY, 
                                                    simulationOutput.salarySimulation.monthly.socialSecurityValue                                
                                                )
                                            }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <strong style={{ display: "block", marginTop: "24px" }}>
                                    {t("netSalaryPossibilities")}
                                </strong>
                                <table className="fullWidth">
                                    <tbody>
                                    {
                                        simulationOutput.salarySimulation.monthly?.netSalaryPossibilities &&
                                        simulationOutput.salarySimulation.monthly?.netSalaryPossibilities.map(
                                        (possibility, index) => {
                                        return (
                                                <tr key={`netSalaryPossibilities-${index}`}>
                                                    <td width="50%">{possibility.description}</td>
                                                    <td width="50%" align="right">
                                                        { possibility.value !== null ? (
                                                            renderFormat(
                                                                TYPE_IRS_DETAIL.CURRENCY, 
                                                                possibility.value
                                                            )
                                                        ) : (
                                                            <>
                                                                {renderFormat(TYPE_IRS_DETAIL.CURRENCY, possibility.minValue)}
                                                                {` - `}
                                                                {renderFormat(TYPE_IRS_DETAIL.CURRENCY, possibility.maxValue)}
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col sm={24} md={{ span: 10, offset: 1 }}>
                    <Card title={t('yearlyRemuneration')}>
                        <Row justify="left" className="paddingBottom20">
                            <Col sm={24} md={24}>
                                <table className="fullWidth">
                                    <tbody>
                                    {
                                        simulationOutput.salarySimulation?.yearly &&
                                        typeof simulationOutput.salarySimulation.yearly.grossSalary === 'number' &&
                                        typeof simulationOutput.salarySimulation.yearly.netSalary === 'number' &&
                                        (
                                        <>
                                            <tr>
                                                <td width="50%">{t("grossSalary")}</td>
                                                <td width="50%" align="right">
                                                {
                                                    renderFormat(
                                                        TYPE_IRS_DETAIL.CURRENCY, 
                                                        simulationOutput.salarySimulation.yearly.grossSalary
                                                    )
                                                }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{t("netSalary")}</td>
                                                <td align="right">
                                                {
                                                    renderFormat(
                                                        TYPE_IRS_DETAIL.CURRENCY, 
                                                        simulationOutput.salarySimulation.yearly.netSalary
                                                    )
                                                }
                                                </td>
                                            </tr>
        
                                        </>                                
                                    )}
                                    {
                                        simulationOutput.salarySimulation?.yearly && (
                                        simulationOutput.salarySimulation.yearly.grossSalary === null &&
                                        simulationOutput.salarySimulation.yearly.netSalary === null &&
                                        <>
                                            <tr>
                                                <td width="50%">{t('grossSalary')}</td>
                                                <td width="50%" align="right">
                                                    {renderFormat(TYPE_IRS_DETAIL.CURRENCY, simulationOutput.salarySimulation?.yearly.minGrossSalary)} <small className="yearly-coverflex">(1)</small>
                                                    {` - `}
                                                    {renderFormat(TYPE_IRS_DETAIL.CURRENCY, simulationOutput.salarySimulation?.yearly.maxGrossSalary)} <small className="yearly-coverflex">(2)</small>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td width="50%">{t('netSalary')}</td>
                                                <td width="50%" align="right">
                                                    {renderFormat(TYPE_IRS_DETAIL.CURRENCY, simulationOutput.salarySimulation?.yearly.minNetSalary)} <small className="yearly-coverflex">(1)</small>
                                                    {` - `}
                                                    {renderFormat(TYPE_IRS_DETAIL.CURRENCY, simulationOutput.salarySimulation?.yearly.maxNetSalary)} <small className="yearly-coverflex">(2)</small>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <Divider className="margin-bottom-0" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <p className="yearly-coverflex margin-0"><small>(1) {t('yearlyRemunerationCoverflexTaxed')}</small></p>
                                                    <p className="yearly-coverflex margin-0"><small>(2) {t('yearlyRemunerationCoverflexNotTaxed')}</small></p>
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                    <br />
                    <Card title={t('companyCost')}>
                        <Row justify="left" className="paddingBottom20">
                            <Col sm={24} md={24}>
                                <table className="fullWidth">
                                    <tbody>
                                    <tr>
                                        <td width="50%">{t("monthly")}</td>
                                        <td width="50%" align="right">
                                        {
                                            renderFormat(
                                                TYPE_IRS_DETAIL.CURRENCY, 
                                                simulationOutput.companyCostsSimulation.monthly.totalCost
                                            )
                                        }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("yearly")}</td>
                                        <td align="right">
                                        {
                                            renderFormat(
                                                TYPE_IRS_DETAIL.CURRENCY, 
                                                simulationOutput.companyCostsSimulation.yearly.totalCost
                                            )
                                        }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("initialCosts")}</td>
                                        <td align="right">
                                        {
                                            renderFormat(
                                                TYPE_IRS_DETAIL.CURRENCY, 
                                                simulationOutput.companyCostsSimulation.initialCosts.totalCost
                                            )
                                        }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("firstMonthCosts")}</td>
                                        <td align="right">
                                        {
                                            renderFormat(
                                                TYPE_IRS_DETAIL.CURRENCY, 
                                                simulationOutput.companyCostsSimulation.firstMonth.totalCost
                                            )
                                        }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t("firstYearCosts")}</td>
                                        <td align="right">
                                        {
                                            renderFormat(
                                                TYPE_IRS_DETAIL.CURRENCY, 
                                                simulationOutput.companyCostsSimulation.firstYear.totalCost
                                            )
                                        }
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            {
                isProposal && (
                    <Row justify="center" className="paddingBottom20">
                        <Col sm={24} md={21}>
                            <br />
                            <SalaryFormFinance 
                                isProposal={isProposal}
                                showAsOutput={true}
                                simulationOutput={simulationOutput}
                                financialData={financialData}
                                onBillingDaysChange={onBillingDaysChange}
                                onBillingMonthsChange={onBillingMonthsChange}
                                onSaleValueHourChange={onSaleValueHourChange}
                                onSaleValueDayChange={onSaleValueDayChange}
                            />
                        </Col>
                    </Row>
                )
            }
        </>      
    )
}

export default Output;