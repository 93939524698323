
import React, { useEffect } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Icon, { StopOutlined } from "@ant-design/icons";

const { Title } = Typography;

import './Forbidden.css';

const Forbidden = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = `${t('forbidden')}`;
    }, []);

    return (
        <>
        <div id="content">
            <Title level={1} className="forbiddenPageTitle">
                <Icon className="stop-sign" component={StopOutlined} />
                {t('forbidden')}
            </Title>  
            <Title level={4} className="forbiddenPageBody">
                {t('forbiddenBody')}
            </Title>  

        </div>
        </>     
    )
}

export default Forbidden;