import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useStoreState } from "easy-peasy";
import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAppClient from "../../shared/hooks/useAppClient";

import "./PerDiem.css";
import Company from "./components/company/Company.component";
import Period from "./components/period/Period.component";
import InternationalPerDiem from "./international";
import NationalPerDiem from "./national";

const { Title, Text } = Typography;
const { confirm } = Modal;

const formatPerdiem = (perDiem, reportType) => {
  return {
    ... perDiem,
    perdiems: perDiem.perdiems.map((item) => {
      return {
        ...item,
        trypLabel: reportType === "NATIONAL"? "P" : "E",
        fromDay: Number(dayjs(`${item.startTime}.000Z`).format('DD')),
        fromTime: dayjs(`${item.startTime}.000Z`),
        toDay: Number(dayjs(`${item.endTime}.000Z`).format('DD')),
        toTime: dayjs(`${item.endTime}.000Z`)      
      }
    })
  }
}

function PerDiem() {
  const tripModel = {
    reason: null,
    location: null,
    percentage: 100,
    id: null,
    position: null,
    startTime: null,
    endTime: null,
  };
  const instance = useAppClient(true);

  const { perDiemId, reportType } = useParams();
  const [perDiem, setPerDiem] = useState(null);
  const [modal, setModal] = useState({ open: false });
  const [saveStatus, setSaveStatus] = useState(null);
  const [trip, setTrip] = useState(tripModel);
  const [form] = Form.useForm();
  const [timer, setTimer] = useState(null);
  const companyName = useStoreState((state) => state.selectedCompanyModel.name);
  const employeeName = useStoreState(
    (state) => state.employeeModel.employee.name
  );

  const apiHandler = useMemo(() => {
    return reportType === 'NATIONAL' ? 'nat' : 'int';
  },[reportType])

  const { t } = useTranslation();

  let loadingIcon = null;
  switch (saveStatus) {
    case "loading":
      loadingIcon = <LoadingOutlined />;
      break;
    case "success":
      loadingIcon = <CheckCircleOutlined />;
      break;
    case "failed":
      loadingIcon = <CloseCircleOutlined />;
      break;
    default:
      break;
  }

  const onFormChange = (changedValues, allValues) => {
    const maxPosition = perDiem.perdiems.reduce( (acc, current) => {
      return current.position > acc? current.position : acc;
    }, 0) + 1;

    setTrip({...trip,
      ...allValues,
      position: maxPosition,
    });

    const isEndTimeField = Object.keys(changedValues).some((key)=> ['endTime'].includes(key));
    if(isEndTimeField){
      form.validateFields(['startTime']);
    }
  };

  const hideModal = (error) => {
    setTrip(tripModel);
    setModal({ open: false });
    form.setFieldsValue(tripModel);
    if (error) {
      Modal.error({
        title: t("modalErrorTitle"),
        content: error.message,
      });
    }
  };

  const isDataValid = (formInstance, currentTime, isStartTime, checkDateOverlapedOnly) => {
    if (currentTime === null) {
      return true;
    }

    const targetColumnName = isStartTime? 'endTime' : 'startTime' ;
    const targetTime = formInstance.getFieldValue(targetColumnName);

    if (targetTime === null) {
      return true;
    }

    const timeDiference = isStartTime? 
    targetTime.diff(currentTime, "minutes", true) : 
    currentTime.diff(targetTime, "minutes", true);

    const isDateOverlapping = timeDiference < 0;
    const isDateDiferenceInvalid = timeDiference > 0 && timeDiference < 15;
    const isDateWrong = (checkDateOverlapedOnly && isDateOverlapping ) || (!checkDateOverlapedOnly && isDateDiferenceInvalid);

    return isDateWrong ? false : true;
  }

  const datePickerTimeDifValidator = (formInstance, errorMessage, isStartTime) => {
    return {
      message: t(errorMessage),
      validator(_, currentTime) {        
        return isDataValid(formInstance, currentTime, isStartTime, false) ? 
          Promise.resolve() :  Promise.reject(new Error());
      },
    };
  }

  const datePickerDateOverlapValidator = (formInstance, errorMessage, isStartTime) => {
    return {
      message: t(errorMessage),
      validator(_, currentTime) {
        return isDataValid(formInstance, currentTime, isStartTime, true) ? 
            Promise.resolve() :  Promise.reject(new Error());
      },
    };
  }

  const perDiemExistsValidator = (formInstance, errorMessage) => {
    return {
      message: t(errorMessage),
      validator(_, currentTime) {
        if (currentTime === null) {
          return Promise.resolve();
        }

        const dateDay = Number(dayjs(currentTime).format('DD'))
        const recordId = formInstance.getFieldValue('id');

        if (
          perDiem?.perdiems.some((trip) => trip.fromDay >= dateDay && trip.toDay <= dateDay && trip.id !== recordId)
        ) {
          return Promise.reject(new Error());
        }
        return Promise.resolve();
      },
    };
  }

  const rules = {
    startTime : [{ required: true, message: t("dayHourRequiredMessage")},
        (formInstance) => datePickerTimeDifValidator(formInstance, 'timeIntervalMessage', true), 
        (formInstance) => datePickerDateOverlapValidator(formInstance, 'dateOverlapMessage', true),
        (formInstance) => perDiemExistsValidator(formInstance, 'perDiemTripOverlapMessage'),
    ],
    endTime:[{ required: true, message: t("dayHourRequiredMessage")},
        (formInstance) => datePickerTimeDifValidator(formInstance ,'timeIntervalMessage', false), 
        (formInstance) => datePickerDateOverlapValidator(formInstance ,'dateOverlapMessage', false), 
        (formInstance) => perDiemExistsValidator(formInstance, 'perDiemTripOverlapMessage'), 
    ]
  }

  const tripForm = (record) => {
    form.setFieldsValue(record);
    return (
      <Form
        form={form}
        name="trip"
        layout="vertical"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={record}
        onValuesChange={onFormChange}
        autoComplete="off"
      >
        <Row>
          <Col span={11}>
            <Form.Item
              label={t("reasonLabel")}
              name="reason"
              rules={[{ required: true, message: t("reasonRequiredMessage") }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item
              label={t("locationLabel")}
              name="location"
              rules={[
                { required: true, message: t("locationRequiredMessage") },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <Form.Item
              label={t("departureLabel")}
              name="startTime"
              rules={rules.startTime}
            >
              <DatePicker showTime format={"YYYY-MM-DD HH:mm"} />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item
              label={t("arrivalLabel")}
              name="endTime"
              rules={rules.endTime}
            >
              <DatePicker showTime format={"YYYY-MM-DD HH:mm"} />
            </Form.Item>          
          </Col>
        </Row>
        {reportType === "NATIONAL"
          ? NationalPerDiem.inputColumns(t)
          : InternationalPerDiem.inputColumns(t)}
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
      </Form>
    );
  };

  const onDelete = (tripId, trip, month) => {
    const monthLabel = dayjs().month(month - 1).format("MMMM");
    confirm({
      title: t("deleteTripConfirmation", {
        day: trip.fromDay,
        month: monthLabel,
      }),
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        instance
          .delete(`/travel-reports/travel-reports/${apiHandler}/${perDiemId}/perdiems/${tripId}`)
          .then(() => {
            getPerDiem(perDiemId);
          })
          .catch((error) => {
            console.warn("error", error);
          });
      },
      onCancel: () => {
        setModal({ open: false });
      },
    });
  };

  const onAdd = () => {
    setTrip(tripModel);

    const perDiemStartDate = new Date(perDiem.year, perDiem.month - 1, 1);
    const defaultPickerDate = dayjs(perDiemStartDate);
    const initialValues = {
      ...tripModel,
      startTime: defaultPickerDate,
      endTime: defaultPickerDate.add(15, 'minutes'),
    };

    setModal({
      open: true,
      title: t("addTripTitle"),
      content: (
        <>
          <Space direction="vertical" size="large" style={{ whiteSpace: "normal" }}>
            {t("addTripContent")}
            {tripForm(initialValues)}
          </Space>
        </>
      ),
    });
  };

  const onEdit = (record) => {
    const editedPerdiem = {
      ...record,
      startTime: dayjs(`${record.startTime}.000Z`),
      endTime: dayjs(`${record.endTime}.000Z`),
    };
    setTrip(editedPerdiem);
    setModal({
      open: true,
      title: t("editTripTitle"),
      content: <>{tripForm(editedPerdiem)}</>,
    });
  };

  const submitTrip = (e) => {
    const btn = e.currentTarget;
    btn.setAttribute("disabled", "disabled");
    form.validateFields().then(() => { 
      const isUpdate = trip.id !== null;
      instance({
        method: isUpdate ? "put" : "post",
        url: `/travel-reports/travel-reports/${apiHandler}/${perDiemId}/perdiems/${trip.id ? trip.id : ""}`,
        data: { ...trip, id: isUpdate ? trip.id : null },
      })
        .then(() => {
          setTrip(tripModel);
          getPerDiem(perDiemId);
          form.resetFields();
          hideModal();
          btn.removeAttribute("disabled");
        })
        .catch((error) => {
          setTrip(tripModel);
          console.warn("error", error);
          form.resetFields();
          hideModal(error);
          btn.removeAttribute("disabled");
        });
    })
    .catch((err) => {
      btn.removeAttribute("disabled");
      console.warn(err);
      return;
    });
  };

  const extraColumns = (t) => {
    return reportType === "NATIONAL"
      ? NationalPerDiem.extraColumns(t)
      : InternationalPerDiem.extraColumns(t);
  };

  const columns = [
    {
      title: t("reasonTitle"),
      dataIndex: "reason",
      key: "reason",
      align: "center",
    },
    {
      title: t("locationTitle"),
      dataIndex: "location",
      key: "location",
    },
    {
      title: t("departureTitle"),
      children: [
        {
          title: t("dayTitle"),
          dataIndex: "fromDay",
          key: "fromDay",
        },
        {
          title: t("timeTitle"),
          dataIndex: "fromTime",
          key: "fromTime",
          render: (_, record) => dayjs(record.fromTime).format('HH:mm'),
        },
      ],
    },
    {
      title: t("arrivalTitle"),
      children: [
        {
          title: t("dayTitle"),
          dataIndex: "toDay",
          key: "toDay",
        },
        {
          title: t("timeTitle"),
          dataIndex: "toTime",
          key: "toTime",
          render: (_, record) => dayjs(record.toTime).format('HH:mm'),
        },
      ],
    },
    ...extraColumns(t),
    {
      title: t("valueTitle"),
      dataIndex: "totalValue",
      key: "totalValue",
      render: (text, _) => (
        <>
          <Typography.Text>{text}</Typography.Text>
          <Typography.Text disabled> {t("euroSymbol")}</Typography.Text>
        </>
      ),
    },
    {
      title: t("actionLabel"),
      key: "action",
      // width: 160,
      align: "right",
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => onDelete(record.id, record, perDiem.month)}>
            {t("deleteActionLabel")}
          </a>
          <a onClick={() => onEdit(record)}>{t("editActionLabel")}</a>
        </Space>
      ),
    },
  ];

  const getPerDiem = async (perDiemId) => {
    instance
      .get(`/travel-reports/travel-reports/${apiHandler}/${perDiemId}`)
      .then((response) => {
        setPerDiem(formatPerdiem(response.data, reportType));
        document.title = `Per Diem Report — ${response.data.year}/${
          response.data.month
        }${typeof companyName === "string" ? " — " + companyName : ""}${
          typeof employeeName === "string" ? " — " + employeeName : ""
        }`;
      })
      .catch((error) => {
        console.warn("error", error);
      });
  };

  const onChange = (value) => {
    setSaveStatus("loading");
    clearTimeout(timer);
    const newTimer = setTimeout(() => {
      instance
        .put(`/travel-reports/travel-reports/${apiHandler}/${perDiemId}`, {
          valuePerDay: value,
        })
        .then(() => {
          getPerDiem(perDiemId);
          setSaveStatus("success");
        })
        .catch((error) => {
          console.warn("error", error);
          setSaveStatus("failed");
        });
    }, 500);

    setTimer(newTimer);
  };

  const onDownload = () => {  
    instance
      .post(
        `travel-reports/travel-reports/export`,
        {
          reportIds: [perDiemId],
        },
        {
          headers: {
            "content-type": "application/json",
            accept: "application/pdf",
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        const fileName = `AC - ${perDiem.employee.name} — ${perDiem.year}-${perDiem.month}.pdf`;
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  React.useEffect(() => {
    getPerDiem(perDiemId);
  }, [perDiemId]);

  return (
    perDiem && (
      <>
        <Title level={2} className="pageTitle">
          {t("perDiemsTitle")}
        </Title>
        <Row>
          <Col span={12}>
            <Title level={4}>{t("compensationForPerDiems")}</Title>
          </Col>
          <Col span={12} align="right">
            <Button
              type="primary"
              size="medium"
              icon={<DownloadOutlined />}
              onClick={onDownload}
            >
              {t("exportButton")}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Company company={perDiem.company} />
          </Col>
          <Col span={4} offset={8}>
            <Row>
              <Col span={24} align="right">
                <Title strong>{t("receipt")}</Title>
              </Col>
            </Row>
            <Period year={perDiem.year} month={perDiem.month} />
          </Col>
        </Row>
        <Table
          dataSource={perDiem.perdiems}
          columns={columns}
          pagination={false}
          size="middle"
          rowKey="id"
          summary={() => {
            return (
              <>
                {reportType === "NATIONAL"
                  ? NationalPerDiem.extraSummary(
                      t,
                      perDiem,
                      onAdd,
                      onChange,
                      loadingIcon
                    )
                  : InternationalPerDiem.extraSummary(
                      t,
                      perDiem,
                      onAdd,
                      onChange,
                      loadingIcon
                    )}
                <Table.Summary.Row className="total">
                  <Table.Summary.Cell colSpan={8} align="right">
                    <Title level={4}>{t("totalAmount")}</Title>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={2} align="right">
                    <Title level={3} className="totalValue" >
                      <Text strong>{perDiem.amount?.toFixed(2)}</Text>
                      <Text type="secondary" className="euro">
                        {t("euroSymbol")}
                      </Text>
                    </Title>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell />
                </Table.Summary.Row>
              </>
            );
          }}
        />
        <Row>
          <Col span={24}>
            <Divider orientation="left" orientationMargin="0">
              {t("payee")}
            </Divider>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Row>
              <Col span={6}>
                <Text strong>{t("name")}</Text>
              </Col>
              <Col span={18}>
                <Text>{perDiem.employee.name}</Text>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Text strong>{t("address")}</Text>
              </Col>
              <Col span={32}>
                <Text>{perDiem.employee.address}</Text>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={6}>
                <Text strong>{t("taxId")}</Text>
              </Col>
              <Col span={18}>
                <Text>{perDiem.employee.vatNumber}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
        {modal.open === true && (
          <Modal
            title={modal.title}
            open={modal.open}
            onOk={(e) => {
              submitTrip(e);
            }}
            onCancel={() => {
              hideModal();
            }}
          >
            {modal.content}
          </Modal>
        )}
      </>
    )
  );
}

export default PerDiem;
