import dayjs from "dayjs";
import { BenefitFormItemType, Benefit } from "../shared/hooks/useBenefits.types";


export const getValue = (benefit:Benefit, key:string, type: BenefitFormItemType) => {
    const value = benefit[key as keyof Benefit];
    if(type === BenefitFormItemType.DATE_RANGE) {
        return value !== null? dayjs(`${value}`) : null;
    }
    return value;
}