import React from 'react';
import Icon, {LoadingOutlined } from "@ant-design/icons";
import "./Cats.css";

const Cats = () => {
    return (
        <Icon className="loading-cats" component={LoadingOutlined} />
    )
}

export default Cats;