import CalculatorApp from "../../components/calculator";
import { useTranslation } from "react-i18next";
import withTranslation from "../../shared/hoc/withTranslation";
import withAuth from '../../shared/hoc/withAuth';

const Calculator = () => {
    const { t } = useTranslation();
    return (
        <CalculatorApp title={t("salaryCalculator")} />
    );
}

export default withAuth(withTranslation(Calculator));