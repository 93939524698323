import { useEffect} from 'react';
import { useStoreState } from 'easy-peasy';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './Welcome.css';

function Welcome() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locale } = useParams();

  const companyName = useStoreState((state) => state.selectedCompanyModel.name);

  useEffect(() => {
    document.title = typeof companyName === 'string' ? `${t('rootHeader')} — ${companyName}` : t('rootHeader');
  }, [companyName]);

  useEffect(() => {
    if (locale !== 'en' && locale !== 'pt') {
      console.log('Locale not recognized. Redirecting to 404.', locale, window.location.href, hash,locale !== 'en', locale !== 'pt', !hash.includes('code'));
      navigate(`/pt/404/`);
    }
  }, [locale]);

  return (
    <>
      <p className='landing'>{t('welcome')}</p>
    </>
  );
}

export default Welcome;
