import { apiInstance as instance, mockInstance } from "../clients/App";
import { AxiosInstance } from 'axios';
import { renewToken, getInstanceToken, updateInstanceToken } from '../clients/App';
import { useEffect, useMemo } from "react";
import useWindowState from "./useWindowState";
import { LOCAL_STORAGE_KEYS } from "../constants/localStorage";

const useAppClient = (canMock = false):AxiosInstance => {

    const isMock = Boolean(process.env.REACT_APP_MOCK);
    const appInstance = isMock && canMock? mockInstance : instance;
    const isReady = useWindowState();

    useEffect(()=>{
        if(getInstanceToken() === undefined){
            const savedToken = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
            if(savedToken) {
                updateInstanceToken(savedToken);
            } else{
                renewToken();
            }            
        }
    },[isReady, appInstance, getInstanceToken, updateInstanceToken])

    return appInstance;
}

export default useAppClient;