import { COMPANIES } from "../shared/constants/companies";

export const getCompanyColor = (companyName) => {
    return companyName.toUpperCase().indexOf(COMPANIES.SEDE) > -1
    ? "lightgrey"
    : companyName.toUpperCase().indexOf(COMPANIES.MERCEDES) > -1
    ? "black"
    : companyName.toUpperCase().indexOf(COMPANIES.CARLSBERG) > -1
    ? "rgb(23, 177, 105)"
    : companyName.toUpperCase().indexOf(COMPANIES.LEROY_MERLIN) > -1
    ? "rgb(124, 176, 53)"
    : companyName.toUpperCase().indexOf(COMPANIES.WELLA) > -1
    ? "rgb(209, 34, 66)"
    : companyName.toUpperCase().indexOf(COMPANIES.FARFETCH) > -1
    ? "rgb(248, 82, 57)"
    : companyName.toUpperCase().indexOf(COMPANIES.CELFOCUS) > -1
    ? "rgb(240, 44, 49)"
    : companyName.toUpperCase().indexOf(COMPANIES.SANTANDER) > -1
    ? "rgb(230, 43, 48)"
    : companyName.toUpperCase().indexOf(COMPANIES.MAERSK) > -1
    ? "rgb(76, 175, 203)"
    : companyName.toUpperCase().indexOf(COMPANIES.BOOST) > -1
    ? "rgb(64, 135, 249)"
    : companyName.toUpperCase().indexOf(COMPANIES.SHIELD) > -1
    ? "rgb(255, 216, 64)"
    : companyName.indexOf(COMPANIES.BNP) > -1
    ? "rgb(0, 133, 92)"
    : companyName.indexOf(COMPANIES.GLS) > -1
    ? "rgb(255, 213, 88)"
    : companyName.indexOf(COMPANIES.NOS) > -1
    ? "black"
    : "grey";
}