import { MsalProvider } from "@azure/msal-react";
import { StoreProvider } from "easy-peasy";
import { createRoot } from "react-dom/client";
import Router from "./components/router";
import { msalInstance } from './shared/clients/Auth';
import store from "./store";
import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <MsalProvider instance={msalInstance}>
    <StoreProvider store={store}>
      <Router />
    </StoreProvider>
  </MsalProvider>
);
