
import React, {useEffect, useState, useMemo, useContext} from 'react';

import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
  } from '@dnd-kit/sortable';

  import useBenefits from '../../shared/hooks/useBenefits';
import {
    DeleteOutlined,
} from "@ant-design/icons";

import { useTranslation } from 'react-i18next';

import {Space, Card, Table} from 'antd';

import useBenefitGroups from '../../shared/hooks/useBenefitGroups';
import DeleteModal from '../delete-modal';

import {Row} from './Row';
import {DragHandle} from './DragHandle';


const EditGroupBenefits = ({
    selectedGroupId = null
}) => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const { group:fetchedGroup, fetchGroup, updateGroup } = useBenefitGroups();
    const [lastFetchedGroupId, setLastFetchedGroupId] = useState(null);
    const [updateTimer, setUpdateTimer] = useState(null);
    const [deleteModal, setDeleteModal] = useState({shouldOpen: false, data:{}});
    const { defaultOrder } = useBenefits();
    
    const shouldFetchData = useMemo(()=> {
        return selectedGroupId && lastFetchedGroupId === null || selectedGroupId !== lastFetchedGroupId;
    },[
        selectedGroupId, lastFetchedGroupId
    ]);

    useEffect(()=> {
        if ( shouldFetchData ) {
            setLoading(true);
            fetchGroup(selectedGroupId, () => {
                setLoading(false);
                setLastFetchedGroupId(selectedGroupId);
            },(_) => {
                setLoading(false);
            });
        }

    },[selectedGroupId, shouldFetchData, setLoading, fetchGroup, setLastFetchedGroupId, fetchedGroup])

    const onDeleteCancelHandler = () => {
        setDeleteModal({shouldOpen: false, data:{}})
    }

    const onDeleteOkHandler = () => {
        const newState = {...fetchedGroup, benefits: fetchedGroup.benefits.filter( benefit => benefit.code !== deleteModal.data.record.code)}
        doUpdateGroup(newState);
        setDeleteModal({shouldOpen: false, data:{}})
    }

    const onDelete = (record) => {
        setDeleteModal({shouldOpen: true, data:{
            record,
            content: t('workItemsDeleteConfirmation', {label: record.description})
        }})        
    }

    const columns = [
        {
            title: t("benefitGroupsEditBenefitTableNameCol"),
            dataIndex: "name",
            width: 350,
            key: "name",
            align: "left",
            render: (_, record) => record.description,
        },
        {
            title: t("benefitGroupsEditBenefitTableOrderCol"),
            dataIndex: "order",
            key: "order",
            align: "left",
            render: (_, record) => record.order,
        },
        {
            title: t("actionLabel"),
            key: "action",
            width: 80,
            align: "center",
            render: (_, record) => (
              <Space size="middle">
                <DragHandle />
                <DeleteOutlined className='IconTable' onClick={() => onDelete(record)}/>
              </Space>
            ),
        }
    ];
    
    const doUpdateGroup = (group) => {
        clearTimeout(updateTimer);
        const timer = setTimeout(() => {
            setLoading(true);
            updateGroup(
                group, 
                group.benefits, 
                ()=>{
                    fetchGroup(group.benefitGroupId, () => {
                        setLoading(false);
                        setLastFetchedGroupId(group.benefitGroupId);
                    },(_) => {
                        setLoading(false);
                    });
                },
                ()=> {
                    setLoading(false);
                }
            );
        }, 200);
        setUpdateTimer(timer); 
    };

    const onDragEnd = ({ active, over }) => {
        if (active?.id !== over?.id) {  

            const benefitsRef = fetchedGroup.benefits;
            const activeIndex = benefitsRef.findIndex((record) => record.code === active?.id);
            const activeOrderValue = Number(benefitsRef[activeIndex].order);

            const overIndex = benefitsRef.findIndex((record) => record.code === over?.id);
            const overOrderValue = Number(benefitsRef[overIndex].order);

            benefitsRef[activeIndex].order = overOrderValue;
            benefitsRef[overIndex].order = activeOrderValue;

            const newState = {...fetchedGroup, benefits: arrayMove(benefitsRef, activeIndex, overIndex)};
            doUpdateGroup(newState);
        }
    };

    return (
        <>
        {
            fetchedGroup && fetchedGroup.benefits && (
                <Card>
                    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                      <SortableContext items={fetchedGroup.benefits.map((i) => i.code)} strategy={verticalListSortingStrategy}> 
                         <Table
                            loading={loading}
                            rowKey="code"
                            size="middle"
                            components={{
                                body: {
                                  row: Row,
                                },
                            }}
                            columns={columns}
                            pagination={false}
                            dataSource={fetchedGroup.benefits.sort(defaultOrder) ?? []}
                        />
                      </SortableContext>
                    </DndContext>
                </Card>
            )
        }
        { deleteModal && deleteModal.shouldOpen && (
                <DeleteModal
                    shouldOpen={deleteModal.shouldOpen}
                    data={deleteModal.data}
                    onOkHandler={onDeleteOkHandler}
                    onCancelHandler={onDeleteCancelHandler}
                />
            )
        }
        </>
    )
}

export default EditGroupBenefits;