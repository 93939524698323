import * as React from "react";
import "./company-picker.css";


const CompanyPicker = ({
    options,
    selectedValue = "",
    onChange = null 
}) => {
    return (
        <>
            <select className="company-picker" onChange={(e) => onChange(e.target.value)} defaultValue={selectedValue}>
                {options.map((item, index) => (<option key={`company-picker-option-${index}`} value={item.value}>{item.label}</option> ))}
            </select>
        </>
    );
}

export default CompanyPicker;