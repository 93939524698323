import React, {useEffect, useState, useMemo, useCallback} from 'react';
import { useTranslation } from 'react-i18next';

import {
    SaveOutlined,
    CloseCircleOutlined,
} from "@ant-design/icons";

import {Modal, notification, Input, Form, Space, Button} from 'antd';

import useBenefitGroups from '../../shared/hooks/useBenefitGroups';
import BenefitSelector from '../benefit-selector';

const modelObject = { open: false, title: '', closed: true};

const EditGroupOrBenefit = ({
    editMode,
    editModeCloseHandler = null,
    createGroupHandler = null,
    addBenefitHandler = null,
    updateGroupHandler = null
}) => {
    const { createGroup, updateGroup, renameGroup, group:fetchedGroup, fetchGroup } = useBenefitGroups();
    const {t} = useTranslation();
    const [modal, setModal] = useState(modelObject);
    const [form] = Form.useForm();

    const {addGroup, isEdit} = editMode;
    const isCreatingGroup = addGroup;
    const isEditingGroup = !addGroup && isEdit;
    const [benefit, setBenefit] = useState(null);

    const fieldName = isCreatingGroup || isEditingGroup ? 'description' : 'benefit';

    const label = useMemo(()=> {
        if (isCreatingGroup || isEditingGroup) {
            return t("benefitGroupsEditCreateDescription");
        } else {
            return t("benefitGroupsEditAddName");
        }
    },[isCreatingGroup, isEditingGroup, t])

    const title = useMemo(()=> {
        if (isCreatingGroup) {
            return t("benefitGroupsEditCreateGroup")
        } else if (isEditingGroup) {
            return t("benefitGroupsEditEditGroup")
        } else {
            return t("benefitGroupsEditAddBenefit")
        }
    },[isCreatingGroup, isEditingGroup, t])

    const shouldFetchGroup = useMemo(()=> {
        const {selectedId} = editMode;
        return selectedId && fetchedGroup === null || fetchedGroup && fetchedGroup.benefitGroupId !== selectedId
    }, [editMode.selectedId]);

    const shouldOpenModal = useMemo(()=> {
        return editMode.shouldShow && !modal.open
    },[editMode.shouldShow, modal.open])
    
    const onBenefitChangeHandler = (text) => {
        if(text.length < 1){
            setBenefit(null);
        }
    };

    const onBenefitSelectHandler = (benefit)=> {
        setBenefit(benefit);
    }

    const onBenefitClearHandler = () => {
        setBenefit(null);
    }

    const hideModal = () =>{
        setModal({ open: false });
        if(editModeCloseHandler !== null){
            editModeCloseHandler();
        }
    }
    
    const onSave = useCallback(() => {
        if(isCreatingGroup){
            createGroup(
                form.getFieldValue(fieldName), 
                ()=> {
                    if(createGroupHandler !== null){
                        createGroupHandler();
                    }
                    notification.success({
                        message: t('benefitGroupsEditCreateGroupNameSuccess'),
                        placement: "bottomRight",
                    });
                    hideModal();
                },
                (error) => {
                    notification.error({
                        message: error?.message ?? '',
                        placement: "bottomRight",
                    });
                }
            );
        } else if (isEditingGroup) {            
            renameGroup(fetchedGroup, form.getFieldValue(fieldName), ()=> {
                if(updateGroupHandler !== null){
                    updateGroupHandler();
                }
                notification.success({
                    message: t('benefitGroupsEditUpdateGroupNameSuccess'),
                    placement: "bottomRight",
                });
                hideModal();
            },
            (error) => {
                notification.error({
                    message: error?.message ?? '',
                    placement: "bottomRight",
                });
            })
        } else {
            const { benefits } = fetchedGroup;
            
            if(benefit && benefits.find(item => item.code == benefit.code) === undefined) {
               
                const newBenefit = {
                    code: benefit.code,
                    description: benefit.description,
                    order: benefits.length < 1? 0 : Math.max(...benefits.map(e => e.order)) + 1,
                };

                updateGroup(fetchedGroup, [... benefits, newBenefit], ()=> {
                    if(addBenefitHandler !== null){
                        addBenefitHandler();
                    }
                    fetchGroup(fetchedGroup.benefitGroupId);
                    notification.success({
                        message: t('benefitGroupsEditCreateBenefitSuccess'),
                        placement: "bottomRight",
                    });
                    hideModal();
                },
                (error) => {
                    notification.error({
                        message: error.message,
                        placement: "bottomRight",
                    });
                })
                
            } else if( benefit && benefits.some(item => item.code == benefit.code)) {
                notification.error({
                    message: t('benefitGroupsEditCreateBenefitExists'),
                    placement: "bottomRight",
                });
            } else {
                notification.error({
                    message: t('benefitGroupsEditCreateBenefitNoneSelected'),
                    placement: "bottomRight",
                });
            }
        }
    },[fetchedGroup, isCreatingGroup, isEditingGroup, createGroup, updateGroup, renameGroup, benefit, form, fieldName]);

    useEffect(()=>{
        if(shouldFetchGroup){
            fetchGroup(editMode.selectedId);
        }

        if(shouldOpenModal){
            setModal(
                {
                    open: true,
                    title: <b>{title}</b>,
                }
            )
        }

        if(fetchedGroup) {
            form.setFieldsValue({[fieldName]: isEditingGroup ? fetchedGroup.description : null});
        }
    },[shouldFetchGroup, shouldOpenModal, setModal, title, fieldName, form.setFieldsValue, isEditingGroup, fetchedGroup]);

    return (
        <Modal
            title={modal.title}
            open={modal.open}
            onCancel={() => {
                hideModal();
            }}
            footer={
                <Space direction='horizontal' size={'middle'}>
                    <Button onClick={() => hideModal()} icon={<CloseCircleOutlined />}>{t('benefitGroupsEditCancelBtn')}</Button>
                    <Button type='primary' onClick={() => onSave()} icon={<SaveOutlined />}>{t('benefitGroupsEditSaveBtn')}</Button>
                </Space>
            }
        >
            {
            <>
                <Form
                    form={form}
                    layout="vertical"
                    name="editor-groups-form"
                >
                    <Form.Item
                        label={label}
                        name={fieldName}
                    >
                        {
                            isCreatingGroup || isEditingGroup? (
                                <>
                                    <Input className='fullWidth' />
                                </>
                            ) : (
                                <>
                                    <BenefitSelector 
                                        onBenefitClearHandler={onBenefitClearHandler} 
                                        onBenefitSelectHandler={onBenefitSelectHandler}
                                        onBenefitChangeHandler={onBenefitChangeHandler}
                                    />
                                </>
                            )
                        }
                    </Form.Item>
                </Form>
            </>
            }
      </Modal>    
    )
}

export default EditGroupOrBenefit;