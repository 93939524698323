import { Benefit, ValueCheck } from "../shared/hooks/useBenefits.types";

export const evalFirstNotNull = (benefit:Benefit, keys: string[] | string, test?: ValueCheck) => {
    if (test) {
        const key = Array.isArray(keys)? keys[0] : keys;
        const value = benefit[key as keyof Benefit];

        if (value === undefined) {
            return '';
        } else  {
            return value === true ? test.true : test.false;
        }

    }
    return null;
}